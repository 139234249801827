import {useEffect, useState} from 'react';
import {Forest} from '../../../entities';
import {
    LabelContainer,
    Label,
    ValidationsList,
    SelectControlShowingEdits,
    FormFieldContainer
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const ForestFormFieldContainer = styled(FormFieldContainer)`
    width: 500px;
`;

export function ForestControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [forests, setForests] = useState<Forest[]>([]);

    const getForests = async () => {
        const result = await (await fetch(`/api/forests`)).json();
        setForests(result);
    };

    useEffect(() => {
        getForests();
    }, []);

    return (
        <>
            <ForestFormFieldContainer>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.TTFForestCodeValidated.current}
                            disabled={project.TTFForestCode.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    TTFForestCodeValidated: project.TTFForestCodeValidated.update(
                                        e.validated
                                    )
                                })
                            }
                        />
                    )}
                    <Label htmlFor="forestCode">Forest</Label>
                </LabelContainer>
                <SelectControlShowingEdits
                    id="forestCode"
                    disabled={project.ControlledByAutomation}
                    value={project.TTFForestCode}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            TTFForestCode: project.TTFForestCode.update(e.target.value)
                        })
                    }>
                    <option></option>
                    {forests.map((f) => (
                        <option key={f.Code} value={f.Code}>
                            {f.Name}
                        </option>
                    ))}
                </SelectControlShowingEdits>
                <ValidationsList validations={project.TTFForestCode.validations} />
            </ForestFormFieldContainer>
        </>
    );
}
