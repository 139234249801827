import {EditedProject} from '../EditedProject';
import {
    LabelContainer,
    Label,
    ValidationsList,
    SelectControlShowingEdits,
    FormFieldContainer
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {PlantingProjectType} from '../../../entities';
import styled from 'styled-components';

export const ProjectStatusFormFieldContainer = styled(FormFieldContainer)`
    width: 400px;
`;

export const Title = styled.span`
    color: rgb(140, 140, 140);
`;

const ProjectStatusControl2 = styled(SelectControlShowingEdits).attrs({
    children: (
        <>
            <option value="true">Completed</option>
            <option value="false">In-progress</option>
        </>
    )
})``;

export const InnerWrapper = styled.div`
    width: 200px;
`;

export function ProjectStatusControl({
    project,
    setProjectAndValidate,
    completedProjectSetProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    completedProjectSetProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <ProjectStatusFormFieldContainer>
            <InnerWrapper>
                <LabelContainer>
                    {!project.ControlledByAutomation &&
                        project.ProjectType.current !== PlantingProjectType.CommunityCanopy && (
                            <ValidateControl
                                validated={project.CompletedValidated.current}
                                onChange={(e) =>
                                    setProjectAndValidate({
                                        ...project,
                                        CompletedValidated: project.CompletedValidated.update(
                                            e.validated
                                        )
                                    })
                                }
                            />
                        )}
                    <Label htmlFor="projectStatus">Project status</Label>
                </LabelContainer>
                <ProjectStatusControl2
                    id="projectStatus"
                    disabled={
                        project.ControlledByAutomation ||
                        project.ProjectType.current === PlantingProjectType.CommunityCanopy
                    }
                    value={project.Completed}
                    onChange={(e) =>
                        completedProjectSetProjectAndValidate({
                            ...project,
                            Completed: project.Completed.update(e.target.value === 'true')
                        })
                    }
                />
            </InnerWrapper>
            <ValidationsList validations={project.Completed.validations} />
        </ProjectStatusFormFieldContainer>
    );
}

export function FixedCompletedProjectStatusControl() {
	return (
		<ProjectStatusFormFieldContainer>
			<InnerWrapper>
				<LabelContainer>
					<Label htmlFor="projectStatus"><Title>Project status:</Title> Completed</Label>
				</LabelContainer>
			</InnerWrapper>
		</ProjectStatusFormFieldContainer>
	);
}