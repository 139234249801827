import { CellWithValidator, StickyNumericHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import {
	ValidateControl,
	ValidatedIcon,
	ValidateFilter,
	ValidateFilterValue
} from "../../../../components/Controls/ValidateControl";
import { ComplexInputWithAsyncUpdate, NumericFilter, ValidationsList } from "../../../../components/Form/FormStyles";
import { Filter } from "../../ParticipationFunctions/Filtering";
import {
	AlignToTextBoxContent,
	TreeCountControl,
	ValidationTippy,
	ValidationTippyContent
} from "../../Styles/ParticipationRowStyles";
import { getNumberFromInput } from "../../../../record-editing";
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications";

export function CommunityTreesPlantedHeader() {
	return (
		<StickyNumericHeader
			style={{
				...offsetOneRow,
				width: '130px',
				minWidth: '130px',
				maxWidth: '130px'
			}}>
			Community trees planted
		</StickyNumericHeader>
	)
}

export function CommunityTreesPlantedFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CommunityTreesPlantedValidated ?? ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityTreesPlantedValidated: e.value
						})
					}
				/>
				<NumericFilter
					value={filter?.CommunityTreesPlanted ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, CommunityTreesPlanted: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function CommunityTreesPlantedContent({ ppn, updater }: { ppn: EditedParticipation, updater: ParticipationUpdater; }) {
	return (
		<StateStyledNumberCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<>
						{ppn.CommunityTreesPlantedValidated.current && <ValidatedIcon />}
						<AlignToTextBoxContent>
							{ppn.CommunityTreesPlanted.current}
						</AlignToTextBoxContent>
					</>
				) : (
					<>
						<ValidateControl
							validated={ppn.CommunityTreesPlantedValidated.current}
							disabled={ppn.CommunityTreesPlanted.notValidToShowPartner}
							onChange={(e) =>
								updater.update({
									...ppn,
									CommunityTreesPlantedValidated:
										ppn.CommunityTreesPlantedValidated.update(
											e.validated
										)
								})
							}
						/>
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={ppn.CommunityTreesPlanted.validations}
									/>
								</ValidationTippyContent>
							}
							disabled={ppn.CommunityTreesPlanted.validations.length === 0}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={TreeCountControl}
									showModification
									value={ppn.CommunityTreesPlanted}
									onChange={(updatedValue) =>
										updater.update({
											...ppn,
											CommunityTreesPlanted: updatedValue
										})
									}
									valueGetter={(e) =>
										ppn.CommunityTreesPlanted.update(
											getNumberFromInput(e)
										)
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}