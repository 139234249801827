import {
	formatProjectStatus,
	formatYesNoConditional,
	PlantingProject,
	PlantingProjectType
} from '../../entities';
import {
	textMatch,
	validatedMatch,
	numberRangeMatch,
	booleanMatch,
	numberMatch
} from '../../filters';
import { ValidateFilterValue } from '../../components/Controls/ValidateControl';
import { formatAdditionalCertificationsArray } from '../PlantingProjectEditor/Carbon/CarbonCreditAdditionalCertifications';
import { formatCarbonCreditTypes } from '../PlantingProjectEditor/Carbon/CarbonCreditType';
import { formatCarbonProjectStandards } from '../PlantingProjectEditor/Carbon/ProjectStandard';
import { formatSustainableDevelopmentGoalArray } from '../PlantingProjectEditor/Carbon/SustainableDevelopmentGoals';

export type Filter = {
	Id: string;
	ProjectType?: PlantingProjectType;
	ProjectTypeValidated: ValidateFilterValue;
	Name: string;
	NameValidated: ValidateFilterValue;
	ProjectStatus: string;
	ProjectStatusValidated: ValidateFilterValue;

	Location: string;
	LocationValidated: ValidateFilterValue;
	TTFForest: string;
	TTFForestCodeValidated: ValidateFilterValue;
	CommunitySiteName: string;
	CommunitySiteNameValidated: ValidateFilterValue;
	CommunitySiteAddress: string;
	CommunitySiteAddressValidated: ValidateFilterValue;
	CommunitySiteCity: string;
	CommunitySiteCityValidated: ValidateFilterValue;
	CommunitySiteStateCode: string;
	CommunitySiteStateCodeValidated: ValidateFilterValue;
	CommunitySitePostalCode: string;
	CommunitySitePostalCodeValidated: ValidateFilterValue;
	CommunitySiteCountryCode: string;
	CommunitySiteCountryCodeValidated: ValidateFilterValue;
	MapValidated: ValidateFilterValue;

	ProjectYears: string;
	ProjectYearsValidated: ValidateFilterValue;
	CommunityEventDates: string;
	CommunityEventDatesValidated: ValidateFilterValue;
	TTFPlantingTimeframe: string;
	TTFPlantingTimeframeValidated: ValidateFilterValue;

	ReasonForPlanting: string;
	ReasonForPlantingValidated: ValidateFilterValue;
	HasImpactStoryInProgress: string;
	ImpactStoryInProgressValidated: ValidateFilterValue;
	HasImpactStoryCompleted: string;
	ImpactStoryCompletedValidated: ValidateFilterValue;

	PlantingPartner: string;
	PlantingPartnerValidated: ValidateFilterValue;
	TTFCommitments: string;
	TTFCommitmentFiscalYear: string;
	TTFCommitmentFiscalYearValidated: ValidateFilterValue;
	TTFAgreementNumber: string;
	TTFAgreementNumberValidated: ValidateFilterValue;
	TTFTotalTreeCost: string;
	TTFTotalTreeCostValidated: ValidateFilterValue;
	CommunityNumberOfCorporateVolunteers: string;
	CommunityNumberOfCorporateVolunteersValidated: ValidateFilterValue;
	CommunityNumberOfTotalParticipants: string;
	CommunityNumberOfTotalParticipantsValidated: ValidateFilterValue;

	ProjectTTFTrees: string;
	ProjectTTFTreesValidated: ValidateFilterValue;
	ProjectCommunityTreesPlanted: string;
	ProjectCommunityTreesPlantedValidated: ValidateFilterValue;
	ProjectCommunityTreesDistributed: string;
	ProjectCommunityTreesDistributedValidated: ValidateFilterValue;
	ProjectAcresRestored: string;
	ProjectAcresRestoredValidated: ValidateFilterValue;

	TreeSpecies: string;
	TreeSpeciesValidated: ValidateFilterValue;
	TTFDBH: string;
	TTFDBHValidated: ValidateFilterValue;
	CommunityDBHTreesPlanted: string;
	CommunityDBHTreesPlantedValidated: ValidateFilterValue;
	CommunityDBHTreesDistributed: string;
	CommunityDBHTreesDistributedValidated: ValidateFilterValue;

	GallonsTotalRunoffAvoidedPerTreeReforestation: string;
	GallonsTotalRunoffAvoidedPerTreeEventPlanted: string;
	GallonsTotalRunoffAvoidedPerTreeEventDistributed: string;
	TonsCarbonPerTreeReforestation: string;
	TonsCarbonPerTreeEventPlanted: string;
	TonsCarbonPerTreeEventDistributed: string;
	TonsAirPollutionPerTreeReforestation: string;
	TonsAirPollutionPerTreeEventPlanted: string;
	TonsAirPollutionPerTreeEventDistributed: string;

	CarbonProjectStandard: string;
	CarbonCreditCreditType: string;
	CarbonICroaEndorsedStandard: string;
	CarbonCorsiaEligible: string;
	CarbonAdditionalDetailsLink: string;
	CarbonProjectRegistryLink: string;
	CarbonCreditsAdditionalCertifications: string;
	SustainableDevelopmentGoals: string;
	CarbonTotalCreditsIssued: string;

	DAMTag: string;
	ImagesValidated: ValidateFilterValue;
	ImageCount: string;
	HasHeroImage: string;
	CarbonTotalCreditsIssuedValidated: ValidateFilterValue;
	CarbonProjectStandardValidated: ValidateFilterValue;
	CarbonCreditCreditTypeValidated: ValidateFilterValue;
	CarbonICroaEndorsedStandardValidated: ValidateFilterValue;
	CarbonCorsiaEligibleValidated: ValidateFilterValue;
	CarbonAdditionalDetailsLinkValidated: ValidateFilterValue;
	CarbonProjectRegistryLinkValidated: ValidateFilterValue;
	SustainableDevelopmentGoalsValidated: ValidateFilterValue;
	AdditionalCarbonCreditCertificationsValidated: ValidateFilterValue;
	EverythingValidated: ValidateFilterValue;
	BHCampaigns: string;
	FundingPartners: string;
};

function defaultCommunityEventValidationFlagsToTrue(filter: Filter) {
	return (
		validatedMatch(true, filter.CommunitySiteNameValidated) &&
		validatedMatch(true, filter.CommunitySiteAddressValidated) &&
		validatedMatch(true, filter.CommunitySiteCityValidated) &&
		validatedMatch(true, filter.CommunitySiteStateCodeValidated) &&
		validatedMatch(true, filter.CommunitySitePostalCodeValidated) &&
		validatedMatch(true, filter.CommunitySiteCountryCodeValidated) &&
		validatedMatch(true, filter.CommunityEventDatesValidated) &&
		validatedMatch(true, filter.CommunityDBHTreesPlantedValidated) &&
		validatedMatch(true, filter.CommunityDBHTreesDistributedValidated) &&
		validatedMatch(true, filter.CommunityNumberOfCorporateVolunteersValidated) &&
		validatedMatch(true, filter.CommunityNumberOfTotalParticipantsValidated) &&
		validatedMatch(true, filter.ProjectCommunityTreesPlantedValidated) &&
		validatedMatch(true, filter.ProjectCommunityTreesDistributedValidated)
	)
}

function defaultReforestationValidationFlagsToTrue(filter: Filter) {
	return (
		validatedMatch(true, filter.TTFForestCodeValidated) &&
		validatedMatch(true, filter.TTFPlantingTimeframeValidated) &&
		validatedMatch(true, filter.TTFCommitmentFiscalYearValidated) &&
		validatedMatch(true, filter.TTFAgreementNumberValidated) &&
		validatedMatch(true, filter.TTFTotalTreeCostValidated) &&
		validatedMatch(true, filter.ProjectTTFTreesValidated) &&
		validatedMatch(true, filter.TTFDBHValidated)
	)
}

function defaultCarbonValidationFlagsToTrue(filter: Filter) {
	return (
		validatedMatch(true, filter.CarbonTotalCreditsIssuedValidated) &&
		validatedMatch(true, filter.CarbonAdditionalDetailsLinkValidated) &&
		validatedMatch(true, filter.CarbonProjectRegistryLinkValidated) &&
		validatedMatch(true, filter.SustainableDevelopmentGoalsValidated) &&
		validatedMatch(true, filter.AdditionalCarbonCreditCertificationsValidated) &&
		validatedMatch(true, filter.CarbonICroaEndorsedStandardValidated) &&
		validatedMatch(true, filter.CarbonCorsiaEligibleValidated) &&
		validatedMatch(true, filter.CarbonProjectStandardValidated) &&
		validatedMatch(true, filter.CarbonCreditCreditTypeValidated)
	)
}

function carbonValidationFlags(project: PlantingProject, filter: Filter) {
	return (
		validatedMatch(project.EverythingValidated, filter.EverythingValidated) &&
		validatedMatch(project.ProjectTypeValidated, filter.ProjectTypeValidated) &&
		validatedMatch(project.NameValidated, filter.NameValidated) &&
		validatedMatch(project.CompletedValidated, filter.ProjectStatusValidated) &&
		validatedMatch(project.LocationValidated, filter.LocationValidated) &&
		validatedMatch(project.MapValidated, filter.MapValidated) &&
		validatedMatch(project.ProjectYearsValidated, filter.ProjectYearsValidated) &&
		validatedMatch(project.ImpactStoryCompletedValidated, filter.ImpactStoryCompletedValidated) &&
		booleanMatch(project.HasImpactStoryCompleted, filter.HasImpactStoryCompleted) &&
		validatedMatch(project.SustainableDevelopmentGoalsValidated, filter.SustainableDevelopmentGoalsValidated) &&
		validatedMatch(project.PlantingPartnerNameValidated, filter.PlantingPartnerValidated) &&
		validatedMatch(project.AcresRestoredValidated, filter.ProjectAcresRestoredValidated) &&
		validatedMatch(project.CarbonTotalCreditsIssuedValidated, filter.CarbonTotalCreditsIssuedValidated) &&
		validatedMatch(project.CarbonProjectStandardValidated, filter.CarbonProjectStandardValidated) &&
		validatedMatch(project.CarbonCreditCreditTypeValidated, filter.CarbonCreditCreditTypeValidated) &&
		validatedMatch(project.CarbonICroaEndorsedStandardValidated, filter.CarbonICroaEndorsedStandardValidated) &&
		validatedMatch(project.CarbonCorsiaEligibleValidated, filter.CarbonCorsiaEligibleValidated) &&
		validatedMatch(project.AdditionalCarbonCreditCertificationsValidated, filter.AdditionalCarbonCreditCertificationsValidated) &&
		validatedMatch(project.CarbonAdditionalDetailsLinkValidated, filter.CarbonAdditionalDetailsLinkValidated) &&
		validatedMatch(project.CarbonProjectRegistryLinkValidated, filter.CarbonProjectRegistryLinkValidated) &&
		validatedMatch(project.ImagesValidated, filter.ImagesValidated) &&

		validatedMatch(true, filter.ReasonForPlantingValidated) &&
		validatedMatch(true, filter.TreeSpeciesValidated) &&
		validatedMatch(true, filter.ImpactStoryInProgressValidated) &&
		booleanMatch(true, filter.TreeSpecies) &&
		booleanMatch(true, filter.HasImpactStoryInProgress) &&

		defaultCommunityEventValidationFlagsToTrue(filter) &&
		defaultReforestationValidationFlagsToTrue(filter)
	);
}

function communityCanopyValidationFlags(project: PlantingProject, filter: Filter) {
	return (
		validatedMatch(project.EverythingValidated, filter.EverythingValidated) &&
		validatedMatch(project.ProjectTypeValidated, filter.ProjectTypeValidated) &&
		validatedMatch(project.NameValidated, filter.NameValidated) &&
		validatedMatch(project.CompletedValidated, filter.ProjectStatusValidated) &&
		validatedMatch(project.CommunityEventDatesValidated, filter.CommunityEventDatesValidated) &&
		validatedMatch(project.ReasonForPlantingValidated, filter.ReasonForPlantingValidated) &&
		validatedMatch(project.ImpactStoryCompletedValidated, filter.ImpactStoryCompletedValidated) &&
		booleanMatch(project.HasImpactStoryCompleted, filter.HasImpactStoryCompleted) &&

		validatedMatch(true, filter.ProjectAcresRestoredValidated) &&
		validatedMatch(true, filter.LocationValidated) &&
		validatedMatch(true, filter.MapValidated) &&
		validatedMatch(true, filter.ProjectYearsValidated) &&
		validatedMatch(true, filter.ImpactStoryInProgressValidated) &&
		validatedMatch(true, filter.PlantingPartnerValidated) &&
		booleanMatch(true, filter.HasHeroImage) &&
		validatedMatch(true, filter.ImagesValidated) &&
		booleanMatch(true, filter.TreeSpecies) &&
		validatedMatch(true, filter.TreeSpeciesValidated) &&
		booleanMatch(true, filter.HasImpactStoryInProgress) &&

		defaultCarbonValidationFlagsToTrue(filter) &&
		defaultCommunityEventValidationFlagsToTrue(filter) &&
		defaultReforestationValidationFlagsToTrue(filter)
	);
}

function communityEventValidationFlags(project: PlantingProject, filter: Filter) {

	return (
		validatedMatch(project.EverythingValidated, filter.EverythingValidated) &&
		validatedMatch(project.ProjectTypeValidated, filter.ProjectTypeValidated) &&
		validatedMatch(project.NameValidated, filter.NameValidated) &&
		validatedMatch(project.CompletedValidated, filter.ProjectStatusValidated) &&
		validatedMatch(project.LocationValidated, filter.LocationValidated) &&
		validatedMatch(project.CommunitySiteNameValidated, filter.CommunitySiteNameValidated) &&
		validatedMatch(project.CommunitySiteAddressValidated, filter.CommunitySiteAddressValidated) &&
		validatedMatch(project.CommunitySiteCityValidated, filter.CommunitySiteCityValidated) &&
		validatedMatch(project.CommunitySiteStateCodeValidated, filter.CommunitySiteStateCodeValidated) &&
		validatedMatch(project.CommunitySitePostalCodeValidated, filter.CommunitySitePostalCodeValidated) &&
		validatedMatch(project.CommunitySiteCountryCodeValidated, filter.CommunitySiteCountryCodeValidated) &&
		validatedMatch(project.MapValidated, filter.MapValidated) &&
		validatedMatch(project.CommunityEventDatesValidated, filter.CommunityEventDatesValidated) &&
		validatedMatch(project.ReasonForPlantingValidated, filter.ReasonForPlantingValidated) &&
		validatedMatch(project.ImpactStoryInProgressValidated, filter.ImpactStoryInProgressValidated) &&
		validatedMatch(project.ImpactStoryCompletedValidated, filter.ImpactStoryCompletedValidated) &&
		validatedMatch(project.PlantingPartnerNameValidated, filter.PlantingPartnerValidated) &&
		validatedMatch(project.CommunityNumberOfCorporateVolunteersValidated, filter.CommunityNumberOfCorporateVolunteersValidated) &&
		validatedMatch(project.CommunityNumberOfTotalParticipantsValidated, filter.CommunityNumberOfTotalParticipantsValidated) &&
		validatedMatch(project.CommunityTreesPlantedValidated, filter.ProjectCommunityTreesPlantedValidated) &&
		validatedMatch(project.CommunityTreesDistributedValidated, filter.ProjectCommunityTreesDistributedValidated) &&
		validatedMatch(project.CommunityDBHTreesPlantedValidated, filter.CommunityDBHTreesPlantedValidated) &&
		validatedMatch(project.CommunityDBHTreesDistributedValidated, filter.CommunityDBHTreesDistributedValidated) &&
		validatedMatch(project.TreeSpeciesValidated, filter.TreeSpeciesValidated) &&
		booleanMatch(project.HasTreeSpecies, filter.TreeSpecies) &&
		validatedMatch(project.ImagesValidated, filter.ImagesValidated) &&

		validatedMatch(true, filter.ProjectAcresRestoredValidated) &&
		validatedMatch(true, filter.ProjectYearsValidated) &&

		defaultCarbonValidationFlagsToTrue(filter) &&
		defaultReforestationValidationFlagsToTrue(filter)
	);
}

function reforestationValidationFlags(project: PlantingProject, filter: Filter) {

	return (
		validatedMatch(project.EverythingValidated, filter.EverythingValidated) &&
		validatedMatch(project.ProjectTypeValidated, filter.ProjectTypeValidated) &&
		validatedMatch(project.NameValidated, filter.NameValidated) &&
		validatedMatch(project.CompletedValidated, filter.ProjectStatusValidated) &&
		validatedMatch(project.LocationValidated, filter.LocationValidated) &&
		validatedMatch(project.TTFForestCodeValidated, filter.TTFForestCodeValidated) &&
		validatedMatch(project.MapValidated, filter.MapValidated) &&
		validatedMatch(project.ProjectYearsValidated, filter.ProjectYearsValidated) &&
		validatedMatch(project.TTFPlantingTimeframeValidated, filter.TTFPlantingTimeframeValidated) &&
		validatedMatch(project.ReasonForPlantingValidated, filter.ReasonForPlantingValidated) &&
		validatedMatch(project.ImpactStoryInProgressValidated, filter.ImpactStoryInProgressValidated) &&
		validatedMatch(project.ImpactStoryCompletedValidated, filter.ImpactStoryCompletedValidated) &&
		booleanMatch(project.HasImpactStoryInProgress, filter.HasImpactStoryInProgress) &&
		booleanMatch(project.HasImpactStoryCompleted, filter.HasImpactStoryCompleted) &&
		validatedMatch(project.PlantingPartnerNameValidated, filter.PlantingPartnerValidated) &&
		validatedMatch(project.TTFCommitmentFiscalYearValidated, filter.TTFCommitmentFiscalYearValidated) &&
		validatedMatch(project.TTFAgreementNumberValidated, filter.TTFAgreementNumberValidated) &&
		validatedMatch(project.TTFTotalTreeCostValidated, filter.TTFTotalTreeCostValidated) &&
		validatedMatch(project.TTFTreesValidated, filter.ProjectTTFTreesValidated) &&
		validatedMatch(project.TTFDBHValidated, filter.TTFDBHValidated) &&
		validatedMatch(project.AcresRestoredValidated, filter.ProjectAcresRestoredValidated) &&
		validatedMatch(project.TreeSpeciesValidated, filter.TreeSpeciesValidated) &&
		booleanMatch(project.HasTreeSpecies, filter.TreeSpecies) &&
		validatedMatch(project.ImagesValidated, filter.ImagesValidated) &&
		defaultCarbonValidationFlagsToTrue(filter) &&
		defaultCommunityEventValidationFlagsToTrue(filter)
	);
}

function filterInputFields(project: PlantingProject, filter: Filter, lythoImageData) {
	return (
		textMatch(project.Id.toString(), filter.Id) &&
		(!filter.ProjectType || filter.ProjectType === project.ProjectType) &&
		textMatch(project.Name, filter.Name) &&

		textMatch(formatProjectStatus(project.Completed), filter.ProjectStatus) &&
		textMatch(project.Location, filter.Location) &&
		textMatch(project.TTFForestName, filter.TTFForest) &&
		textMatch(project.CommunitySiteName, filter.CommunitySiteName) &&
		textMatch(project.CommunitySiteAddress, filter.CommunitySiteAddress) &&
		textMatch(project.CommunitySiteCity, filter.CommunitySiteCity) &&
		textMatch(project.CommunitySiteStateCode, filter.CommunitySiteStateCode) &&
		textMatch(project.CommunitySitePostalCode, filter.CommunitySitePostalCode) &&
		textMatch(project.CommunitySiteCountryCode, filter.CommunitySiteCountryCode) &&
		numberRangeMatch(
			project.ProjectStartYear,
			project.ProjectEndYear,
			filter.ProjectYears
		) &&
		textMatch(project.CommunityFirstDayOfEvent, filter.CommunityEventDates) &&
		textMatch(project.TTFPlantingTimeframe, filter.TTFPlantingTimeframe) &&
		textMatch(project.ReasonForPlanting, filter.ReasonForPlanting) &&
		booleanMatch(project.HasImpactStoryInProgress, filter.HasImpactStoryInProgress) &&
		booleanMatch(project.HasImpactStoryCompleted, filter.HasImpactStoryCompleted) &&
		textMatch(project.PlantingPartnerName, filter.PlantingPartner) &&
		textMatch(
			project.Commitments?.map((commitment) => commitment.CommitmentId).join(', '),
			filter.TTFCommitments
		) &&
		textMatch(project.TTFCommitmentFiscalYear, filter.TTFCommitmentFiscalYear) &&
		textMatch(project.TTFAgreementNumber, filter.TTFAgreementNumber) &&
		numberMatch(project.TTFTotalTreeCost, filter.TTFTotalTreeCost) &&
		numberMatch(
			project.CommunityNumberOfCorporateVolunteers,
			filter.CommunityNumberOfCorporateVolunteers
		) &&
		numberMatch(
			project.CommunityNumberOfTotalParticipants,
			filter.CommunityNumberOfTotalParticipants
		) &&
		numberMatch(project.TTFTrees, filter.ProjectTTFTrees) &&
		numberMatch(project.CommunityTreesPlanted, filter.ProjectCommunityTreesPlanted) &&
		numberMatch(
			project.CommunityTreesDistributed,
			filter.ProjectCommunityTreesDistributed
		) &&
		numberMatch(project.AcresRestored, filter.ProjectAcresRestored) &&
		numberMatch(project.TTFDBH, filter.TTFDBH) &&
		numberMatch(project.CommunityDBHTreesPlanted, filter.CommunityDBHTreesPlanted) &&
		numberMatch(
			project.CommunityDBHTreesDistributed,
			filter.CommunityDBHTreesDistributed
		) &&
		numberMatch(
			project.GallonsTotalRunoffAvoidedPerTreeReforestation,
			filter.GallonsTotalRunoffAvoidedPerTreeReforestation
		) &&
		numberMatch(
			project.GallonsTotalRunoffAvoidedPerTreeEventPlanted,
			filter.GallonsTotalRunoffAvoidedPerTreeEventPlanted
		) &&
		numberMatch(
			project.GallonsTotalRunoffAvoidedPerTreeEventDistributed,
			filter.GallonsTotalRunoffAvoidedPerTreeEventDistributed
		) &&
		numberMatch(
			project.TonsCarbonPerTreeReforestation,
			filter.TonsCarbonPerTreeReforestation
		) &&
		numberMatch(
			project.TonsCarbonPerTreeEventPlanted,
			filter.TonsCarbonPerTreeEventPlanted
		) &&
		numberMatch(
			project.TonsCarbonPerTreeEventDistributed,
			filter.TonsCarbonPerTreeEventDistributed
		) &&
		numberMatch(
			project.TonsAirPollutionPerTreeReforestation,
			filter.TonsAirPollutionPerTreeReforestation
		) &&
		numberMatch(
			project.TonsAirPollutionPerTreeEventPlanted,
			filter.TonsAirPollutionPerTreeEventPlanted
		) &&
		numberMatch(
			project.TonsAirPollutionPerTreeEventDistributed,
			filter.TonsAirPollutionPerTreeEventDistributed
		) &&
		textMatch(project.DAMTag, filter.DAMTag) &&
		numberMatch(project.CarbonTotalCreditsIssued, filter.CarbonTotalCreditsIssued) &&
		textMatch(project.CarbonAdditionalDetailsLink, filter.CarbonAdditionalDetailsLink) &&
		textMatch(project.CarbonProjectRegistryLink, filter.CarbonProjectRegistryLink) &&
		textMatch(
			formatSustainableDevelopmentGoalArray(project.SustainableDevelopmentGoals),
			filter.SustainableDevelopmentGoals
		) &&
		textMatch(
			formatAdditionalCertificationsArray(project.CarbonCreditsAdditionalCertifications),
			filter.CarbonCreditsAdditionalCertifications
		) &&
		textMatch(
			formatYesNoConditional(project.CarbonICroaEndorsedStandard),
			filter.CarbonICroaEndorsedStandard
		) &&
		textMatch(
			formatYesNoConditional(project.CarbonCorsiaEligible),
			filter.CarbonCorsiaEligible
		) &&
		textMatch(
			formatCarbonProjectStandards(project.CarbonProjectStandard),
			filter.CarbonProjectStandard
		) &&
		textMatch(
			formatCarbonCreditTypes(project.CarbonCreditCreditType),
			filter.CarbonCreditCreditType
		) &&
		textMatch(
			project.BHCampaigns?.map((campaign) => campaign.CampaignId).join(' '),
			filter.BHCampaigns
		) &&
		textMatch(
			project.FundingPartners?.map((partner) => partner.Name).join(', '),
			filter.FundingPartners
		) &&
		numberMatch(lythoImageData ? lythoImageData["image-count"] : 0, filter.ImageCount) &&
		booleanMatch(lythoImageData ? lythoImageData['hero'] : false, filter.HasHeroImage));
}


export function filterProjects(projects: PlantingProject[], lythoImageData, filter: Filter) {

	return projects.filter((project) => {
		if (!filter) return true;

		const lythoProjectData = lythoImageData ? lythoImageData[project.DAMTag] : null;
		switch (project.ProjectType) {
			case PlantingProjectType.Carbon:
				return (carbonValidationFlags(project, filter)
					&& filterInputFields(project, filter, lythoProjectData))
			case PlantingProjectType.CommunityCanopy:
				return (communityCanopyValidationFlags(project, filter)
					&& filterInputFields(project, filter, lythoProjectData))
			case PlantingProjectType.Reforestation:
				return (reforestationValidationFlags(project, filter)
					&& filterInputFields(project, filter, lythoProjectData))
			case PlantingProjectType.CommunityEvent:
				return (communityEventValidationFlags(project, filter)
					&& filterInputFields(project, filter, lythoProjectData))
			default: return false;
		}
	});
}