import {PlantingProjectType} from '../../../entities';
import {
    LabelContainer,
    Label,
    RequiredToShowToPartnerSignifier,
    ValidationsList,
    DateControlShowingEdits,
    FormFieldContainer
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const DateFormFieldContainer = styled(FormFieldContainer)`
    width: 200px;
`;

export const DateRangeBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const DateRangeContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const DateContainer = styled(FormFieldContainer)`
    & > input {
        max-width: 100px;
    }
`;

export function CommunityEventDatesControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <DateRangeBlock>
            <LabelContainer>
                {!project.ControlledByAutomation &&
                    project.ProjectType.current !== PlantingProjectType.CommunityCanopy && (
                        <ValidateControl
                            validated={project.CommunityEventDatesValidated.current}
                            disabled={
                                project.CommunityFirstDayOfEvent.notValidToShowPartner ||
                                project.CommunityFinalDayOfEvent.notValidToShowPartner
                            }
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    CommunityEventDatesValidated:
                                        project.CommunityEventDatesValidated.update(e.validated)
                                })
                            }
                        />
                    )}
                Community event dates
            </LabelContainer>
            <DateRangeContainer>
                <DateFormFieldContainer>
                    <LabelContainer>
                        <Label htmlFor="eventDateFirstDay">First day</Label>
                        <RequiredToShowToPartnerSignifier />
                    </LabelContainer>
                    <DateControlShowingEdits
                        id="eventDateFirstDay"
                        disabled={
                            project.ControlledByAutomation ||
                            project.ProjectType.current === PlantingProjectType.CommunityCanopy
                        }
                        value={project.CommunityFirstDayOfEvent}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CommunityFirstDayOfEvent: project.CommunityFirstDayOfEvent.update(
                                    e.target.value
                                )
                            })
                        }></DateControlShowingEdits>
                </DateFormFieldContainer>
                <DateFormFieldContainer>
                    <LabelContainer>
                        <Label htmlFor="eventDateFinalDay">Final day</Label>
                        <RequiredToShowToPartnerSignifier />
                    </LabelContainer>
                    <DateControlShowingEdits
                        id="eventDateFinalDay"
                        disabled={
                            project.ControlledByAutomation ||
                            project.ProjectType.current === PlantingProjectType.CommunityCanopy
                        }
                        value={project.CommunityFinalDayOfEvent}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CommunityFinalDayOfEvent: project.CommunityFinalDayOfEvent.update(
                                    e.target.value
                                )
                            })
                        }></DateControlShowingEdits>
                </DateFormFieldContainer>
            </DateRangeContainer>
            {project.CommunityFirstDayOfEvent.validations.length > 0 && (
                <ValidationsList validations={project.CommunityFirstDayOfEvent.validations} />
            )}
            {project.CommunityFinalDayOfEvent.validations.length > 0 && (
                <ValidationsList validations={project.CommunityFinalDayOfEvent.validations} />
            )}
        </DateRangeBlock>
    );
}
