// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import './index.css';
import {createRoot} from 'react-dom/client';
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import App from './App';
import Partners from './routes/Partners';
import Partner from './routes/Partner';
import PlantingProjects from './routes/PlantingProjects';
import PlantingProject from './routes/PlantingProject';
import styled from 'styled-components';
import {useUser, UserProvider} from './providers/UserProvider';
import PlantingProjectNew from './routes/PlantingProjectNew';
import {ConfigProvider} from './providers/ConfigProvider';
import PartnerNew from './routes/PartnerNew';
import AutomationRunLogs from './routes/AutomationRunLogs';
import CarbonCreditImporter from './routes/CarbonCreditImporter';

const IndexContainer = styled.div`
    padding: 1rem;
`;

const ToolHeader = styled.h1`
    margin-top: 0;
`;

function Index() {
    return (
        <IndexContainer>
            <ToolHeader>Impact Dashboard Data Review Tool</ToolHeader>
            <p>This tool can be used to review the data loaded in the Impact Dashboard.</p>
            <p>
                Users have to be invited into this tool in order to use it. Send a ticket to{' '}
                <a href="mailto:help@gonines.com">help@gonines.com</a> if you need access.
            </p>
        </IndexContainer>
    );
}

const UnauthorizedContainer = styled.div`
    padding: 1rem;
`;

function AuthorizedRoute({role, children}) {
    const userInfo = useUser();
    const location = useLocation();

    const roleArray = role.split(', ');

    if (!userInfo.userInfoRetrieved) return null;
    else if (!userInfo.user) {
        window.location.href =
            '/.auth/login/aad?post_login_redirect_uri=' +
            encodeURIComponent(location.pathname + location.search);
        return null;
    } else if (!roleArray.some((substring) => userInfo.user.userRoles.includes(substring)))
        return (
            <UnauthorizedContainer>You aren't authorized to view this page</UnauthorizedContainer>
        );
    else return children;
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <ConfigProvider>
        <UserProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route
                            path="partners"
                            element={
                                <AuthorizedRoute role="editor, reader">
                                    <Partners />
                                </AuthorizedRoute>
                            }>
                            <Route path=":partnerId" element={<Partner />} />
                            <Route
                                index
                                element={<div style={{padding: '1rem'}}>Select a partner</div>}
                            />
                            <Route path="new" element={<PartnerNew />} />
                        </Route>
                        <Route
                            path="planting-projects"
                            element={
                                <AuthorizedRoute role="editor, reader">
                                    <PlantingProjects />
                                </AuthorizedRoute>
                            }></Route>
                        <Route
                            path="planting-projects/:projectId"
                            element={
                                <AuthorizedRoute role="editor, reader">
                                    <PlantingProject />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="planting-projects/new/:projectType"
                            element={
                                <AuthorizedRoute role="editor">
                                    <PlantingProjectNew />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="import-carbon-credits"
                            element={
                                <AuthorizedRoute role="editor">
                                    <CarbonCreditImporter />
                                </AuthorizedRoute>
                            }
                        />
                        <Route
                            path="automation-run-logs"
                            element={
                                <AuthorizedRoute role="editor, reader">
                                    <AutomationRunLogs />
                                </AuthorizedRoute>
                            }
                        />
                        <Route index element={<Index />} />
                        <Route
                            path="*"
                            element={
                                <main style={{padding: '1rem'}}>
                                    <p>Page not found</p>
                                </main>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserProvider>
    </ConfigProvider>
);
