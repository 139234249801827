import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    NumberControlShowingEdits,
    ValidationsList
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import {NumericFormFieldContainer} from '../MultipleProjectTypes/NumericFormFieldContainer';

export function TTFTreesPlantedControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <NumericFormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.TTFTreesValidated.current}
                        disabled={project.TTFTrees.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                TTFTreesValidated: project.TTFTreesValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="totalTrees">Trees planted</Label>
            </LabelContainer>
            <NumberControlShowingEdits
                id="totalTrees"
                disabled={project.ControlledByAutomation}
                value={project.TTFTrees}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        TTFTrees: project.TTFTrees.update(getNumberFromInput(e))
                    })
                }></NumberControlShowingEdits>
            <ValidationsList validations={project.TTFTrees.validations} />
        </NumericFormFieldContainer>
    );
}
