import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import {
	ValidatedIcon,
	ValidateFilter,
	ValidateFilterValue
} from "../../../../components/Controls/ValidateControl";
import {
	ComplexInputWithAsyncUpdate,
	TextControlShowingEdits,
	TextFilter,
	ValidationsList
} from "../../../../components/Form/FormStyles";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { EditedParticipation } from "../../../Partner/EditedPartner";
import {
	AlignToTextBoxContent,
	ValidationTippy,
	ValidationTippyContent
} from "../../Styles/ParticipationRowStyles";
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications";

export function VintageYearHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '150px',
				minWidth: '150px',
				maxWidth: '150px'
			}}>
			Vintage Year
		</StickyHeader>
	)
}

export function VintageYearFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.VintageYearValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, VintageYearValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.VintageYear ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							VintageYear: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function VintageYearContent({
	ppn,
	updater
}: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledNumberCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<ValidatedIcon />
				) : (
					<>
						<ValidatedIcon />
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={ppn.VintageYear.validations}
									/>
								</ValidationTippyContent>
							}
							disabled={ppn.VintageYear.validations.length === 0}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={TextControlShowingEdits}
									showModification
									value={ppn.VintageYear}
									onChange={(updatedValue) =>
										updater.update({
											...ppn,
											VintageYear: updatedValue
										})
									}
									valueGetter={(e) =>
										ppn.VintageYear.update(e.target.value)
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}