import {useNavigate, useParams} from 'react-router-dom';
import PlantingProjectEditor from '../containers/PlantingProjectEditor/PlantingProjectEditor';
import {PlantingProjectType} from '../entities';

function PlantingProjectNew() {
    const {projectType} = useParams();
    const projectTypeEnum = parseInt(projectType) as PlantingProjectType;
    const navigate = useNavigate();

    function handleProjectAdded(id: number) {
        navigate(`/planting-projects/${id}`);
    }

    return (
        <PlantingProjectEditor
            newProject={true}
            newProjectType={projectTypeEnum}
            projectAdded={handleProjectAdded}
        />
    );
}

export default PlantingProjectNew;
