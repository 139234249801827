import {useState, useEffect} from 'react';
import Select from 'react-select';
import YesNoConditionalOption, {getYesNoConditionalOptions} from '../../YesNoConditional';
import {EditedProject} from '../EditedProject';

export function CarbonICroaEndorsed({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [options, setOptions] = useState<YesNoConditionalOption[]>(null);
    const [selectedOption, setSelectedOption] = useState<YesNoConditionalOption>(null);

    useEffect(() => {
        setOptions(getYesNoConditionalOptions());
    }, []);

    useEffect(() => {
        const selected = project.CarbonICroaEndorsedStandard.current;
        setSelectedOption(options?.find((x) => x.value === selected));
    }, [options]);

    const onChange = (e) => {
        setSelectedOption(e);
        setProjectAndValidate({
            ...project,
            CarbonICroaEndorsedStandard: project.CarbonICroaEndorsedStandard.update(e.value)
        });
    };

    return <Select value={selectedOption} onChange={(e) => onChange(e)} options={options} />;
}
export default CarbonICroaEndorsed;
