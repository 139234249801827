import {EditedParticipation} from '../../../Partner/EditedPartner';
import {AcresRestoredContent} from '../../ParticipationColumns/Shared/AcresRestored';
import {DAMTagContent} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationContent} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedContent} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedContent} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedContent} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearContent} from '../../ParticipationColumns/Shared/FundedYear';
import {HasHeroContent} from '../../ParticipationColumns/Shared/HasHero';
import {HasTreeSpeciesContent} from '../../ParticipationColumns/Shared/HasTreeSpecies';
import {ImageCountContent} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedContent} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedContent} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ImpactStoryInProgressContent} from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import {MapContent} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdContent} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerContent} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdContent} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectLocationContent} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ProjectNameContent} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusContent} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeContent} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ProjectYearsContent} from '../../ParticipationColumns/Shared/ProjectYears';
import {ReasonForPlantingContent} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {D365Content} from '../../ParticipationColumns/Reforestation/D365';
import {TTFAgreementNumberContent} from '../../ParticipationColumns/Reforestation/TTFAgreementNumber';
import {TTFCommitmentFiscalYearContent} from '../../ParticipationColumns/Reforestation/TTFCommitmentFiscalYear';
import {TTFCommitmentsContent} from '../../ParticipationColumns/Reforestation/TTFCommitments';
import {TTFDBHContent} from '../../ParticipationColumns/Reforestation/TTFDBH';
import {TTFForestContent} from '../../ParticipationColumns/Reforestation/TTFForest';
import {TTFPlantingTimeframeContent} from '../../ParticipationColumns/Reforestation/TTFPlantingTimeframe';
import {TTFTotalTreeCostContent} from '../../ParticipationColumns/Reforestation/TTFTotalTreeCost';
import {TTFTreesContent} from '../../ParticipationColumns/Reforestation/TTFTrees';
import {THContent} from '../../ParticipationColumns/Reforestation/TreeHarmony';
import {ShowOnDateContent} from '../../ParticipationColumns/Shared/ShowOnDate';
import {
    ParticipationRemover,
    ParticipationUnremover,
    ParticipationUpdater,
    ProjectSelectionSetter
} from '../../ParticipationFunctions/ParticipationModifications';
import {ProjectTTFTreesContent} from '../../ParticipationColumns/Reforestation/ProjectTTFTrees';
import {FundingSourceContent} from '../../ParticipationColumns/Shared/FundingSource';

export function ReforestationContent({
    role,
    participation,
    remover,
    unremover,
    updater,
    projectSelectionSetter,
    treeHarmonyBaseUrl,
    imageData
}: {
    role: string[];
    participation: EditedParticipation;
    remover: ParticipationRemover;
    unremover: ParticipationUnremover;
    updater: ParticipationUpdater;
    projectSelectionSetter: ProjectSelectionSetter;
    treeHarmonyBaseUrl: string;
    imageData: {hasHero: boolean; imageCount: number};
}) {
    return (
        <tr>
            <DeleteParticipationContent
                ppn={participation}
                remover={remover}
                unremover={unremover}
                role={role}
            />
            <ExcludedContent ppn={participation} updater={updater} />
            <EverythingValidatedContent ppn={participation} />
            <ParticipationIdContent ppn={participation} />
            <FundedYearContent ppn={participation} updater={updater} />
            <FundingSourceContent ppn={participation} updater={updater} />
            <TTFTreesContent ppn={participation} updater={updater} />
            <DollarDonatedContent ppn={participation} updater={updater} />
            <ShowOnDateContent ppn={participation} updater={updater} />
            <D365Content ppn={participation} />
            <THContent ppn={participation} treeHarmonyBaseUrl={treeHarmonyBaseUrl} />
            <ProjectIdContent ppn={participation} projectSelectionSetter={projectSelectionSetter} />
            <ProjectTypeContent ppn={participation} />
            <ProjectNameContent ppn={participation} />
            <ProjectStatusContent ppn={participation} />
            <ProjectLocationContent ppn={participation} />
            <TTFForestContent ppn={participation} />
            <MapContent ppn={participation} />
            <ProjectYearsContent ppn={participation} />
            <TTFPlantingTimeframeContent ppn={participation} />
            <ReasonForPlantingContent ppn={participation} />
            <ImpactStoryInProgressContent ppn={participation} />
            <ImpactStoryCompletedContent ppn={participation} />
            <PlantingPartnerContent ppn={participation} />
            <TTFCommitmentsContent ppn={participation} treeHarmonyBaseUrl={treeHarmonyBaseUrl} />
            <TTFCommitmentFiscalYearContent ppn={participation} />
            <TTFAgreementNumberContent ppn={participation} />
            <TTFTotalTreeCostContent ppn={participation} />
            <ProjectTTFTreesContent ppn={participation} />
            <TTFDBHContent ppn={participation} />
            <AcresRestoredContent ppn={participation} />
            <HasTreeSpeciesContent ppn={participation} />
            <DAMTagContent ppn={participation} />
            <ImagesValidatedContent ppn={participation} />
            <ImageCountContent ppn={participation} imageCount={imageData.imageCount} />
            <HasHeroContent ppn={participation} hasHero={imageData.hasHero} />
        </tr>
    );
}
