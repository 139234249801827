import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export function NotExcludedCheckboxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                color="rgb(200, 200, 200)"
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
            />
        </svg>
    );
}

export function ExcludedCheckboxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                color="rgb(255, 147, 69)"
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
            />
            <path
                color="rgb(255, 147, 69)"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
        </svg>
    );
}

const Label = styled.label`
    height: 18px;
    line-height: 1;
    vertical-align: bottom;
`;

const HiddenCheckbox = styled.input`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`;

export function ExcludeControl({
    excluded,
    onChange
}: {
    excluded: boolean;
    onChange: ({excluded}) => void;
}) {
    const message = excluded
        ? 'Uncheck to show this project to the partner'
        : 'Check to exclude this project from showing to the partner';

    return (
        <Tippy content={message}>
            <Label>
                <HiddenCheckbox
                    type="checkbox"
                    checked={excluded}
                    onChange={(e) => onChange({excluded: e.target.checked})}
                />
                {excluded ? <ExcludedCheckboxIcon /> : <NotExcludedCheckboxIcon />}
            </Label>
        </Tippy>
    );
}

function NotFilteredCheckboxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                color="rgb(200, 200, 200)"
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
            />
            <path
                color="rgb(200, 200, 200)"
                d="M11.354 4.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0z"
            />
        </svg>
    );
}

export enum ExcludeFilterValue {
    Unset = 1,
    Excluded = 2,
    NotExcluded = 3
}

const FilterContainer = styled.span`
    height: 18px;
    line-height: 1;
    vertical-align: bottom;
`;

export function ExcludeFilter({
    value,
    onChange
}: {
    value: ExcludeFilterValue;
    onChange: ({value}: {value: ExcludeFilterValue}) => void;
}) {
    function message() {
        switch (value) {
            case ExcludeFilterValue.Unset:
                return 'Showing included and excluded';
            case ExcludeFilterValue.Excluded:
                return 'Showing excluded';
            case ExcludeFilterValue.NotExcluded:
                return 'Showing included';
            default:
                return null;
        }
    }

    function icon() {
        switch (value) {
            case ExcludeFilterValue.Unset:
                return <NotFilteredCheckboxIcon />;
            case ExcludeFilterValue.Excluded:
                return <ExcludedCheckboxIcon />;
            case ExcludeFilterValue.NotExcluded:
                return <NotExcludedCheckboxIcon />;
            default:
                return null;
        }
    }

    function nextValue() {
        switch (value) {
            case ExcludeFilterValue.Unset:
                return ExcludeFilterValue.Excluded;
            case ExcludeFilterValue.Excluded:
                return ExcludeFilterValue.NotExcluded;
            case ExcludeFilterValue.NotExcluded:
                return ExcludeFilterValue.Unset;
            default:
                return null;
        }
    }

    return (
        <Tippy content={message()}>
            <FilterContainer onClick={() => onChange({value: nextValue()})}>
                {icon()}
            </FilterContainer>
        </Tippy>
    );
}
