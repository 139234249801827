import { CellWithValidator, StickyNumericHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications";
import { Filter } from "../../ParticipationFunctions/Filtering";
import {
	ValidateControl,
	ValidatedIcon,
	ValidateFilter,
	ValidateFilterValue
} from "../../../../components/Controls/ValidateControl";
import { ComplexInputWithAsyncUpdate, NumericFilter, ValidationsList } from "../../../../components/Form/FormStyles";
import {
	AlignToTextBoxContent,
	DollarsControl,
	ValidationTippy,
	ValidationTippyContent
} from "../../Styles/ParticipationRowStyles";
import { getNumberFromInput } from "../../../../record-editing";

export function CommunityBudgetPassThroughHeader() {
	return (
		<StickyNumericHeader
			style={{ ...offsetOneRow, width: '165px', minWidth: '165px', maxWidth: '165px' }}>
			Community budget pass-through
		</StickyNumericHeader>

	)
}

export function CommunityBudgetPassThroughFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CommunityBudgetPassThroughValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityBudgetPassThroughValidated: e.value
						})
					}
				/>
				<NumericFilter
					value={filter?.CommunityBudgetPassThrough ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityBudgetPassThrough: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function CommunityBudgetPassThroughContent({ ppn, updater }: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledNumberCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<>
						{ppn.CommunityBudgetPassThroughValidated.current && (
							<ValidatedIcon />
						)}
						<AlignToTextBoxContent>
							{ppn.CommunityBudgetPassThrough.current}
						</AlignToTextBoxContent>
					</>
				) : (
					<>
						<ValidateControl
							validated={ppn.CommunityBudgetPassThroughValidated.current}
							disabled={ppn.CommunityBudgetPassThrough.notValidToShowPartner}
							onChange={(e) =>
								updater.update({
									...ppn,
									CommunityBudgetPassThroughValidated:
										ppn.CommunityBudgetPassThroughValidated.update(
											e.validated
										)
								})
							}
						/>
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={
											ppn.CommunityBudgetPassThrough.validations
										}
									/>
								</ValidationTippyContent>
							}
							disabled={
								ppn.CommunityBudgetPassThrough.validations.length === 0
							}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={DollarsControl}
									showModification
									value={ppn.CommunityBudgetPassThrough}
									onChange={(updatedValue) =>
										updater.update({
											...ppn,
											CommunityBudgetPassThrough: updatedValue
										})
									}
									valueGetter={(e) =>
										ppn.CommunityBudgetPassThrough.update(
											getNumberFromInput(e)
										)
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}