import { useState, useEffect } from 'react';
import { LythoImageProject } from '../../entities';
import { EditedParticipation } from '../Partner/EditedPartner';
import {
	ParticipationRemover,
	ParticipationUnremover,
	ParticipationUpdater,
	ProjectSelectionSetter
} from './ParticipationFunctions/ParticipationModifications';
import {
	ParticipationView,
	viewIncludesCarbon,
	viewIncludesCommunityCanopy,
	viewIncludesCommunityEvents,
	viewIncludesReforestation
} from './ParticipationViewSelector';
import { CarbonParticipationTableContent } from './ProjectTypeTables/CarbonTable/CarbonParticipationTableContent';
import { CommunityCanopyParticipationContent } from './ProjectTypeTables/CommunityCanopyTable/CommunityCanopyParticipationContent';
import { CommunityEventContent } from "./ProjectTypeTables/CommunityEventTable/CommunityEventContent";
import { ReforestationContent } from './ProjectTypeTables/ReforestationTable/ReforestationContent';

function ParticipationRow({
	ppn,
	lythoImageData,
	remover,
	unremover,
	updater,
	projectSelectionSetter,
	view,
	role,
	treeHarmonyBaseUrl
}: {
	ppn: EditedParticipation,
	lythoImageData: LythoImageProject[],
	remover: ParticipationRemover,
	unremover: ParticipationUnremover,
	updater: ParticipationUpdater,
	projectSelectionSetter: ProjectSelectionSetter,
	view: ParticipationView,
	role: string[],
	treeHarmonyBaseUrl: string
}) {
	const [imageData, setImageData] = useState<{ hasHero: boolean; imageCount: number }>({
		hasHero: false,
		imageCount: 0
	});

	useEffect(() => {
		if (lythoImageData == null) return;

		const imageData = lythoImageData ? lythoImageData[ppn.PlantingProject.DAMTag] : null;
		if (imageData)
			setImageData({
				hasHero: imageData['hero'] ?? false,
				imageCount: imageData['image-count'] ?? 0
			});
	}, [lythoImageData]);

	if (viewIncludesReforestation(view))
		return (
			<ReforestationContent
				participation={ppn}
				projectSelectionSetter={projectSelectionSetter}
				role={role}
				remover={remover}
				unremover={unremover}
				updater={updater}
				imageData={imageData}
				treeHarmonyBaseUrl={treeHarmonyBaseUrl} />
		)

	if (viewIncludesCommunityEvents(view))
		return (
			<CommunityEventContent
				participation={ppn}
				projectSelectionSetter={projectSelectionSetter}
				role={role}
				remover={remover}
				unremover={unremover}
				updater={updater}
				imageData={imageData} />
		)

	if (viewIncludesCommunityCanopy(view))
		return (
			<CommunityCanopyParticipationContent
				participation={ppn}
				projectSelectionSetter={projectSelectionSetter}
				role={role}
				remover={remover}
				unremover={unremover}
				updater={updater} />
		)

	if (viewIncludesCarbon(view))
		return (
			<CarbonParticipationTableContent
				participation={ppn}
				projectSelectionSetter={projectSelectionSetter}
				role={role}
				remover={remover}
				unremover={unremover}
				updater={updater}
				imageData={imageData} />
		)
}

export default ParticipationRow;
