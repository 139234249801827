import {useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {PlantingProjectType} from '../../entities';
import {AddButton} from '../Buttons/SaveChanges';

const Container = styled.div`
    position: relative;
    display: inline-block;
`;

const Content = styled.div`
    display: none;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    margin-top: 4px;
    right: 0;
    background-color: white;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.4);
    z-index: 1;
    padding: 0.5rem;

    &.show {
        display: flex;
    }
`;

const Option = styled(Link)`
    display: block;
    white-space: nowrap;
`;

const DisabledOption = styled.div`
    white-space: nowrap;
    color: rgb(0 0 0 / 0.35);
`;

export function AddProjectMenu({...props}) {
    const containerRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        if (!containerRef.current || !contentRef.current) return;

        const closeIfClickOutside = (evt) => {
            let target = evt.target;

            do {
                if (target == containerRef.current) return;
                target = (target as any).parentNode;
            } while (target);

            const contentElement = contentRef.current as any;
            contentElement.classList.remove('show');
        };

        window.addEventListener('click', closeIfClickOutside);

        return () => window.removeEventListener('click', closeIfClickOutside);
    }, [containerRef.current, contentRef.current]);

    function handleAddClicked(): void {
        if (contentRef.current) {
            const contentElement = contentRef.current as any;
            contentElement.classList.toggle('show');
        }
    }

    return (
        <Container ref={containerRef} {...props}>
            <AddButton onClick={handleAddClicked}>Add a project</AddButton>
            <Content ref={contentRef}>
                <Option to={`/planting-projects/new/${PlantingProjectType.Reforestation}`}>
                    Reforestation
                </Option>
                <Option to={`/planting-projects/new/${PlantingProjectType.CommunityEvent}`}>
                    Community Event
                </Option>
                <Option to={`/planting-projects/new/${PlantingProjectType.Carbon}`}>
                    Carbon Credit
                </Option>
                <DisabledOption>
                    Community Canopy
                    <br />
                    <small>(add through participation)</small>
                </DisabledOption>
            </Content>
        </Container>
    );
}
