import { ValidateFilter, ValidateFilterValue, ValidatedIcon, ValidateControl } from "../../../../components/Controls/ValidateControl"
import { NumericFilter, ValidationsList, ComplexInputWithAsyncUpdate } from "../../../../components/Form/FormStyles"
import { StickyNumericHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { PlantingProjectType } from "../../../../entities"
import { getNumberFromInput } from "../../../../record-editing"
import { formatDollars } from "../../../../utilities/currencyUtilities"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications"
import { AlignToTextBoxContent, ValidationTippy, ValidationTippyContent, DollarsControl } from "../../Styles/ParticipationRowStyles"
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles"

export function DollarDonatedHeader() {
	return (
		<StickyNumericHeader
			style={{ ...offsetOneRow, width: '152px', minWidth: '152px', maxWidth: '152px' }}>
			Dollars donated
		</StickyNumericHeader>
	)
}

export function DollarDonatedFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.DollarsDonatedValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, DollarsDonatedValidated: e.value })
					}
				/>
				<NumericFilter
					value={filter?.DollarsDonated ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, DollarsDonated: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function DollarDonatedContent({ ppn, updater }: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledNumberCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<>
						{ppn.DollarsDonatedValidated.current && <ValidatedIcon />}
						<AlignToTextBoxContent>
							{formatDollars(ppn.DollarsDonated.current)}
						</AlignToTextBoxContent>
					</>
				) : (
					<>
						{ppn.ProjectType === PlantingProjectType.Reforestation ||
							ppn.ProjectType === PlantingProjectType.CommunityEvent ||
							ppn.ProjectType === PlantingProjectType.Carbon ? (
							<ValidateControl
								validated={ppn.DollarsDonatedValidated.current}
								disabled={ppn.DollarsDonated.notValidToShowPartner}
								onChange={(e) =>
									updater.update({
										...ppn,
										DollarsDonatedValidated:
											ppn.DollarsDonatedValidated.update(e.validated)
									})
								}
							/>
						) : (
							<ValidatedIcon />
						)}
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={ppn.DollarsDonated.validations}
									/>
								</ValidationTippyContent>
							}
							disabled={ppn.DollarsDonated.validations.length === 0}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={DollarsControl}
									showModification
									value={ppn.DollarsDonated}
									onChange={(updatedValue) =>
										updater.update({
											...ppn,
											DollarsDonated: updatedValue
										})
									}
									valueGetter={(e) =>
										ppn.DollarsDonated.update(getNumberFromInput(e))
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}