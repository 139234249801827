import { useState } from "react";
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { NumericFilter } from "../../../../components/Form/FormStyles";
import { RecordEditStatus } from "../../../../record-editing";
import DocumentModal from "./DocumentModal";

export function CarbonCreditDocumentsHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '150px',
				minWidth: '150px',
				maxWidth: '150px'
			}}>
			Carbon Credit Documents
		</StickyHeader>
	)
}

export function CarbonCreditDocumentsFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CarbonDocumentsValidated ?? ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({ ...filter, CarbonDocumentsValidated: e.value })
					}
				/>
				<NumericFilter
					value={filter?.DocumentCount ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, DocumentCount: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function CarbonCreditDocumentsContent({
	ppn,
	role
}: { ppn: EditedParticipation, role }) {

	const [documentCount, setDocumentCount] = useState<number>(ppn.DocumentCount);
	const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);

	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				<ValidatedIcon />
				{ppn.EditStatus !== RecordEditStatus.New && (
					<div>
						<div
							style={{ display: 'flex', cursor: 'pointer', color: 'blue' }}
							onClick={() => setShowDocumentModal(true)}>
							<div style={{ marginRight: '10px' }}></div>
							<div style={{}}>{documentCount}</div>
						</div>
						<DocumentModal
							setShowModal={setShowDocumentModal}
							showModal={showDocumentModal}
							participationTitle={ppn.PlantingProject.Name}
							participationId={ppn.Id}
							role={role}
							setDocumentCount={setDocumentCount}
						/>
					</div>
				)}
			</CellWithValidator>
		</StateStyledCell>
	)
}