import {LabelContainer, Label} from '../../../components/Form/FormStyles';
import {Help} from '../../../components/Buttons/Help';
import CommitmentList from './CommitmentList';
import {EditedCommitment, EditedProject} from '../EditedProject';
import {RecordEditStatus, deleteStatus} from '../../../record-editing';

export function CommitmentsControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    function handleAddCommitment(commitment: EditedCommitment) {
        const updatedProject = {
            ...project,
            TTFCommitments: [
                ...project.TTFCommitments,
                {...commitment, EditStatus: RecordEditStatus.New}
            ]
        };
        setProjectAndValidate(updatedProject);
    }

    function handleRemoveCommitment(commitment: EditedCommitment) {
        const updatedCommitments = project.TTFCommitments.map((c) =>
            c.CommitmentId === commitment.CommitmentId
                ? {...c, EditStatus: deleteStatus(c.EditStatus)}
                : c
        );
        const updatedProject = {...project, TTFCommitments: updatedCommitments};
        setProjectAndValidate(updatedProject);
    }

    return (
        <div>
            <LabelContainer>
                <Label>Commitments</Label>
                <Help>
                    Going forward, there should only be one commitment per project. If you encounter
                    a need for more than one, submit a request.
                </Help>
            </LabelContainer>
            <CommitmentList
                commitments={project.TTFCommitments}
                addCommitment={handleAddCommitment}
                removeCommitment={handleRemoveCommitment}
                disabled={project.ControlledByAutomation}
            />
        </div>
    );
}
