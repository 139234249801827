import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    ValidationsList,
    FormFieldContainer,
    NumberControlShowingEdits
} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const D365EventIdField = styled(NumberControlShowingEdits)`
    max-width: 200px;
`;

export function D365EventNumberControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                <Label htmlFor="d365EventId">Event number</Label>
            </LabelContainer>
            <D365EventIdField
                id="d365EventId"
                value={project.D365EventId}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        D365EventId: project.D365EventId.update(getNumberFromInput(e))
                    })
                }
            />
            <ValidationsList validations={project.D365EventId.validations} />
        </FormFieldContainer>
    );
}
