import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ParticipationIdHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '75px', minWidth: '75px', maxWidth: '75px' }}>
			Id
		</StickyHeader>
	)
}

export function ParticipationIdFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<TextFilter
				value={filter?.Id ?? ''}
				onChange={(e) => updateFilter({ ...filter, Id: e.target.value })}
			/>
		</StickyHeader>
	)
}

export function ParticipationIdContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			{ppn.Id > 0 ? ppn.Id : ''}
		</StateStyledCell>
	)
}