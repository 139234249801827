import {useState} from 'react';
import styled from 'styled-components';
import {RecordEditStatus, Validation, ValidationLevel} from '../../../record-editing';
import {Button, TextControl, ValidationsList} from '../../../components/Form/FormStyles';
import {RemoveButtonX} from '../../../components/Buttons/RemoveButton';
import {EditedNavCustomer} from '../EditedPartner';
import {UserRoles} from '../../../providers/UserProvider';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0.25rem;
`;

const StyledCustomerList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const StyledCustomerListItem = styled.li`
    margin-bottom: 0.5rem;
`;

const CustomerContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const AddForm = styled.form`
    display: flex;
    gap .5rem;
    align-items: center;
`;

const NewCustomerIdTextBox = styled(TextControl)`
    width: 360px;
`;

function isCustomerId(str: string) {
    const regexExp = /^[0-9]{10}$/gi;
    return regexExp.test(str);
}

function validateNewCustomer(newCustomerId: string, customers: EditedNavCustomer[]): Validation[] {
    const validations = [];

    if (newCustomerId) {
        const newCustomerIsADuplicate = customers.find(
            (customer) =>
                customer.CustomerId === newCustomerId &&
                customer.EditStatus !== RecordEditStatus.NewDeleted &&
                customer.EditStatus !== RecordEditStatus.Deleted
        );
        if (newCustomerIsADuplicate)
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'This is a duplicate'
            });

        const newCustomerIsInValidFormat = isCustomerId(newCustomerId);
        if (!newCustomerIsInValidFormat)
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'The format is invalid for a Nav customer id'
            });
    }

    return validations;
}

function NavCustomerList({
    customers,
    addCustomer,
    removeCustomer
}: {
    customers: EditedNavCustomer[];
    addCustomer: (customer: EditedNavCustomer) => void;
    removeCustomer: (customer: EditedNavCustomer) => void;
}) {
    const [newCustomerId, setNewCustomerId] = useState('');
    const roles = UserRoles();

    function handleNewCustomerIdChanged(e) {
        setNewCustomerId(e.target.value);
    }

    function handleAddCustomerButtonClicked(e) {
        e.preventDefault();

        const customer = {
            EditStatus: RecordEditStatus.New,
            CustomerId: newCustomerId
        };

        setNewCustomerId('');
        addCustomer(customer);
    }

    function handleRemoveButtonClicked(account: EditedNavCustomer) {
        removeCustomer(account);
    }

    const newCustomerValidations = validateNewCustomer(newCustomerId, customers);

    const nonDeletedCustomers = customers.filter(
        (customer) =>
            customer.EditStatus !== RecordEditStatus.NewDeleted &&
            customer.EditStatus !== RecordEditStatus.Deleted
    );

    return (
        <Layout>
            <StyledCustomerList>
                {nonDeletedCustomers.map((customer) => (
                    <StyledCustomerListItem key={customer.CustomerId}>
                        <CustomerContainer>
                            {roles.includes('editor') && (
                                <RemoveButtonX
                                    onClick={() => handleRemoveButtonClicked(customer)}
                                />
                            )}
                            <a
                                href={`dynamicsnav://lnkas3.nadf.local/Nav/Arbor Day Foundation/runpage?page=21&$filter=%27No.%27%20IS%20%27${customer.CustomerId}%27`}
                                target="_blank"
                                rel="noreferrer">
                                {customer.CustomerId}
                            </a>
                        </CustomerContainer>
                    </StyledCustomerListItem>
                ))}
            </StyledCustomerList>
            <AddForm>
                <NewCustomerIdTextBox value={newCustomerId} onChange={handleNewCustomerIdChanged} />
                {roles.includes('editor') && (
                    <Button
                        type="submit"
                        disabled={!newCustomerId || newCustomerValidations.length > 0}
                        onClick={handleAddCustomerButtonClicked}>
                        Add customer
                    </Button>
                )}
            </AddForm>
            <ValidationsList validations={newCustomerValidations} />
        </Layout>
    );
}

export default NavCustomerList;
