import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function PlantingPartnerHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '425px', minWidth: '425px', maxWidth: '425px' }}>
			Planting partner
		</StickyHeader>
	)
}

export function PlantingPartnerFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.PlantingPartnerValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, PlantingPartnerValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.PlantingPartner ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, PlantingPartner: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function PlantingPartnerContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.PlantingPartnerNameValidated && <ValidatedIcon />}
				</span>
				<span>{ppn.PlantingProject.PlantingPartnerName}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}