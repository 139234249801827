import React, { ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/Modal/Modal';
import { RemoveButton } from '../../../../components/Buttons/CloseButton';
import { SaveChangesMessage } from '../../../../components/Buttons/SaveChanges';
import Spinner from '../../../../components/Spinner/Spinner';

const ModalHeader = styled.div`
    display: flex;
    width: 100%;
`;

const DocumentModal = ({
	showModal,
	setShowModal,
	participationTitle,
	participationId,
	role,
	setDocumentCount
}: {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	participationTitle: string;
	participationId: number;
	role: string;
	setDocumentCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const [documents, setDocuments] = useState([]);
	const [message, setMessage] = useState({ show: false, success: true, message: '' });
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		if (!showModal) return;
		getDocuments();
	}, [showModal]);

	const getDocuments = async () => {
		const result = await (
			await fetch(`/api/participation-documents/${participationId}`)
		).json();
		setDocuments(result);
		setDocumentCount(result.length);
	};

	const confirmRemoveDocument = (documentId) => {
		if (window.confirm('Are you sure you want to delete this document?')) {
			removeDocument(documentId);
		}
	};

	const removeDocument = async (documentId) => {
		const result = await fetch(`/api/participation-document/${documentId}`, { method: 'delete' });
		if (result.ok) {
			getDocuments();
			setMessage({ show: true, success: true, message: 'Your document was deleted' });
			setTimeout(() => {
				setMessage({ show: false, success: true, message: '' });
			}, 5000);
		} else {
			setMessage({ show: true, success: false, message: 'Something went wrong' });
			setTimeout(() => {
				setMessage({ show: false, success: true, message: '' });
			}, 5000);
		}
	};

	return (
		<>
			<Spinner showSpinner={showSpinner} />
			<Modal
				onClose={() => {
					setShowModal(false);
				}}
				showModal={showModal}
				width="700px"
				title={
					<ModalHeader>
						<div style={{ display: 'flex', flexDirection: 'column', flexGrow: '2' }}>
							<div style={{ display: 'flex', flexDirection: 'row', maxHeight: '30px' }}>
								<h3>Participation Documents</h3>
							</div>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<h4 style={{ color: 'rgb(50,50,50)', marginBottom: '0px' }}>
									{participationTitle}
								</h4>
							</div>
						</div>
					</ModalHeader>
				}
			>
				<div>
					<div style={{ marginBottom: '10px' }}>
						<SaveChangesMessage
							message={message.message}
							show={message.show}
							success={message.success}
						/>
					</div>
					{documents.map((doc, i) => {
						return (
							<div
								key={i}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginBottom: '5px'
								}}
							>
								<a
									download
									href={`/api/participation-documents/${doc.Id}/download`}
								>
									{doc.DisplayName}
								</a>

								{role.includes('editor') && (
									<div style={{ alignSelf: 'flex-end' }}>
										<RemoveButton
											onClick={() => {
												confirmRemoveDocument(doc.Id);
											}}
										/>
									</div>
								)}
							</div>
						);
					})}
					<FileUploadMultiple
						participationId={participationId}
						getDocuments={getDocuments}
						setMessage={setMessage}
						setShowSpinner={setShowSpinner}
						role={role}
					></FileUploadMultiple>
				</div>
			</Modal>
		</>
	);
};

const FileUploadMultiple = ({
	participationId,
	getDocuments,
	setMessage,
	setShowSpinner,
	role
}: {
	participationId: number;
	getDocuments: () => Promise<void>;
	setMessage: React.Dispatch<
		React.SetStateAction<{ show: boolean; success: boolean; message: string }>
	>;
	setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
	role: string;
}) => {
	async function uploadFiles(e: ChangeEvent<HTMLInputElement>) {
		setShowSpinner(true);
		const filesTarget = e.target.files;
		const files = filesTarget ? [...filesTarget] : [];

		const data = new FormData();
		files.forEach((file, i) => {
			if (file.size > 50000000) {
				//50mb limit
				setMessage({
					show: true,
					success: false,
					message: 'File size is limited to 50mb.'
				});
				setTimeout(() => {
					setMessage({ show: false, success: true, message: '' });
				}, 15000);
			}

			data.append(`file-${i}`, file, file.name);
		});

		const saveResults = await fetch(`/api/participation-documents/${participationId}`, {
			method: 'post',
			body: data
		});

		if (saveResults.ok) {
			getDocuments();
			setMessage({ show: true, success: true, message: 'Success!' });
			setTimeout(() => {
				setMessage({ show: false, success: true, message: '' });
			}, 5000);
		} else if (saveResults.status === 400) {
			setMessage({
				show: true,
				success: false,
				message:
					'Invalid file. Only pdf, jpg, jpeg, png, xls, xlsx, doc and docx are supported.'
			});
			setTimeout(() => {
				setMessage({ show: false, success: true, message: '' });
			}, 15000);
		} else {
			setMessage({ show: true, success: false, message: 'Something went wrong' });
			setTimeout(() => {
				setMessage({ show: false, success: true, message: '' });
			}, 5000);
		}
		setShowSpinner(false);
	}

	if (!role.includes('editor'))
		return <p style={{ "marginTop": "40px" }}>Contact the system administrator for permission to add documents.</p>;

	return (
		<div style={{ marginTop: '20px' }}>
			<form
				id="uploadForm"
				name="uploadForm"
				action="/uploader"
				encType="multipart/form-data"
				method="post"
			>
				<input
					style={{ color: 'rgba(0,0,0,0)' }}
					id="uploadInput"
					name=""
					type="file"
					multiple
					onChange={(e) => {
						uploadFiles(e);
					}}
				/>
			</form>
		</div>
	);
};

export default DocumentModal;
