import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, centeredContainerStyle, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function MapHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '60px', minWidth: '60px', maxWidth: '60px' }}>
			Map
		</StickyHeader>
	)
}

export function MapFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows, ...centeredContainerStyle }}>
			<ValidateFilter
				value={filter?.MapValidated ?? ValidateFilterValue.Unset}
				onChange={(e) => updateFilter({ ...filter, MapValidated: e.value })}
			/>
		</StickyHeader>
	)
}

export function MapContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>{ppn.PlantingProject.MapValidated && <ValidatedIcon />}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}