import {
    FormFieldContainer,
    LabelContainer,
    Label,
    TextAreaControlShowingEdits,
    ValidationsList,
    SubSectionHeader
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';

export function PlantingPartnerControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <>
            <SubSectionHeader>Planting partner</SubSectionHeader>
            <FormFieldContainer>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.PlantingPartnerNameValidated.current}
                            disabled={project.PlantingPartnerName.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    PlantingPartnerNameValidated:
                                        project.PlantingPartnerNameValidated.update(e.validated)
                                })
                            }
                        />
                    )}
                    <Label htmlFor="plantingPartner">Planting partner name</Label>
                </LabelContainer>
                <TextAreaControlShowingEdits
                    id="plantingPartner"
                    disabled={project.ControlledByAutomation}
                    rows={2}
                    maxLength={500}
                    value={project.PlantingPartnerName}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            PlantingPartnerName: project.PlantingPartnerName.update(e.target.value)
                        })
                    }></TextAreaControlShowingEdits>
                <ValidationsList validations={project.PlantingPartnerName.validations} />
            </FormFieldContainer>
        </>
    );
}
