import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ReasonForPlantingHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '230px', minWidth: '230px', maxWidth: '230px' }}>
			Reason for planting
		</StickyHeader>
	)
}

export function ReasonForPlantingFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.ReasonForPlantingValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, ReasonForPlantingValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.ReasonForPlanting ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, ReasonForPlanting: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function ReasonForPlantingContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.ReasonForPlantingValidated && <ValidatedIcon />}
				</span>
				<span>{ppn.PlantingProject.ReasonForPlanting}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}