import {Filter} from '../../ParticipationFunctions/Filtering';
import {CommunityBudgetPassThroughFilter} from '../../ParticipationColumns/CommunityEvents/CommunityBudgetPassThrough';
import {CommunityCorpVolunteersFilter} from '../../ParticipationColumns/CommunityEvents/CommunityCorpVolunteers';
import {CommunityDistributedDBHFilter} from '../../ParticipationColumns/CommunityEvents/CommunityDistributedDBH';
import {CommunityEventDatesFilter} from '../../ParticipationColumns/CommunityEvents/CommunityEventDates';
import {CommunityPlantedDBHFilter} from '../../ParticipationColumns/CommunityEvents/CommunityPlantedDBH';
import {CommunitySiteAddressFilter} from '../../ParticipationColumns/CommunityEvents/CommunitySiteAddress';
import {CommunitySiteCityFilter} from '../../ParticipationColumns/CommunityEvents/CommunitySiteCity';
import {CommunitySiteCountryFilter} from '../../ParticipationColumns/CommunityEvents/CommunitySiteCountry';
import {CommunitySiteNameFilter} from '../../ParticipationColumns/CommunityEvents/CommunitySiteName';
import {CommunitySitePostalCodeFilter} from '../../ParticipationColumns/CommunityEvents/CommunitySitePostalCode';
import {CommunitySiteStateFilter} from '../../ParticipationColumns/CommunityEvents/CommunitySiteState';
import {CommunityTotalParticipantsFilter} from '../../ParticipationColumns/CommunityEvents/CommunityTotalVolunteers';
import {CommunityTreesPlantedFilter} from '../../ParticipationColumns/CommunityEvents/CommunityTreesPlanted';
import {ProjectCommunityTreesDistributedFilter} from '../../ParticipationColumns/CommunityEvents/ProjectCommunityTreesDistributed';
import {ProjectCommunityTreesPlantedFilter} from '../../ParticipationColumns/CommunityEvents/ProjectCommunityTreesPlanted';
import {CommunityTreesDistributedFilter} from '../../ParticipationColumns/Shared/CommunityTreesDistributed';
import {DAMTagFilter} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationFilter} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedFilter} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedFilter} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedFilter} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearFilter} from '../../ParticipationColumns/Shared/FundedYear';
import {HasHeroFilter} from '../../ParticipationColumns/Shared/HasHero';
import {HasTreeSpeciesFilter} from '../../ParticipationColumns/Shared/HasTreeSpecies';
import {ImageCountFilter} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedFilter} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedFilter} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ImpactStoryInProgressFilter} from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import {MapFilter} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdFilter} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerFilter} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdFilter} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectLocationFilter} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ProjectNameFilter} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusFilter} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeFilter} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ReasonForPlantingFilter} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {ShowOnDateFilter} from '../../ParticipationColumns/Shared/ShowOnDate';
import {FundingSourceFilter} from '../../ParticipationColumns/Shared/FundingSource';

export function CommunityEventFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <tr>
            <DeleteParticipationFilter />
            <ExcludedFilter filter={filter} updateFilter={updateFilter} />
            <EverythingValidatedFilter filter={filter} updateFilter={updateFilter} />
            <ParticipationIdFilter filter={filter} updateFilter={updateFilter} />
            <FundedYearFilter filter={filter} updateFilter={updateFilter} />
            <FundingSourceFilter filter={filter} updateFilter={updateFilter} />
            <CommunityTreesPlantedFilter filter={filter} updateFilter={updateFilter} />
            <CommunityTreesDistributedFilter filter={filter} updateFilter={updateFilter} />
            <DollarDonatedFilter filter={filter} updateFilter={updateFilter} />
            <CommunityBudgetPassThroughFilter filter={filter} updateFilter={updateFilter} />
            <ShowOnDateFilter filter={filter} updateFilter={updateFilter} />
            <ProjectIdFilter filter={filter} updateFilter={updateFilter} />
            <ProjectTypeFilter filter={filter} updateFilter={updateFilter} />
            <ProjectNameFilter filter={filter} updateFilter={updateFilter} />
            <ProjectStatusFilter filter={filter} updateFilter={updateFilter} />
            <ProjectLocationFilter filter={filter} updateFilter={updateFilter} />
            <CommunitySiteNameFilter filter={filter} updateFilter={updateFilter} />
            <CommunitySiteAddressFilter filter={filter} updateFilter={updateFilter} />
            <CommunitySiteCityFilter filter={filter} updateFilter={updateFilter} />
            <CommunitySiteStateFilter filter={filter} updateFilter={updateFilter} />
            <CommunitySitePostalCodeFilter filter={filter} updateFilter={updateFilter} />
            <CommunitySiteCountryFilter filter={filter} updateFilter={updateFilter} />
            <MapFilter filter={filter} updateFilter={updateFilter} />
            <CommunityEventDatesFilter filter={filter} updateFilter={updateFilter} />
            <ReasonForPlantingFilter filter={filter} updateFilter={updateFilter} />
            <ImpactStoryInProgressFilter filter={filter} updateFilter={updateFilter} />
            <ImpactStoryCompletedFilter filter={filter} updateFilter={updateFilter} />
            <PlantingPartnerFilter filter={filter} updateFilter={updateFilter} />
            <CommunityCorpVolunteersFilter filter={filter} updateFilter={updateFilter} />
            <CommunityTotalParticipantsFilter filter={filter} updateFilter={updateFilter} />
            <ProjectCommunityTreesPlantedFilter filter={filter} updateFilter={updateFilter} />
            <ProjectCommunityTreesDistributedFilter filter={filter} updateFilter={updateFilter} />
            <CommunityPlantedDBHFilter filter={filter} updateFilter={updateFilter} />
            <CommunityDistributedDBHFilter filter={filter} updateFilter={updateFilter} />
            <HasTreeSpeciesFilter filter={filter} updateFilter={updateFilter} />
            <DAMTagFilter filter={filter} updateFilter={updateFilter} />
            <ImagesValidatedFilter filter={filter} updateFilter={updateFilter} />
            <ImageCountFilter filter={filter} updateFilter={updateFilter} />
            <HasHeroFilter filter={filter} updateFilter={updateFilter} />
        </tr>
    );
}
