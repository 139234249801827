import {CellWithValidator, StickyNumericHeader} from '../../../../components/Table/TableStyles';
import {
    offsetOneRow,
    offsetTwoRows,
    StateStyledNumberCell
} from '../../Styles/ParticipationTableStyles';
import {
    ValidateControl,
    ValidatedIcon,
    ValidateFilter,
    ValidateFilterValue
} from '../../../../components/Controls/ValidateControl';
import {
    ComplexInputWithAsyncUpdate,
    NumericFilter,
    ValidationsList
} from '../../../../components/Form/FormStyles';
import {Filter} from '../../ParticipationFunctions/Filtering';
import {EditedParticipation} from '../../../Partner/EditedPartner';
import {getNumberFromInput} from '../../../../record-editing';
import {ParticipationUpdater} from '../../ParticipationFunctions/ParticipationModifications';
import {
    AlignToTextBoxContent,
    ValidationTippy,
    ValidationTippyContent,
    TreeCountControl
} from '../../Styles/ParticipationRowStyles';

export function TTFTreesHeader() {
    return (
        <StickyNumericHeader
            style={{...offsetOneRow, width: '120px', minWidth: '120px', maxWidth: '120px'}}>
            TTF trees
        </StickyNumericHeader>
    );
}

export function TTFTreesFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <StickyNumericHeader style={{...offsetTwoRows}}>
            <CellWithValidator>
                <ValidateFilter
                    value={filter?.TTFTreesValidated ?? ValidateFilterValue.Unset}
                    onChange={(e) => updateFilter({...filter, TTFTreesValidated: e.value})}
                />
                <NumericFilter
                    value={filter?.TTFTrees ?? ''}
                    onChange={(e) => updateFilter({...filter, TTFTrees: e.target.value})}
                />
            </CellWithValidator>
        </StickyNumericHeader>
    );
}

export function TTFTreesContent({
    ppn,
    updater
}: {
    ppn: EditedParticipation;
    updater: ParticipationUpdater;
}) {
    return (
        <StateStyledNumberCell
            editStatus={ppn.EditStatus}
            modified={ppn.PlantingProjectId.modified}>
            <CellWithValidator>
                {ppn.ControlledByAutomation ? (
                    <>
                        {ppn.TTFTreesValidated.current && <ValidatedIcon />}
                        <AlignToTextBoxContent>
                            {ppn.TTFTrees.current?.toLocaleString()}
                        </AlignToTextBoxContent>
                    </>
                ) : (
                    <>
                        <ValidateControl
                            validated={ppn.TTFTreesValidated.current}
                            disabled={ppn.TTFTrees.notValidToShowPartner}
                            onChange={(e) =>
                                updater.update({
                                    ...ppn,
                                    TTFTreesValidated: ppn.TTFTreesValidated.update(e.validated)
                                })
                            }
                        />
                        <ValidationTippy
                            content={
                                <ValidationTippyContent>
                                    <ValidationsList validations={ppn.TTFTrees.validations} />
                                </ValidationTippyContent>
                            }
                            disabled={ppn.TTFTrees.validations.length === 0}>
                            <div>
                                <ComplexInputWithAsyncUpdate
                                    Control={TreeCountControl}
                                    showModification
                                    value={ppn.TTFTrees}
                                    onChange={(updatedValue) =>
                                        updater.update({
                                            ...ppn,
                                            TTFTrees: updatedValue
                                        })
                                    }
                                    valueGetter={(e) => ppn.TTFTrees.update(getNumberFromInput(e))}
                                />
                            </div>
                        </ValidationTippy>
                    </>
                )}
            </CellWithValidator>
        </StateStyledNumberCell>
    );
}
