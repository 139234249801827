import {EditedParticipation} from '../../../Partner/EditedPartner';
import {AcresRestoredContent} from '../../ParticipationColumns/Shared/AcresRestored';
import {AdditionalCertificationsContent} from '../../ParticipationColumns/Carbon/AdditionalCertifications';
import {CCProjectStandardContent} from '../../ParticipationColumns/Carbon/CCProjectStandard';
import {CarbonCreditDocumentsContent} from '../../ParticipationColumns/Carbon/CarbonCreditDocuments';
import {CarbonCreditTypeContent} from '../../ParticipationColumns/Carbon/CarbonCreditType';
import {VerifiedCarbonCreditsContent} from '../../ParticipationColumns/Carbon/VerifiedCarbonCredits';
import {CarbonPurchaseAmountContent} from '../../ParticipationColumns/Carbon/CarbonPurchaseAmount';
import {CorsiaEligibleContent} from '../../ParticipationColumns/Carbon/CorsiaEligible';
import {ICROAEndorsedStandardContent} from '../../ParticipationColumns/Carbon/ICROAEndorsedStandard';
import {MoreDetailsLinkContent} from '../../ParticipationColumns/Carbon/MoreDetailsLink';
import {ParticipantRegistryLinkContent} from '../../ParticipationColumns/Carbon/ParticipantRegistryLink';
import {ProjectTotalCarbonCreditsContent} from '../../ParticipationColumns/Carbon/ProjectTotalCarbonCredits';
import {RegistryLinkContent} from '../../ParticipationColumns/Carbon/RegistryLink';
import {SerialNumberContent} from '../../ParticipationColumns/Carbon/SerialNumber';
import {SustainableDevelopmentGoalsContent} from '../../ParticipationColumns/Carbon/SustainableDevelopmentGoals';
import {VintageYearContent} from '../../ParticipationColumns/Carbon/VintageYear';
import {DAMTagContent} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationContent} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {EverythingValidatedContent} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedContent} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearContent} from '../../ParticipationColumns/Shared/FundedYear';
import {HasHeroContent} from '../../ParticipationColumns/Shared/HasHero';
import {ImageCountContent} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedContent} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedContent} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {MapContent} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdContent} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerContent} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdContent} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectLocationContent} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ProjectNameContent} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusContent} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeContent} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ProjectYearsContent} from '../../ParticipationColumns/Shared/ProjectYears';
import {
    ParticipationRemover,
    ParticipationUnremover,
    ParticipationUpdater,
    ProjectSelectionSetter
} from '../../ParticipationFunctions/ParticipationModifications';
import {FundingSourceContent} from '../../ParticipationColumns/Shared/FundingSource';

export function CarbonParticipationTableContent({
    role,
    participation,
    remover,
    unremover,
    updater,
    projectSelectionSetter,
    imageData
}: {
    role: string[];
    participation: EditedParticipation;
    remover: ParticipationRemover;
    unremover: ParticipationUnremover;
    updater: ParticipationUpdater;
    projectSelectionSetter: ProjectSelectionSetter;
    imageData: {hasHero: boolean; imageCount: number};
}) {
    return (
        <tr>
            <DeleteParticipationContent
                ppn={participation}
                remover={remover}
                unremover={unremover}
                role={role}
            />
            <ExcludedContent ppn={participation} updater={updater} />
            <EverythingValidatedContent ppn={participation} />
            <ParticipationIdContent ppn={participation} />
            <FundedYearContent ppn={participation} updater={updater} />
            <FundingSourceContent ppn={participation} updater={updater} />
            <CarbonPurchaseAmountContent ppn={participation} updater={updater} />
            <VerifiedCarbonCreditsContent ppn={participation} updater={updater} />
            <VintageYearContent ppn={participation} updater={updater} />
            <SerialNumberContent ppn={participation} updater={updater} />
            <ParticipantRegistryLinkContent ppn={participation} updater={updater} />
            <CarbonCreditDocumentsContent ppn={participation} role={role} />
            <ProjectIdContent ppn={participation} projectSelectionSetter={projectSelectionSetter} />
            <ProjectTypeContent ppn={participation} />
            <ProjectNameContent ppn={participation} />
            <ProjectStatusContent ppn={participation} />
            <ProjectLocationContent ppn={participation} />
            <MapContent ppn={participation} />
            <ProjectYearsContent ppn={participation} />
            <ImpactStoryCompletedContent ppn={participation} />
            <SustainableDevelopmentGoalsContent ppn={participation} />
            <PlantingPartnerContent ppn={participation} />
            <AcresRestoredContent ppn={participation} />
            <ProjectTotalCarbonCreditsContent ppn={participation} />
            <CCProjectStandardContent ppn={participation} />
            <CarbonCreditTypeContent ppn={participation} />
            <ICROAEndorsedStandardContent ppn={participation} />
            <CorsiaEligibleContent ppn={participation} />
            <AdditionalCertificationsContent ppn={participation} />
            <MoreDetailsLinkContent ppn={participation} />
            <RegistryLinkContent ppn={participation} />
            <DAMTagContent ppn={participation} />
            <ImagesValidatedContent ppn={participation} />
            <ImageCountContent ppn={participation} imageCount={imageData.imageCount} />
            <HasHeroContent ppn={participation} hasHero={imageData.hasHero} />
        </tr>
    );
}
