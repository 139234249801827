import Tippy from "@tippyjs/react";
import { AddButton } from "../../../../components/Buttons/AddButton";
import { StickyHeader } from "../../../../components/Table/TableStyles";
import { RecordEditStatus } from "../../../../record-editing";
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { ParticipationRemover, ParticipationUnremover } from "../../ParticipationFunctions/ParticipationModifications";
import { offsetOneRow } from "../../Styles/ParticipationTableStyles";
import { EmptyFilter } from "./EmptyFilter";
import { RemoveButton } from "../../../../components/Buttons/RemoveButton";

export function DeleteParticipationContent({ role, ppn, remover, unremover }
	: { role, ppn: EditedParticipation, remover: ParticipationRemover, unremover: ParticipationUnremover }) {
	return <td>
		{role.includes('editor') &&
			!ppn.ControlledByAutomation &&
			(ppn.EditStatus === RecordEditStatus.New ||
				ppn.EditStatus === RecordEditStatus.Existing ? (
				<Tippy content="Delete this project">
					<span>
						<RemoveButton onClick={() => remover.remove(ppn.Id)}></RemoveButton>
					</span>
				</Tippy>
			) : (
				<Tippy content="Undelete this project">
					<span>
						<AddButton onClick={() => unremover.unremove(ppn.Id)}></AddButton>
					</span>
				</Tippy>
			))}
	</td>;
}

export function DeleteParticipationHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '50px', minWidth: '50px', maxWidth: '50px' }}>
			<Tippy content="Use to delete participations">
				<span>Del</span>
			</Tippy>
		</StickyHeader>
	)
}

export function DeleteParticipationFilter() {
	return (
		<EmptyFilter />
	)
}