import {Filter} from '../../ParticipationFunctions/Filtering';
import {CarbonCreditDocumentsFilter} from '../../ParticipationColumns/Carbon/CarbonCreditDocuments';
import {VerifiedCarbonCreditsFilter} from '../../ParticipationColumns/Carbon/VerifiedCarbonCredits';
import {CarbonPurchaseAmountFilter} from '../../ParticipationColumns/Carbon/CarbonPurchaseAmount';
import {RegistryLinkFilter} from '../../ParticipationColumns/Carbon/RegistryLink';
import {SerialNumberFilter} from '../../ParticipationColumns/Carbon/SerialNumber';
import {VintageYearFilter} from '../../ParticipationColumns/Carbon/VintageYear';
import {SustainableDevelopmentGoalsFilter} from '../../ParticipationColumns/Carbon/SustainableDevelopmentGoals';
import {AcresRestoredFilter} from '../../ParticipationColumns/Shared/AcresRestored';
import {ProjectTotalCarbonCreditsFilter} from '../../ParticipationColumns/Carbon/ProjectTotalCarbonCredits';
import {CCProjectStandardFilter} from '../../ParticipationColumns/Carbon/CCProjectStandard';
import {CarbonCreditTypeFilter} from '../../ParticipationColumns/Carbon/CarbonCreditType';
import {ICROAEndorsedStandardFilter} from '../../ParticipationColumns/Carbon/ICROAEndorsedStandard';
import {CorsiaEligibleFilter} from '../../ParticipationColumns/Carbon/CorsiaEligible';
import {AdditionalCertificationsFilter} from '../../ParticipationColumns/Carbon/AdditionalCertifications';
import {MoreDetailsLinkFilter} from '../../ParticipationColumns/Carbon/MoreDetailsLink';
import {DeleteParticipationFilter} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {EverythingValidatedFilter} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedFilter} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearFilter} from '../../ParticipationColumns/Shared/FundedYear';
import {ProjectLocationFilter} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ParticipationIdFilter} from '../../ParticipationColumns/Shared/ParticipationId';
import {ProjectIdFilter} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectNameFilter} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusFilter} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeFilter} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {MapFilter} from '../../ParticipationColumns/Shared/Map';
import {ProjectYearsFilter} from '../../ParticipationColumns/Shared/ProjectYears';
import {ImpactStoryCompletedFilter} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {DAMTagFilter} from '../../ParticipationColumns/Shared/DAMTag';
import {ImagesValidatedFilter} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImageCountFilter} from '../../ParticipationColumns/Shared/ImageCount';
import {PlantingPartnerFilter} from '../../ParticipationColumns/Shared/PlantingPartner';
import {HasHeroFilter} from '../../ParticipationColumns/Shared/HasHero';
import {FundingSourceFilter} from '../../ParticipationColumns/Shared/FundingSource';

export function CarbonParticipationFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <tr>
            <DeleteParticipationFilter />
            <ExcludedFilter filter={filter} updateFilter={updateFilter} />
            <EverythingValidatedFilter filter={filter} updateFilter={updateFilter} />
            <ParticipationIdFilter filter={filter} updateFilter={updateFilter} />
            <FundedYearFilter filter={filter} updateFilter={updateFilter} />
            <FundingSourceFilter filter={filter} updateFilter={updateFilter} />
            <CarbonPurchaseAmountFilter filter={filter} updateFilter={updateFilter} />
            <VerifiedCarbonCreditsFilter filter={filter} updateFilter={updateFilter} />
            <VintageYearFilter filter={filter} updateFilter={updateFilter} />
            <SerialNumberFilter filter={filter} updateFilter={updateFilter} />
            <RegistryLinkFilter filter={filter} updateFilter={updateFilter} />
            <CarbonCreditDocumentsFilter filter={filter} updateFilter={updateFilter} />
            <ProjectIdFilter filter={filter} updateFilter={updateFilter} />
            <ProjectTypeFilter filter={filter} updateFilter={updateFilter} />
            <ProjectNameFilter filter={filter} updateFilter={updateFilter} />
            <ProjectStatusFilter filter={filter} updateFilter={updateFilter} />
            <ProjectLocationFilter filter={filter} updateFilter={updateFilter} />
            <MapFilter filter={filter} updateFilter={updateFilter} />
            <ProjectYearsFilter filter={filter} updateFilter={updateFilter} />
            <ImpactStoryCompletedFilter filter={filter} updateFilter={updateFilter} />
            <SustainableDevelopmentGoalsFilter filter={filter} updateFilter={updateFilter} />
            <PlantingPartnerFilter filter={filter} updateFilter={updateFilter} />
            <AcresRestoredFilter filter={filter} updateFilter={updateFilter} />
            <ProjectTotalCarbonCreditsFilter filter={filter} updateFilter={updateFilter} />
            <CCProjectStandardFilter filter={filter} updateFilter={updateFilter} />
            <CarbonCreditTypeFilter filter={filter} updateFilter={updateFilter} />
            <ICROAEndorsedStandardFilter filter={filter} updateFilter={updateFilter} />
            <CorsiaEligibleFilter filter={filter} updateFilter={updateFilter} />
            <AdditionalCertificationsFilter filter={filter} updateFilter={updateFilter} />
            <MoreDetailsLinkFilter filter={filter} updateFilter={updateFilter} />
            <RegistryLinkFilter filter={filter} updateFilter={updateFilter} />
            <DAMTagFilter filter={filter} updateFilter={updateFilter} />
            <ImagesValidatedFilter filter={filter} updateFilter={updateFilter} />
            <ImageCountFilter filter={filter} updateFilter={updateFilter} />
            <HasHeroFilter filter={filter} updateFilter={updateFilter} />
        </tr>
    );
}
