import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function DAMTagHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '120px',
				minWidth: '120px',
				maxWidth: '120px'
			}}>
			DAM tag
		</StickyHeader>
	)
}

export function DAMTagFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<TextFilter
				value={filter?.DAMTag ?? ''}
				onChange={(e) => updateFilter({ ...filter, DAMTag: e.target.value })}
			/>
		</StickyHeader>
	)
}

export function DAMTagContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			{ppn.PlantingProject.DAMTag}
		</StateStyledCell>
	)
}