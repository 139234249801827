import {ReadOnlyProperty, PropertyName} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';

export function ProjectIdControl({project}: {project: EditedProject}) {
	if (!project.Id) return <></>
    return (
        <ReadOnlyProperty>
            <PropertyName>Id: </PropertyName>
            {project.Id}
        </ReadOnlyProperty>
    );
}
