import { EditedParticipation } from "../../../Partner/EditedPartner";
import { StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { EmptyFilter } from "../Shared/EmptyFilter";

export function THHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				textAlign: 'left',
				width: '60px',
				minWidth: '60px',
				maxWidth: '60px'
			}}>
			TH
		</StickyHeader>
	)
}

export function THFilter() {
	return (
		<EmptyFilter />
	)
}

export function THContent({ ppn, treeHarmonyBaseUrl }: { ppn: EditedParticipation, treeHarmonyBaseUrl: string }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			{ppn.THLiabilityId !== null && (
				<a
					href={`${treeHarmonyBaseUrl}/Liabilities/Detail/${ppn.THLiabilityId}`}
					target="_blank"
					rel="noopener noreferrer">
					View
				</a>
			)}
		</StateStyledCell>
	)
}