import styled from 'styled-components';

export function RemoveIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
        </svg>
    );
}

const StyledRemoveButton = styled.button`
    height: 18px;
    line-height: 1;
    vertical-align: bottom;
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
    border: none;
    background-color: transparent;
    color: rgb(255, 100, 100);

    &:hover {
        cursor: pointer;
    }
`;

export function RemoveButton(props) {
    return (
        <StyledRemoveButton {...props}>
            <RemoveIcon />
        </StyledRemoveButton>
    );
}

export function RemoveIconX() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
            />
            <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
            />
        </svg>
    );
}

const StyledRemoveButtonX = styled.button`
    height: 18px;
    line-height: 1;
    vertical-align: bottom;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: rgb(255, 100, 100);

    &:hover {
        cursor: pointer;
    }
`;

export function RemoveButtonX(props) {
    return (
        <StyledRemoveButtonX {...props}>
            <RemoveIconX />
        </StyledRemoveButtonX>
    );
}
