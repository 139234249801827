import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export function FilterIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
        </svg>
    );
}

const StyledFilterButton = styled.button`
    height: 18px;
    line-height: 1;
    vertical-align: bottom;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
`;

export function FilterButton(props) {
    return (
        <StyledFilterButton {...props}>
            <FilterIcon />
        </StyledFilterButton>
    );
}

export function RemoveFilterIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
            <path
                transform="translate(-.6, -1.2)"
                color="red"
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
            />
        </svg>
    );
}

export function RemoveFilterButton(props) {
    return (
        <Tippy content="Remove all filters">
            <StyledFilterButton {...props}>
                <RemoveFilterIcon />
            </StyledFilterButton>
        </Tippy>
    );
}
