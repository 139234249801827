import styled from 'styled-components';
import {ButtonBaseCss} from './ButtonBase';

export const SaveChangesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
`;

export const SaveChangesButton = styled.button`
    ${ButtonBaseCss}
    color: #fff;
    background-color: ${(props) => (props.disabled ? '#9EC0FC' : '#0d6efd')};
    border-color: ${(props) => (props.disabled ? '#9EC0FC' : '#0d6efd')};
    cursor: pointer;
`;

const SaveChangesMessageBase = styled.div`
    padding: 1rem;
    border-radius: 0.5rem;
    z-index: 1;
`;

const SaveChangesSucceededMessage = styled(SaveChangesMessageBase)`
    background-color: rgb(200, 255, 200);
`;

const SaveChangesFailedMessage = styled(SaveChangesMessageBase)`
    background-color: rgb(255, 200, 200);
`;

export function SaveChangesMessage({
    show,
    success,
    message
}: {
    show: boolean;
    success: boolean;
    message: string;
}) {
    if (show)
        if (success) return <SaveChangesSucceededMessage>{message}</SaveChangesSucceededMessage>;
        else return <SaveChangesFailedMessage>{message}</SaveChangesFailedMessage>;
    else return null;
}

export const AddButton = styled.button`
    ${ButtonBaseCss}
    color: #fff;
    background-color: ${(props) => (props.disabled ? '#9EC0FC' : '#0d6efd')};
    border-color: ${(props) => (props.disabled ? '#9EC0FC' : '#0d6efd')};
`;

export const DeleteButton = styled.button`
    ${ButtonBaseCss}
    color: #fff;
    background-color: ${(props) => (props.disabled ? '#ECA9AE' : '#dc3545')};
    border-color: ${(props) => (props.disabled ? '#ECA9AE' : '#dc3545')};
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
`;

export const ActionList = styled.div`
    display: flex;
    gap: 1rem;
    z-index: 1;
`;

export const CancelButton = styled.button`
    ${ButtonBaseCss}
    // color: #fff;
    // background-color: #dc3545;
    // border-color: #dc3545;
    opacity: ${(props) => (props.disabled ? '.45' : '1')}
`;
