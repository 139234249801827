import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    NumberControlShowingEdits,
    ValidationsList,
    RequiredToShowToPartnerSignifier
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import {NumericFormFieldContainer} from './NumericFormFieldContainer';

export function CommunityTreesPlantedDBHControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <NumericFormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.CommunityDBHTreesPlantedValidated.current}
                        disabled={project.CommunityDBHTreesPlanted.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CommunityDBHTreesPlantedValidated:
                                    project.CommunityDBHTreesPlantedValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="dbhEventTreesPlanted">Tree diameter (DBH) - planted</Label>
                <RequiredToShowToPartnerSignifier />
            </LabelContainer>
            <NumberControlShowingEdits
                id="dbhEventTreesPlanted"
                disabled={project.ControlledByAutomation}
                value={project.CommunityDBHTreesPlanted}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        CommunityDBHTreesPlanted: project.CommunityDBHTreesPlanted.update(
                            getNumberFromInput(e)
                        )
                    })
                }></NumberControlShowingEdits>
            <ValidationsList validations={project.CommunityDBHTreesPlanted.validations} />
        </NumericFormFieldContainer>
    );
}
