import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation, projectTypeLabel } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, fixedProjectTypeColumn, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ProjectTypeHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				zIndex: 1,
				...fixedProjectTypeColumn,
				width: '190px',
				minWidth: '190px',
				maxWidth: '190px'
			}}>
			Project type
		</StickyHeader>
	)
}

export function ProjectTypeFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows, zIndex: 1, ...fixedProjectTypeColumn }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.ProjectTypeValidated ?? ValidateFilterValue.Unset}
					onChange={(e) => updateFilter({ ...filter, ProjectTypeValidated: e.value })}
				/>
				<TextFilter
					value={filter?.ProjectType ?? ''}
					onChange={(e) => updateFilter({ ...filter, ProjectType: e.target.value })}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function ProjectTypeContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			style={{ position: 'sticky', ...fixedProjectTypeColumn }}
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>{ppn.PlantingProject.ProjectTypeValidated && <ValidatedIcon />}</span>
				<span>{projectTypeLabel(ppn.PlantingProject.ProjectType)}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}