import {useState, useEffect} from 'react';
import Select from 'react-select';
import {CarbonCreditCreditType} from '../../../entities';
import {EditedProject} from '../EditedProject';

export function CarbonCreditTypeSelect({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [options, setOptions] = useState<CreditTypeOption[]>(null);
    const [selectedOption, setSelectedOption] = useState<CreditTypeOption>(null);

    useEffect(() => {
        const standardOptions = getCarbonCreditCreditTypeOptions();
        setOptions(standardOptions);
    }, []);

    useEffect(() => {
        const selected = project.CarbonCreditCreditType.current;

        if (!options || !selected) return;

        setSelectedOption(options.find((x) => x.value === selected));
    }, [options]);

    const onChange = (e) => {
        setSelectedOption(e);
        setProjectAndValidate({
            ...project,
            CarbonCreditCreditType: project.CarbonCreditCreditType.update(e.value)
        });
    };

    return <Select value={selectedOption} onChange={(e) => onChange(e)} options={options} />;
}

function getCarbonCreditCreditTypeOptions(): CreditTypeOption[] {
    return Object.keys(CarbonCreditCreditType)
        .filter((x) => isNaN(Number(x)))
        .map((name) => {
            const id = CarbonCreditCreditType[name as keyof CarbonCreditCreditType];
            return {
                name,
                value: id,
                label: formatCarbonCreditTypes(id)
            };
        });
}

export function formatCarbonCreditTypes(creditType: CarbonCreditCreditType) {
    switch (creditType) {
        case CarbonCreditCreditType.REDDReduction:
            return 'REDD+, Reduction';
        case CarbonCreditCreditType.IFMReduction:
            return 'IFM, Reduction';
        case CarbonCreditCreditType.IFMRemoval:
            return 'IFM, Removal';
        case CarbonCreditCreditType.ARRemoval:
            return 'A/R Removal';
        case CarbonCreditCreditType.MangroveReduction:
            return 'Mangrove, Reduction';
        case CarbonCreditCreditType.MangroveRemoval:
            return 'Mangrove, Removal';
    }
}

type CreditTypeOption = {
    name: string;
    value: number;
    label: string;
};
export default CarbonCreditTypeSelect;
