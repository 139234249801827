import styled from 'styled-components';
import {DeleteButton, CancelButton} from '../../../components/Buttons/SaveChanges';

const DeleteConfirmationOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 2;
    padding: 3rem;
`;

const DeleteConfirmationContainer = styled.div`
    border: 1px solid gray;
    border-radius: 0.25rem;
    padding: 1rem;
    background-color: white;
    z-index: 3;
`;

const ConfirmationHeader = styled.h3`
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
`;

const ConfirmationActionList = styled.div`
    display: flex;
    gap: 1rem;
`;

const DeleteWarningHasParticipations = styled.p`
    background-color: #fff3cd;
    border-color: #ffecb5;
    border-radius: 0.25rem;
    color: #664d03;
    padding: 1rem;
`;

export function DeleteConfirmation({
    hasParticipations,
    deleteConfirmed,
    canceled
}: {
    hasParticipations: boolean;
    deleteConfirmed: () => void;
    canceled: () => void;
}) {
    return (
        <>
            <DeleteConfirmationOverlay></DeleteConfirmationOverlay>
            <DeleteConfirmationContainer>
                <ConfirmationHeader>
                    Are you sure you want to delete this project?
                </ConfirmationHeader>
                {hasParticipations && (
                    <DeleteWarningHasParticipations>
                        This project is linked to one or more partners.
                    </DeleteWarningHasParticipations>
                )}
                <ConfirmationActionList>
                    <DeleteButton onClick={() => deleteConfirmed()}>Delete</DeleteButton>
                    <CancelButton onClick={() => canceled()}>Cancel</CancelButton>
                </ConfirmationActionList>
            </DeleteConfirmationContainer>
        </>
    );
}
