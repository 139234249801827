
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { TextFilter } from "../../../../components/Form/FormStyles";
import {
	formatAdditionalCertificationsArray
} from "../../../PlantingProjectEditor/Carbon/CarbonCreditAdditionalCertifications";

export function AdditionalCertificationsHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '250px',
				minWidth: '250px',
				maxWidth: '250px'
			}}>
			CC Additional Certifications
		</StickyHeader>
	)
}

export function AdditionalCertificationsFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.AdditionalCarbonCreditCertificationsValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							AdditionalCarbonCreditCertificationsValidated: e.value
						})
					}
				/>
				<TextFilter
					value={filter?.AdditionalCarbonCreditCertifications ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							AdditionalCarbonCreditCertifications: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function AdditionalCertificationsContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject
						.AdditionalCarbonCreditCertificationsValidated && (
							<ValidatedIcon />
						)}
				</span>
				<span>
					{formatAdditionalCertificationsArray(
						ppn.PlantingProject.CarbonCreditsAdditionalCertifications
					)}
				</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}