import React from 'react';
import styled from 'styled-components';
import { LythoImageProject } from '../../entities';
import { useConfig } from '../../providers/ConfigProvider';
import { UserRoles } from '../../providers/UserProvider';
import { EditedParticipation } from '../Partner/EditedPartner';
import { Filter } from './ParticipationFunctions/Filtering';
import ParticipationColumnGroupRow from './ParticipationColumnGroupRow';
import ParticipationColumnHeaderRow from './ParticipationColumnHeaderRow';
import ParticipationFilterRow from './ParticipationFilterRow';
import {
	ParticipationRemover,
	ParticipationUnremover,
	ParticipationUpdater,
	ProjectSelectionSetter
} from './ParticipationFunctions/ParticipationModifications';
import ParticipationRow from './ParticipationRow';
import { ParticipationView } from './ParticipationViewSelector';

const ParticipationTable = styled.table`
    min-width: 100%;
`;

function ParticipationsTable({
	filter,
	updateFilter,
	participations,
	lythoImageData,
	participationRemover,
	participationUnremover,
	participationUpdater,
	projectSelectionSetter,
	view
}: {
	filter: Filter;
	updateFilter: (filter: Filter) => void;
	participations: EditedParticipation[];
	lythoImageData: LythoImageProject[];
	participationRemover: ParticipationRemover;
	participationUnremover: ParticipationUnremover;
	participationUpdater: ParticipationUpdater;
	projectSelectionSetter: ProjectSelectionSetter;
	view: ParticipationView;
	setMessage: React.Dispatch<
		React.SetStateAction<{ show: boolean; success: boolean; message: string }>
	>;
}) {
	const config = useConfig();
	const roles = UserRoles();

	return (
		<ParticipationTable className="table">
			<thead>
				<ParticipationColumnGroupRow view={view} />
				<ParticipationColumnHeaderRow view={view} />
				<ParticipationFilterRow filter={filter} updateFilter={updateFilter} view={view} />
			</thead>
			<tbody>
				{participations.map((ppn) => (
					<ParticipationRow
						key={ppn.Id}
						ppn={ppn}
						lythoImageData={lythoImageData}
						remover={participationRemover}
						unremover={participationUnremover}
						updater={participationUpdater}
						projectSelectionSetter={projectSelectionSetter}
						view={view}
						role={roles}
						treeHarmonyBaseUrl={config.treeHarmonyBaseUrl}
					/>
				))}
			</tbody>
		</ParticipationTable>
	);
}

export default ParticipationsTable;