import {Section, SectionHeader, SubSectionHeader} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import {BeehiveCampaignsControl} from './BeehiveCampaignsControl';
import {CommunityEventDatesControl} from '../MultipleProjectTypes/CommunityEventDatesControl';
import {FundingPartnersControl} from '../MultipleProjectTypes/FundingPartnersControl';
import {ImpactStoryCompletedControl} from '../MultipleProjectTypes/ImpactStoryCompletedControl';
import {NameControl} from '../MultipleProjectTypes/NameControl';
import {ProjectIdControl} from '../MultipleProjectTypes/ProjectIdControl';
import {ProjectTypeControl} from '../MultipleProjectTypes/ProjectTypeControl';
import {ReasonForPlantingControl} from '../MultipleProjectTypes/ReasonForPlantingControl';
import {TreeSpeciesReadOnly} from '../MultipleProjectTypes/TreeSpeciesReadOnly';

export function CommunityCanopyProject({
    project,
    setProjectAndValidate,
    newProject
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    completedProjectSetProjectAndValidate: (project: EditedProject) => void;
    newProject?: boolean;
    navigationPromptActive: boolean;
    setNavigationPromptActive: (navigationPromptActive: boolean) => void;
    registerSave: (f: (projectId: number) => void) => void;
    registerHasChanges: (f: () => boolean) => void;
    hasChangesUpdated: () => void;
}) {
    return (
        <>
            <Section>
                <SectionHeader>What is the project?</SectionHeader>
                {!newProject && <ProjectIdControl project={project} />}
                <ProjectTypeControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    hideValidateControl={true}
                />
                <NameControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    hideValidateControl={true}
                />
                <BeehiveCampaignsControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            {!newProject && (
                <Section>
                    <SectionHeader>When is the project?</SectionHeader>
                    <CommunityEventDatesControl
                        project={project}
                        setProjectAndValidate={setProjectAndValidate}
                    />
                </Section>
            )}

            <Section>
                <SectionHeader>Why is the project being done?</SectionHeader>
                <ReasonForPlantingControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    hideValidateControl={true}
                />
                <ImpactStoryCompletedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    hideValidateControl={true}
                />
            </Section>

            {!newProject && (
                <Section>
                    <SectionHeader>Who is participating in the project?</SectionHeader>
                    <SubSectionHeader>Funding partners</SubSectionHeader>
                    <FundingPartnersControl participations={project.Participations} />
                </Section>
            )}

            {!newProject && (
                <Section>
                    <SectionHeader>What is being planted?</SectionHeader>
                    <TreeSpeciesReadOnly project={project} />
                </Section>
            )}
        </>
    );
}
