import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {NumericHeader, NumericCell} from '../../../components/Table/TableStyles';
import {EditedParticipation} from '../EditedProject';

export const PartnersTable = styled.table.attrs({className: 'table'})`
    margin-bottom: 1rem;
`;

export function CarbonFundingPartnersControl({
    participations
}: {
    participations: EditedParticipation[];
}) {
    return (
        <PartnersTable>
            <thead>
                <tr>
                    <th>Partner</th>
                    <NumericHeader>Carbon Credits</NumericHeader>
                    <NumericHeader>Purchase year</NumericHeader>
                </tr>
            </thead>
            <tbody>
                {participations?.map((ppn, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <Link to={`/partners/${ppn.PartnerId}`}>{ppn.Partner.Name}</Link>
                            </td>
                            <NumericCell>{ppn.CarbonCreditsTransacted}</NumericCell>
                            <NumericCell>{ppn.FundedYear ?? ''}</NumericCell>
                        </tr>
                    );
                })}
            </tbody>
        </PartnersTable>
    );
}
