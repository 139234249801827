import { EditedParticipation } from "../../../Partner/EditedPartner";
import { StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { EmptyFilter } from "../Shared/EmptyFilter";

export function D365Header() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				textAlign: 'left',
				width: '60px',
				minWidth: '60px',
				maxWidth: '60px'
			}}>
			D365
		</StickyHeader>
	)
}

export function D365Filter() {
	return (
		<EmptyFilter />
	)
}

export function D365Content({ ppn }: { ppn: EditedParticipation }) {
	const href = ppn.isOrderProduct
        ? `https://arbordayfarm.crm.dynamics.com/main.aspx?appid=aaf69a85-59da-4da2-9c1b-219a888ed0e8&pagetype=entityrecord&etn=salesorderdetail&id=${ppn.D365RevenuePostingInvoiceId}`
        : `https://arbordayfarm.crm.dynamics.com/main.aspx?appid=aaf69a85-59da-4da2-9c1b-219a888ed0e8&pagetype=entityrecord&etn=stn_revenuepostinginvoice&id=${ppn.D365RevenuePostingInvoiceId}`;

    return (
        <StateStyledCell editStatus={ppn.EditStatus}>
            {ppn.D365RevenuePostingInvoiceId !== null && (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    View
                </a>
            )}
        </StateStyledCell>
    );
}