import { EditedParticipation } from "../../../Partner/EditedPartner";
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import {
	ValidatedIcon,
	ValidateFilter,
	ValidateFilterValue
} from "../../../../components/Controls/ValidateControl";
import {
	ComplexInputWithAsyncUpdate,
	TextControlShowingEdits,
	TextFilter,
	ValidationsList
} from "../../../../components/Form/FormStyles";
import { AlignToTextBoxContent, ValidationTippy, ValidationTippyContent } from "../../Styles/ParticipationRowStyles";

export function ParticipantRegistryLinkHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '400px',
				minWidth: '400px',
				maxWidth: '400px'
			}}>
			Registry Link
		</StickyHeader>
	)
}

export function ParticipantRegistryLinkFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.ParticipantRegistryLinkValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							ParticipantRegistryLinkValidated: e.value
						})
					}
				/>
				<TextFilter
					value={filter?.ParticipantRegistryLink ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							ParticipantRegistryLink: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function ParticipantRegistryLinkContent({
	ppn,
	updater
}: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<ValidatedIcon />
				) : (
					<>
						<ValidatedIcon />
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={
											ppn.ParticipantRegistryLink.validations
										}
									/>
								</ValidationTippyContent>
							}
							disabled={
								ppn.ParticipantRegistryLink.validations.length === 0
							}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={TextControlShowingEdits}
									showModification
									value={ppn.ParticipantRegistryLink}
									onChange={(updatedValue) => {
										updater.update({
											...ppn,
											ParticipantRegistryLink: updatedValue
										});
									}}
									valueGetter={(e) =>
										ppn.ParticipantRegistryLink.update(
											e.target.value
										)
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledCell>
	)
}