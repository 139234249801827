import {Section, SectionHeader, SubSectionHeader} from '../../../components/Form/FormStyles';
import AdditionalProjectDetails from './AdditionalProjectDetails';
import {EditedProject} from '../EditedProject';
import {AcresRestoredControl} from '../MultipleProjectTypes/AcresRestoredControl';
import {CarbonFundingPartnersControl} from './CarbonFundingPartnersControl';
import { ImpactStoryCompletedControl } from '../MultipleProjectTypes/ImpactStoryCompletedControl';
import {LocationControl} from '../MultipleProjectTypes/LocationControl';
import { MapControl } from '../MultipleProjectTypes/Map/MapControl';
import {NameControl} from '../MultipleProjectTypes/NameControl';
import {PlantingPartnerControl} from '../MultipleProjectTypes/PlantingPartnerControl';
import {ProjectIdControl} from '../MultipleProjectTypes/ProjectIdControl';
import { FixedCompletedProjectStatusControl } from '../MultipleProjectTypes/ProjectStatusControl';
import {ProjectTypeControl} from '../MultipleProjectTypes/ProjectTypeControl';
import { ProjectYearsControl } from '../MultipleProjectTypes/ProjectYearsControl';
import { SustainabilityDevelopmentGoalsControl } from './SustainabilityDevelopmentGoalsControl';
import {ImagesSection} from '../MultipleProjectTypes/ImagesSection';
import {ImpactStoryUneditedControl} from '../MultipleProjectTypes/ImpactStoryFromUnedited';

export function CarbonProject({
    project,
    setProjectAndValidate,
    newProject,
    navigationPromptActive,
    setNavigationPromptActive,
    registerSave,
    registerHasChanges,
    hasChangesUpdated
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    newProject?: boolean;
    navigationPromptActive: boolean;
    setNavigationPromptActive: (navigationPromptActive: boolean) => void;
    registerSave: (f: (projectId: number) => void) => void;
    registerHasChanges: (f: () => boolean) => void;
    hasChangesUpdated: () => void;
}) {
    return (
        <>
            <Section>
                <FixedCompletedProjectStatusControl />
            </Section>

            <Section>
                <SectionHeader>What is the project?</SectionHeader>
                <ProjectIdControl project={project} />
                <ProjectTypeControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <NameControl project={project} setProjectAndValidate={setProjectAndValidate} />
            </Section>

            <Section>
                <SectionHeader>Where is the project?</SectionHeader>
                <LocationControl project={project} setProjectAndValidate={setProjectAndValidate} />
                <MapControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                    navigationPromptActive={navigationPromptActive}
                    setNavigationPromptActive={setNavigationPromptActive}
                />
            </Section>

            <Section>
                <SectionHeader>When is the project?</SectionHeader>
                <ProjectYearsControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>Why is the project being done?</SectionHeader>
                <SustainabilityDevelopmentGoalsControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <ImpactStoryUneditedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <ImpactStoryCompletedControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <Section>
                <SectionHeader>Who is participating in the project?</SectionHeader>
                <PlantingPartnerControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <SubSectionHeader>Funding partners</SubSectionHeader>
                <CarbonFundingPartnersControl participations={project.Participations} />
            </Section>

            <Section>
                <SectionHeader>What is being planted?</SectionHeader>
                <AcresRestoredControl
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </Section>

            <AdditionalProjectDetails
                project={project}
                setProjectAndValidate={setProjectAndValidate}
            />

            <ImagesSection
                newProject={newProject}
                project={project}
                setProjectAndValidate={setProjectAndValidate}
            />
        </>
    );
}
