import {useState, useEffect} from 'react';
import Select from 'react-select';
import {CarbonProjectStandards} from '../../../entities';
import {EditedProject} from '../EditedProject';

export function ProjectStandard({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [options, setOptions] = useState<CarbonProjectStandardOption[]>(null);
    const [selectedOption, setSelectedOption] = useState<CarbonProjectStandardOption>(null);

    useEffect(() => {
        setOptions(getCarbonStandardsOptions());
    }, []);

    useEffect(() => {
        const selected = project.CarbonProjectStandard.current;

        if (!options || !selected) return;

        setSelectedOption(options.find((x) => x.value === selected));
    }, [options]);

    const onChange = (e) => {
        setSelectedOption(e);
        setProjectAndValidate({
            ...project,
            CarbonProjectStandard: project.CarbonProjectStandard.update(e.value)
        });
    };

    return <Select value={selectedOption} onChange={(e) => onChange(e)} options={options} />;
}

function getCarbonStandardsOptions(): CarbonProjectStandardOption[] {
    return Object.keys(CarbonProjectStandards)
        .filter((x) => isNaN(Number(x)))
        .map((name) => {
            const id = CarbonProjectStandards[name as keyof CarbonProjectStandards];
            return {
                name,
                value: id,
                label: formatCarbonProjectStandards(id)
            };
        });
}

export function formatCarbonProjectStandards(standard: CarbonProjectStandards) {
    switch (standard) {
        case CarbonProjectStandards.VerraVCS:
            return 'Verra: VCS';
        case CarbonProjectStandards.VerraVCSCCB:
            return 'Verra: VCS + CCB';
        case CarbonProjectStandards.VerraVCSCCBSDVISta:
            return 'Verra: VCS + CCB + SD VISta';
        case CarbonProjectStandards.PlanVivo:
            return 'Plan Vivo';
        case CarbonProjectStandards.ACR:
            return 'ACR';
        case CarbonProjectStandards.GoldStandard:
            return 'Gold Standard';
        case CarbonProjectStandards.CAR:
            return 'CAR';
        case CarbonProjectStandards.UNFCCCMechanisms:
            return 'UNFCCC Mechanisms';
        case CarbonProjectStandards.ERFOfTheAustralianGovernment:
            return 'ERF of the Australian Government';
        case CarbonProjectStandards.UKWoodlandCarbonCode:
            return 'UK Woodland Carbon Code';
        case CarbonProjectStandards.ARTTrees:
            return 'ART Trees';
        case CarbonProjectStandards.CityForestCredits:
            return 'City Forest Credits';
    }
}

type CarbonProjectStandardOption = {
    name: string;
    value: number;
    label: string;
};

export default ProjectStandard;
