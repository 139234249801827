import styled, {css} from 'styled-components';

export const AlertBaseCss = css`
    display: inline-block;
    padding: 1rem;
    border-radius: 0.5rem;
    z-index: 1;
    max-width: 500px;
    margin-bottom: 1rem;
`;

export const SuccessAlert = styled.div`
    ${AlertBaseCss}
    background-color: rgb(200, 255, 200);
`;

export const FailureAlert = styled.div`
    ${AlertBaseCss}
    background-color: rgb(255, 200, 200);
`;

export const InfoAlert = styled.div`
    ${AlertBaseCss}
    color: #0c5460;
    background-color: #d1ecf1;
`;
