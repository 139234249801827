import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { TextFilter } from "../../../../components/Form/FormStyles";
import { formatSustainableDevelopmentGoal } from "../../../PlantingProjectEditor/Carbon/SustainableDevelopmentGoals";

export function SustainableDevelopmentGoalsHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '250px', minWidth: '250px', maxWidth: '250px' }}>
			Sustainable Development Goals
		</StickyHeader>
	)
}

export function SustainableDevelopmentGoalsFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.SustainableDevelopmentGoalsValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							SustainableDevelopmentGoalsValidated: e.value
						})
					}
				/>
				<TextFilter
					value={filter?.SustainableDevelopmentGoals ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							SustainableDevelopmentGoals: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function SustainableDevelopmentGoalsContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.SustainableDevelopmentGoalsValidated && (
						<ValidatedIcon />
					)}
				</span>
				{
					<span>
						{ppn.PlantingProject.SustainableDevelopmentGoals?.map((x) =>
							formatSustainableDevelopmentGoal(x.SustainableDevelopmentGoal)
						).join(', ')}
					</span>
				}
			</CellWithValidator>
		</StateStyledCell>
	)
}