
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import { CellWithValidator, StickyNumericHeader } from "../../../../components/Table/TableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { NumericFilter } from "../../../../components/Form/FormStyles";

export function CommunityCorpVolunteersHeader() {
	return (
		<StickyNumericHeader
			style={{
				...offsetOneRow,
				width: '160px',
				minWidth: '160px',
				maxWidth: '160px'
			}}>
			Community corp. volunteers
		</StickyNumericHeader>
	)
}

export function CommunityCorpVolunteersFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CommunityNumberOfCorporateVolunteersValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityNumberOfCorporateVolunteersValidated: e.value
						})
					}
				/>
				<NumericFilter
					value={filter?.CommunityNumberOfCorporateVolunteers ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityNumberOfCorporateVolunteers: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function CommunityCorpVolunteersContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledNumberCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject
						.CommunityNumberOfCorporateVolunteersValidated && (
							<ValidatedIcon />
						)}
				</span>
				<span>
					{ppn.PlantingProject.CommunityNumberOfCorporateVolunteers?.toLocaleString()}
				</span>
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}