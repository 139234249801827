import {PlantingProjectType} from '../../../entities';
import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    NumberControlShowingEdits,
    ValidationsList
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import {NumericFormFieldContainer} from './NumericFormFieldContainer';

export function AcresRestoredControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <NumericFormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.AcresRestoredValidated.current}
                        disabled={project.AcresRestored.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                AcresRestoredValidated: project.AcresRestoredValidated.update(
                                    e.validated
                                )
                            })
                        }
                    />
                )}
                <Label htmlFor="acresRestored">
                    {project.ProjectType.current === PlantingProjectType.Carbon
                        ? 'Total Project Acres'
                        : 'Acres restored'}
                </Label>
            </LabelContainer>
            <NumberControlShowingEdits
                id="acresRestored"
                disabled={project.ControlledByAutomation}
                value={project.AcresRestored}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        AcresRestored: project.AcresRestored.update(getNumberFromInput(e))
                    })
                }></NumberControlShowingEdits>
            <ValidationsList validations={project.AcresRestored.validations} />
        </NumericFormFieldContainer>
    );
}
