import styled from 'styled-components';
import {EditedProject} from '../EditedProject';
import {
    Section,
    SectionHeader,
    LabelContainer,
    Label,
    NumberControlShowingEdits,
    ValidationsList,
    FormFieldContainer,
    TextControlShowingEdits
} from '../../../components/Form/FormStyles';
import {getNumberFromInput} from '../../../record-editing';
import {ProjectStandard} from './ProjectStandard';
import {CarbonCreditTypeSelect} from './CarbonCreditType';
import {CarbonCorsiaEligible} from './CarbonCorsiaEligible';
import {CarbonICroaEndorsed} from './CarbonICroaEndorsed';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import CarbonCreditAdditionalCertificationsComponent from './CarbonCreditAdditionalCertifications';

const StyledFormFieldContainer = styled(FormFieldContainer)`
    max-width: 200px;
`;

const WideContainer = styled.div`
    max-width: 500px;
`;

const ExtraWideContainer = styled.div`
    max-width: 100%;
`;

export function AdditionalProjectDetails({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <Section>
            <SectionHeader>Additional Project Details</SectionHeader>
            <StyledFormFieldContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.CarbonTotalCreditsIssuedValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CarbonTotalCreditsIssuedValidated:
                                    project.CarbonTotalCreditsIssuedValidated.update(e.validated)
                            })
                        }
                    />
                    <Label htmlFor="carbonTotalCreditsIssued">Total Carbon Credits</Label>
                </LabelContainer>
                <NumberControlShowingEdits
                    id="carbonTotalCreditsIssued"
                    value={project.CarbonTotalCreditsIssued}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            CarbonTotalCreditsIssued: project.CarbonTotalCreditsIssued.update(
                                getNumberFromInput(e)
                            )
                        })
                    }></NumberControlShowingEdits>
            </StyledFormFieldContainer>
            <WideContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.CarbonProjectStandardValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CarbonProjectStandardValidated:
                                    project.CarbonProjectStandardValidated.update(e.validated)
                            })
                        }
                    />
                    <Label htmlFor="carbonProjectStandard">Project Standard</Label>
                </LabelContainer>
                <ProjectStandard project={project} setProjectAndValidate={setProjectAndValidate} />
                <ValidationsList validations={project.CarbonProjectStandard.validations} />
            </WideContainer>
            <WideContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.CarbonCreditCreditTypeValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CarbonCreditCreditTypeValidated:
                                    project.CarbonCreditCreditTypeValidated.update(e.validated)
                            })
                        }
                    />
                    <Label htmlFor="carbonCreditCreditType">Carbon Credit Type</Label>
                </LabelContainer>
                <CarbonCreditTypeSelect
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
                <ValidationsList validations={project.CarbonCreditCreditType.validations} />
            </WideContainer>
            <WideContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.AdditionalCarbonCreditCertificationsValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                AdditionalCarbonCreditCertificationsValidated:
                                    project.AdditionalCarbonCreditCertificationsValidated.update(
                                        e.validated
                                    )
                            })
                        }
                    />
                    <Label htmlFor="carbonCreditAdditionalCertifications">
                        Carbon Credit Additional Certifications
                    </Label>
                </LabelContainer>
                <CarbonCreditAdditionalCertificationsComponent
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </WideContainer>
            <WideContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.CarbonCorsiaEligibleValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CarbonCorsiaEligibleValidated:
                                    project.CarbonCorsiaEligibleValidated.update(e.validated)
                            })
                        }
                    />
                    <Label>Carbon CORSIA Eligible</Label>
                </LabelContainer>
                <CarbonCorsiaEligible
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </WideContainer>
            <WideContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.CarbonICroaEndorsedStandardValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CarbonICroaEndorsedStandardValidated:
                                    project.CarbonICroaEndorsedStandardValidated.update(e.validated)
                            })
                        }
                    />
                    <Label>Carbon ICROA Endorsed Standard</Label>
                </LabelContainer>
                <CarbonICroaEndorsed
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </WideContainer>
            <ExtraWideContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.CarbonAdditionalDetailsLinkValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CarbonAdditionalDetailsLinkValidated:
                                    project.CarbonAdditionalDetailsLinkValidated.update(e.validated)
                            })
                        }
                    />
                    <Label htmlFor="carbonAdditionalDetailsLink">CC Details Link</Label>
                </LabelContainer>
                <TextControlShowingEdits
                    id="carbonAdditionalDetailsLink"
                    value={project.CarbonAdditionalDetailsLink}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            CarbonAdditionalDetailsLink: project.CarbonAdditionalDetailsLink.update(
                                e.target.value
                            )
                        })
                    }></TextControlShowingEdits>
                <ValidationsList validations={project.CarbonAdditionalDetailsLink.validations} />
            </ExtraWideContainer>
            <ExtraWideContainer>
                <LabelContainer>
                    <ValidateControl
                        validated={project.CarbonProjectRegistryLinkValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CarbonProjectRegistryLinkValidated:
                                    project.CarbonProjectRegistryLinkValidated.update(e.validated)
                            })
                        }
                    />
                    <Label htmlFor="carbonProjectRegistryLink">CC Registry Link</Label>
                </LabelContainer>
                <TextControlShowingEdits
                    id="carbonProjectRegistryLink"
                    value={project.CarbonProjectRegistryLink}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            CarbonProjectRegistryLink: project.CarbonProjectRegistryLink.update(
                                e.target.value
                            )
                        })
                    }></TextControlShowingEdits>
                <ValidationsList validations={project.CarbonProjectRegistryLink.validations} />
            </ExtraWideContainer>
        </Section>
    );
}

export default AdditionalProjectDetails;
