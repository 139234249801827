
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { TextFilter } from "../../../../components/Form/FormStyles";

export function TTFForestHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '320px', minWidth: '320px', maxWidth: '320px' }}>
			TTF forest
		</StickyHeader>
	)
}

export function TTFForestFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.TTFForestCodeValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, TTFForestCodeValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.TTFForest ?? ''}
					onChange={(e) => updateFilter({ ...filter, TTFForest: e.target.value })}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function TTFForestContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.TTFForestCodeValidated && <ValidatedIcon />}
				</span>
				<span>{ppn.PlantingProject.TTFForestName}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}