import {useState, useEffect} from 'react';
import Select from 'react-select';
import {SustainableDevelopmentGoal, SustainableDevelopmentGoals} from '../../../entities';
import {EditedProject, EditedSustainableDevelopmentGoal} from '../EditedProject';
import {RecordEditStatus} from '../../../record-editing';

export function SustainableDevelopmentGoalContainer({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [availableOptions, setAvailableOptions] = useState<SustainableGoalType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [originalGoals, setOriginalGoals] = useState<EditedSustainableDevelopmentGoal[]>([]);

    useEffect(() => {
        setSelectedOptions(
            project.SustainableDevelopmentGoals?.map((x) => setterSelectedOptions(x))
        );
    }, []);

    function setterSelectedOptions(x: EditedSustainableDevelopmentGoal): SustainableGoalType {
        const goal: SustainableGoalType = {
            value: x.SustainableDevelopmentGoalType,
            label: formatSustainableDevelopmentGoal(x.SustainableDevelopmentGoalType),
            name: SustainableDevelopmentGoal[x.SustainableDevelopmentGoalType].toString()
        };
        return goal;
    }

    useEffect(() => {
        setOriginalGoals(project.SustainableDevelopmentGoals);
    }, []);

    useEffect(() => {
        const susOptions = getSustainableDevelopmentObject();
        setAvailableOptions(susOptions);
    }, []);

    const onChange = (e) => {
        setSelectedOptions(e);
        setProjectAndValidate({
            ...project,
            SustainableDevelopmentGoals: updateSustainableGoalRecordStatus(e)
        });
    };

    function updateSustainableGoalRecordStatus(
        goalsInState: SustainableGoalType[]
    ): EditedSustainableDevelopmentGoal[] {
        const updatedGoalList: EditedSustainableDevelopmentGoal[] = [];

        goalsInState.forEach((goalToEval) => {
            //if the goal already exists, keep going
            if (
                originalGoals.some(
                    (types) => types.SustainableDevelopmentGoalType === goalToEval.value
                )
            ) {
                updatedGoalList.push({
                    EditStatus: RecordEditStatus.Existing,
                    SustainableDevelopmentGoalType: goalToEval.value
                });
            }
            //if it's in the new list but not the original, add it
            else if (
                !originalGoals.some(
                    (types) => types.SustainableDevelopmentGoalType === goalToEval.value
                )
            ) {
                updatedGoalList.push({
                    EditStatus: RecordEditStatus.New,
                    SustainableDevelopmentGoalType: goalToEval.value
                });
            }
        });

        //cycle through all the goals and add any that have been removed and are not in the current state
        originalGoals.forEach((originalGoals) => {
            if (
                !goalsInState.some(
                    (goalsInState) =>
                        goalsInState.value === originalGoals.SustainableDevelopmentGoalType
                )
            ) {
                updatedGoalList.push({
                    EditStatus: RecordEditStatus.Deleted,
                    SustainableDevelopmentGoalType: originalGoals.SustainableDevelopmentGoalType
                });
            }
        });

        return updatedGoalList;
    }

    return (
        <Select
            value={selectedOptions}
            onChange={(e) => onChange(e)}
            options={availableOptions}
            isMulti
        />
    );
}

export function formatSustainableDevelopmentGoalArray(goals: SustainableDevelopmentGoals[]) {
    const goalStrings = goals?.map((goal) =>
        formatSustainableDevelopmentGoal(goal.SustainableDevelopmentGoal)
    );
    return goalStrings?.join(', ');
}

export function formatSustainableDevelopmentGoal(goal: SustainableDevelopmentGoal) {
    switch (goal) {
        case SustainableDevelopmentGoal.NoPoverty:
            return 'No Poverty';
        case SustainableDevelopmentGoal.ZeroHunger:
            return 'Zero Hunger';
        case SustainableDevelopmentGoal.GoodHealthAndWellBeing:
            return 'Good Health and Well Being';
        case SustainableDevelopmentGoal.QualityEducation:
            return 'Quality Education';
        case SustainableDevelopmentGoal.GenderEquality:
            return 'Gender Equality';
        case SustainableDevelopmentGoal.CleanWaterAndSanitation:
            return 'Clean Water and Sanitation';
        case SustainableDevelopmentGoal.AffordableAndCleanEnergy:
            return 'Affordable and Clean Energy';
        case SustainableDevelopmentGoal.DecentWorkAndEconomicGrowth:
            return 'Decent Work and Economic Growth';
        case SustainableDevelopmentGoal.IndustryInnovationAndInfrastructure:
            return 'Industry Innovation and Infrastructure';
        case SustainableDevelopmentGoal.ReducedInequalities:
            return 'Reduced Inequalities';
        case SustainableDevelopmentGoal.SustainableCitiesAndCommunities:
            return 'Sustainable Cities and Communities';
        case SustainableDevelopmentGoal.ResponsibleConsumptionAndProduction:
            return 'Responsible Consumption and Production';
        case SustainableDevelopmentGoal.ClimateAction:
            return 'Climate Action';
        case SustainableDevelopmentGoal.LifeBelowWater:
            return 'Life Below Water';
        case SustainableDevelopmentGoal.LifeOnLand:
            return 'Life On Land';
        case SustainableDevelopmentGoal.PeaceJusticeAndStrongInstitutions:
            return 'Peace Justice and Strong Institutions';
    }
}

function getSustainableDevelopmentObject(): SustainableGoalType[] {
    return Object.keys(SustainableDevelopmentGoal)
        .filter((x) => isNaN(Number(x)))
        .map((name) => {
            const id = SustainableDevelopmentGoal[name as keyof SustainableDevelopmentGoal];
            return {
                name,
                value: id,
                label: formatSustainableDevelopmentGoal(id)
            };
        });
}

type SustainableGoalType = {
    name: string;
    value: number;
    label: string;
};

export default SustainableDevelopmentGoalContainer;
