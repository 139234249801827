import {
    FormFieldContainer,
    Label,
    LabelContainer,
    TextAreaControlShowingEdits,
    ValidationsList
} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';

export function ImpactStoryUneditedControl({
    project,
    setProjectAndValidate,
    disabled
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    disabled?: boolean;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                <Label htmlFor="impactStoryCompleted">
                    Impact story - unedited - reference only
                </Label>
            </LabelContainer>
            <TextAreaControlShowingEdits
                id="impactStoryUnedited"
                disabled={disabled}
                rows={6}
                value={project.ImpactStoryUnedited}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        ImpactStoryUnedited: project.ImpactStoryUnedited.update(e.target.value)
                    })
                }></TextAreaControlShowingEdits>
            <ValidationsList validations={project.ImpactStoryUnedited.validations} />
        </FormFieldContainer>
    );
}
