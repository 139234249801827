import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function HasHeroHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '70px',
				minWidth: '70px',
				maxWidth: '70px'
			}}>
			Has hero
		</StickyHeader>
	)
}

export function HasHeroFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<TextFilter
				value={filter?.HasHeroImage ?? ''}
				onChange={(e) =>
					updateFilter({ ...filter, HasHeroImage: e.target.value })
				}
			/>
		</StickyHeader>
	)
}

export function HasHeroContent({ ppn, hasHero }: { ppn: EditedParticipation, hasHero: boolean }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<span>{hasHero ? 'Y' : ''}</span>
		</StateStyledCell>
	)
}