import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, fixedNameColumn, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ProjectNameHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				zIndex: 1,
				...fixedNameColumn,
				width: '355px',
				minWidth: '355px',
				maxWidth: '355px'
			}}>
			Name
		</StickyHeader>
	)
}

export function ProjectNameFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows, zIndex: 1, ...fixedNameColumn }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.NameValidated ?? ValidateFilterValue.Unset}
					onChange={(e) => updateFilter({ ...filter, NameValidated: e.value })}
				/>
				<TextFilter
					value={filter?.Name ?? ''}
					onChange={(e) => updateFilter({ ...filter, Name: e.target.value })}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function ProjectNameContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			style={{ position: 'sticky', ...fixedNameColumn }}
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				{ppn.PlantingProject.Id ? (
					<>
						<span>{ppn.PlantingProject.NameValidated && <ValidatedIcon />}</span>
						<span>{ppn.PlantingProject.Name}</span>
					</>
				) : (
					<>
						<span></span>
						<span>{ppn.TTFForestName}</span>
					</>
				)}
			</CellWithValidator>
		</StateStyledCell>
	)
}