import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { formatProjectStatus } from "../../../../entities"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ProjectStatusHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '125px', minWidth: '125px', maxWidth: '125px' }}>
			Project status
		</StickyHeader>
	)
}

export function ProjectStatusFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.ProjectStatusValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, ProjectStatusValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.ProjectStatus ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, ProjectStatus: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function ProjectStatusContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>{ppn.PlantingProject.CompletedValidated && <ValidatedIcon />}</span>
				<span>
					{ppn.PlantingProject.Id &&
						formatProjectStatus(ppn.PlantingProject.Completed)}
				</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}