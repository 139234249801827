import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export const ArchivedSignifier = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: rgb(60, 220, 60);
    display: inline-block;
    margin-left: 0.5rem;
    line-height: 1;
`;

const ArchivedFilterIcon = styled.span`
    font-size: 13px;
    color: rgb(60, 220, 60);
    border: 1px solid rgb(60, 220, 60);
    border-radius: 13px;
    padding: 0px 6px 2px 6px;
    position: relative;
    top: -1px;
`;

const NotArchivedFilterIcon = styled.span`
    font-size: 13px;
    text-decoration: line-through;
    color: rgb(255, 147, 69);
    border: 1px solid rgb(255, 147, 69);
    border-radius: 13px;
    padding: 0px 6px 2px 6px;
    position: relative;
    top: -1px;
`;

const NotFilteredFilterIcon = styled.span`
    font-size: 13px;
    color: rgb(200, 200, 200);
    border: 1px solid rgb(200, 200, 200);
    border-radius: 13px;
    padding: 0px 6px 2px 6px;
    position: relative;
    top: -1px;
`;

const FilterContainer = styled.span`
    cursor: pointer;
`;

export enum ArchivedFilterValue {
    Unset = 1,
    Archived = 2,
    NotArchived = 3
}

export function ArchivedFilter({
    value,
    onChange
}: {
    value: ArchivedFilterValue;
    onChange: (value: ArchivedFilterValue) => void;
}) {
    function message() {
        switch (value) {
            case ArchivedFilterValue.Unset:
                return 'Showing archived and not archived';
            case ArchivedFilterValue.Archived:
                return 'Showing archived';
            case ArchivedFilterValue.NotArchived:
                return 'Showing not archived';
            default:
                return null;
        }
    }

    function icon() {
        switch (value) {
            case ArchivedFilterValue.Unset:
                return <NotFilteredFilterIcon>Archived</NotFilteredFilterIcon>;
            case ArchivedFilterValue.Archived:
                return <ArchivedFilterIcon>Archived</ArchivedFilterIcon>;
            case ArchivedFilterValue.NotArchived:
                return <NotArchivedFilterIcon>Archived</NotArchivedFilterIcon>;
            default:
                return null;
        }
    }

    function nextValue() {
        switch (value) {
            case ArchivedFilterValue.Unset:
                return ArchivedFilterValue.Archived;
            case ArchivedFilterValue.Archived:
                return ArchivedFilterValue.NotArchived;
            case ArchivedFilterValue.NotArchived:
                return ArchivedFilterValue.Unset;
            default:
                return null;
        }
    }

    return (
        <Tippy content={message()}>
            <FilterContainer onClick={() => onChange(nextValue())}>{icon()}</FilterContainer>
        </Tippy>
    );
}
