import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { offsetOneRow, offsetTwoRows, StateStyledDateCell } from "../../Styles/ParticipationTableStyles";
import { CellWithValidator, StickyDateHeader } from "../../../../components/Table/TableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { TextFilter } from "../../../../components/Form/FormStyles";

export function CommunityEventDatesHeader() {
	return (
		<StickyDateHeader
			style={{ ...offsetOneRow, width: '230px', minWidth: '230px', maxWidth: '230px' }}>
			Community event dates
		</StickyDateHeader>
	)
}

export function CommunityEventDatesFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyDateHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CommunityEventDatesValidated ?? ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({ ...filter, CommunityEventDatesValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.CommunityEventDates ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, CommunityEventDates: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyDateHeader>
	)
}

export function CommunityEventDatesContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledDateCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.CommunityEventDatesValidated && <ValidatedIcon />}
				</span>
				<span>
					{ppn.PlantingProject.CommunityFirstDayOfEvent ===
						ppn.PlantingProject.CommunityFinalDayOfEvent
						? ppn.PlantingProject.CommunityFinalDayOfEvent
						: `${ppn.PlantingProject.CommunityFirstDayOfEvent ?? ''} - ${ppn.PlantingProject.CommunityFinalDayOfEvent ?? ''
						}`}
				</span>
			</CellWithValidator>
		</StateStyledDateCell>
	)
}