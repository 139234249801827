export enum RecordEditStatus {
    New = 1,
    Existing = 2,
    Deleted = 3,
    NewDeleted = 4
}

export function deleteStatus(currentStatus: RecordEditStatus) {
    switch (currentStatus) {
        case RecordEditStatus.New:
            return RecordEditStatus.NewDeleted;
        default:
            return RecordEditStatus.Deleted;
    }
}

export enum ValidationLevel {
    NotValidToSave = 1,
    NotValidToShowPartner = 2,
    Warning = 3
}

export type Validation = {
    level: ValidationLevel;
    message: string;
};

function nullify(value) {
    if (value === '' || value === undefined) return null;
    return value;
}

export function getNumberFromInput(e) {
    return e.target.value === '' ? null : parseFloat(e.target.value);
}

export class EditedField<Type> {
    validations: Validation[];

    constructor(public original: Type, public current?: Type) {
        this.original = original;
        this.current = current === undefined ? original : current;
        this.validations = [];
    }

    get modified() {
        return this.current !== this.original;
    }

    update(value: Type): EditedField<Type> {
        const newValue = new EditedField(this.original, nullify(value));
        newValue.validations = this.validations;
        return newValue;
    }

    get notValidToSave() {
        return (
            this.validations.findIndex(
                (validation) => validation.level === ValidationLevel.NotValidToSave
            ) !== -1
        );
    }

    get notValidToShowPartner() {
        return (
            this.notValidToSave ||
            this.validations.findIndex(
                (validation) => validation.level === ValidationLevel.NotValidToShowPartner
            ) !== -1
        );
    }

    get hasWarning() {
        return (
            this.validations.findIndex(
                (validation) => validation.level === ValidationLevel.Warning
            ) !== -1
        );
    }
}
