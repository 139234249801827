import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { NumericFilter } from "../../../../components/Form/FormStyles"
import { StickyNumericHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles"

export function ProjectYearsHeader() {
	return (
		<StickyNumericHeader
			style={{ ...offsetOneRow, width: '130px', minWidth: '130px', maxWidth: '130px' }}>
			Project years
		</StickyNumericHeader>
	)
}

export function ProjectYearsFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.ProjectYearsValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, ProjectYearsValidated: e.value })
					}
				/>
				<NumericFilter
					value={filter?.ProjectYears ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, ProjectYears: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function ProjectYearsContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledNumberCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.ProjectYearsValidated && <ValidatedIcon />}
				</span>
				<span>
					{ppn.PlantingProject.ProjectStartYear ===
						ppn.PlantingProject.ProjectEndYear
						? ppn.PlantingProject.ProjectEndYear
						: `${ppn.PlantingProject.ProjectStartYear ?? ''} - ${ppn.PlantingProject.ProjectEndYear ?? ''
						}`}
				</span>
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}