import { ValidateFilter, ValidateFilterValue, ValidatedIcon, ValidateControl } from "../../../../components/Controls/ValidateControl"
import { NumericFilter, ValidationsList, ComplexInputWithAsyncUpdate } from "../../../../components/Form/FormStyles"
import { StickyNumericHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { PlantingProjectType } from "../../../../entities"
import { getNumberFromInput } from "../../../../record-editing"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications"
import { AlignToTextBoxContent, ValidationTippy, ValidationTippyContent, TreeCountControl } from "../../Styles/ParticipationRowStyles"
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles"

export function CommunityTreesDistributedHeader() {
	return (
		<StickyNumericHeader
			style={{
				...offsetOneRow,
				width: '130px',
				minWidth: '130px',
				maxWidth: '130px'
			}}>
			Community trees dist.
		</StickyNumericHeader>
	)
}

export function CommunityTreesDistributedFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CommunityTreesDistributedValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityTreesDistributedValidated: e.value
						})
					}
				/>
				<NumericFilter
					value={filter?.CommunityTreesDistributed ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityTreesDistributed: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function CommunityTreesDistributedContent({ ppn, updater }: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledNumberCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ||
					ppn.ProjectType === PlantingProjectType.CommunityCanopy ? (
					<>
						{ppn.CommunityTreesDistributedValidated.current && (
							<ValidatedIcon />
						)}
						<AlignToTextBoxContent>
							{ppn.CommunityTreesDistributed.current}
						</AlignToTextBoxContent>
					</>
				) : (
					<>
						<ValidateControl
							validated={ppn.CommunityTreesDistributedValidated.current}
							disabled={ppn.CommunityTreesDistributed.notValidToShowPartner}
							onChange={(e) =>
								updater.update({
									...ppn,
									CommunityTreesDistributedValidated:
										ppn.CommunityTreesDistributedValidated.update(
											e.validated
										)
								})
							}
						/>
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={
											ppn.CommunityTreesDistributed.validations
										}
									/>
								</ValidationTippyContent>
							}
							disabled={
								ppn.CommunityTreesDistributed.validations.length === 0
							}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={TreeCountControl}
									showModification
									value={ppn.CommunityTreesDistributed}
									onChange={(updatedValue) =>
										updater.update({
											...ppn,
											CommunityTreesDistributed: updatedValue
										})
									}
									valueGetter={(e) =>
										ppn.CommunityTreesDistributed.update(
											getNumberFromInput(e)
										)
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}