
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { TextFilter } from "../../../../components/Form/FormStyles";
import { formatCarbonProjectStandards } from "../../../PlantingProjectEditor/Carbon/ProjectStandard";

export function CCProjectStandardHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '200px',
				minWidth: '200px',
				maxWidth: '200px'
			}}>
			CC Project Standard
		</StickyHeader>
	)
}

export function CCProjectStandardFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CarbonProjectStandardValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CarbonProjectStandardValidated: e.value
						})
					}
				/>
				<TextFilter
					value={filter?.CarbonProjectStandard ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, CarbonProjectStandard: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function CCProjectStandardContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.CarbonProjectStandardValidated && (
						<ValidatedIcon />
					)}
				</span>
				<span>
					{formatCarbonProjectStandards(
						ppn.PlantingProject.CarbonProjectStandard
					)}
				</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}