import {
    LabelContainer,
    Label,
    ValidationsList,
    FormFieldContainer,
    RequiredSignifier,
    TextControlShowingEdits
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';

export function NameControl({
    project,
    setProjectAndValidate,
    hideValidateControl
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    hideValidateControl?: boolean;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && !hideValidateControl && (
                    <ValidateControl
                        validated={project.NameValidated.current}
                        disabled={project.Name.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                NameValidated: project.NameValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="name">Name</Label>
                <RequiredSignifier />
            </LabelContainer>
            <TextControlShowingEdits
                id="name"
                disabled={project.ControlledByAutomation}
                maxLength={100}
                value={project.Name}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        Name: project.Name.update(e.target.value)
                    })
                }></TextControlShowingEdits>
            <ValidationsList validations={project.Name.validations} />
        </FormFieldContainer>
    );
}
