import {
    ValidationsList,
    ComplexInputWithAsyncUpdate,
    TextFilter,
    TextControlShowingEdits
} from '../../../../components/Form/FormStyles';
import {StickyHeader} from '../../../../components/Table/TableStyles';
import {EditedParticipation} from '../../../Partner/EditedPartner';
import {Filter} from '../../ParticipationFunctions/Filtering';
import {ParticipationUpdater} from '../../ParticipationFunctions/ParticipationModifications';
import {
    AlignToTextBoxContentText,
    ValidationTippy,
    ValidationTippyContent
} from '../../Styles/ParticipationRowStyles';
import {offsetOneRow, offsetTwoRows, StateStyledCell} from '../../Styles/ParticipationTableStyles';

export function FundingSourceHeader() {
    return (
        <StickyHeader
            style={{...offsetOneRow, width: '250px', minWidth: '250px', maxWidth: '250px'}}>
            Funding source
        </StickyHeader>
    );
}

export function FundingSourceFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <StickyHeader style={{...offsetTwoRows}}>
            <TextFilter
                value={filter?.FundingSource ?? ''}
                onChange={(e) => updateFilter({...filter, FundingSource: e.target.value})}
            />
        </StickyHeader>
    );
}

export function FundingSourceContent({
    ppn,
    updater
}: {
    ppn: EditedParticipation;
    updater: ParticipationUpdater;
}) {
    return (
        <StateStyledCell editStatus={ppn.EditStatus}>
            {ppn.ControlledByAutomation ? (
                <AlignToTextBoxContentText>{ppn.FundingSource.current}</AlignToTextBoxContentText>
            ) : (
                <>
                    <ValidationTippy
                        content={
                            <ValidationTippyContent>
                                <ValidationsList validations={ppn.FundingSource.validations} />
                            </ValidationTippyContent>
                        }
                        disabled={ppn.FundingSource.validations.length === 0}>
                        <div>
                            <ComplexInputWithAsyncUpdate
                                Control={TextControlShowingEdits}
                                maxLength={75}
                                showModification
                                value={ppn.FundingSource}
                                onChange={(updatedValue) =>
                                    updater.update({...ppn, FundingSource: updatedValue})
                                }
                                valueGetter={(e) => ppn.FundingSource.update(e.target.value)}
                            />
                        </div>
                    </ValidationTippy>
                </>
            )}
        </StateStyledCell>
    );
}
