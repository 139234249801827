import Modal from './Modal';
import styled from 'styled-components';

const ModalWrapper = styled.div`
    color: #4a4a4a;
    cursor: default;
`;

const StyledLI = styled.li`
    margin-top: 10px;
`;

const TipsSectionHeader = styled.h4`
    margin-bottom: 5px;
`;

const HelpModal = ({
    showHelpModal,
    setShowHelpModal
}: {
    showHelpModal: boolean;
    setShowHelpModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return (
        <ModalWrapper>
            <Modal
                showModal={showHelpModal}
                onClose={() => {
                    setShowHelpModal(false);
                }}
                title={<h3 style={{marginTop: '0px', marginBottom: '0px'}}>Help</h3>}
                width="850px"
            >
                <TipsSectionHeader>Filtering Tips</TipsSectionHeader>
                <ul>
                    <StyledLI>
                        Numeric inputs can be compared using: &#62;, &#60; or &#61; symbols. <br />
                        Example: '&#62;10' in the Community Trees Planted filter would show all
                        projects with more than 10 trees.
                        <br />
                    </StyledLI>
                    <StyledLI>
                        Empty single quotes will show all fields with no inputs.
                        <br />
                        Example: When entering '' in the Community Trees Planted filter, all
                        projects with no location listed will appear.
                    </StyledLI>
                </ul>
            </Modal>
        </ModalWrapper>
    );
};

export default HelpModal;
