import Tippy from "@tippyjs/react"
import { ExcludeFilter, ExcludeFilterValue, ExcludeControl } from "../../../../components/Controls/ExcludeControl"
import { StickyHeader } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications"
import { offsetOneRow, offsetTwoRows, centeredContainerStyle, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ExcludedHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '50px', minWidth: '50px', maxWidth: '50px' }}>
			<Tippy content="Use to exclude participations from being viewed by the partner">
				<span>Excl</span>
			</Tippy>
		</StickyHeader>
	)
}

export function ExcludedFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows, ...centeredContainerStyle }}>
			<ExcludeFilter
				value={filter?.Excluded ?? ExcludeFilterValue.Unset}
				onChange={(e) => updateFilter({ ...filter, Excluded: e.value })}
			/>
		</StickyHeader>
	)
}

export function ExcludedContent({ ppn, updater }: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			<ExcludeControl
				excluded={ppn.Excluded.current}
				onChange={(e) =>
					updater.update({ ...ppn, Excluded: ppn.Excluded.update(e.excluded) })
				}
			/>
		</StateStyledCell>
	)
}