import {ActionContainer, AddButton} from '../../../components/Buttons/SaveChanges';
import {
    SaveChangesButton,
    SaveChangesMessage,
    DeleteButton,
    ActionList
} from '../../../components/Buttons/SaveChanges';
import {EditedProject} from '../EditedProject';
import {DeleteConfirmation} from './DeleteConfirmation';
import Tippy from '@tippyjs/react';
import {AddConfirmation} from './AddConfirmation';
import styled from 'styled-components';

export const PositionedActionContainer = styled(ActionContainer)`
    position: absolute;
    top: 1rem;
    right: 2rem;
`;

export function ActionControls(
    newProject: boolean,
    changed: boolean,
    notValidToSave: boolean,
    saveChanges: () => Promise<void>,
    project: EditedProject,
    handleDeleteClicked: () => void,
    handleAddClicked: () => Promise<void>,
    message: {show: boolean; success: boolean; message: string},
    showDeleteConfirmation: boolean,
    handleDeleteConfirmed: () => Promise<void>,
    handleDeleteCanceled: () => void,
    potentialDuplicate: {foundPotentialDuplicate: boolean; projectIds: any[]},
    handleAddConfirmed: () => Promise<void>,
    handleAddCanceled: () => void
) {
    return (
        <PositionedActionContainer>
            <ActionList>
                {!newProject ? (
                    <>
                        <SaveChangesButton
                            disabled={!changed || notValidToSave}
                            onClick={saveChanges}>
                            Save Changes
                        </SaveChangesButton>
                        <Tippy
                            disabled={project.Participations.length === 0}
                            placement="bottom"
                            content="All participations must be removed before deleting this project">
                            <div style={{display: 'inline-block'}}>
                                {!project.ControlledByAutomation && (
                                    <DeleteButton
                                        disabled={project.Participations.length > 0}
                                        onClick={handleDeleteClicked}>
                                        Delete
                                    </DeleteButton>
                                )}
                            </div>
                        </Tippy>
                    </>
                ) : (
                    <>
                        <AddButton disabled={notValidToSave} onClick={handleAddClicked}>
                            Add this project
                        </AddButton>
                    </>
                )}
            </ActionList>
            <SaveChangesMessage {...message} />
            {showDeleteConfirmation && (
                <DeleteConfirmation
                    hasParticipations={project.Participations.length > 0}
                    deleteConfirmed={handleDeleteConfirmed}
                    canceled={handleDeleteCanceled}
                />
            )}
            {potentialDuplicate.foundPotentialDuplicate && (
                <AddConfirmation
                    projectIds={potentialDuplicate.projectIds}
                    addConfirmed={handleAddConfirmed}
                    canceled={handleAddCanceled}
                />
            )}
        </PositionedActionContainer>
    );
}
