import {LabelContainer, Label} from '../../../components/Form/FormStyles';
import {EditedPlantingProjectBHCampaign, EditedProject} from '../EditedProject';
import BHCampaignList from './BHCampaignList';
import {RecordEditStatus, deleteStatus} from '../../../record-editing';

export function BeehiveCampaignsControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    function handleAddBHCampaign(campaign: EditedPlantingProjectBHCampaign) {
        const updatedProject = {
            ...project,
            BHCampaigns: [...project.BHCampaigns, {...campaign, EditStatus: RecordEditStatus.New}]
        };
        setProjectAndValidate(updatedProject);
    }

    function handleRemoveBHCampaign(campaign: EditedPlantingProjectBHCampaign) {
        const updatedBHCampaigns = project.BHCampaigns.map((c) =>
            c.CampaignId === campaign.CampaignId
                ? {...c, EditStatus: deleteStatus(c.EditStatus)}
                : c
        );
        const updatedProject = {...project, BHCampaigns: updatedBHCampaigns};
        setProjectAndValidate(updatedProject);
    }

    return (
        <div>
            <LabelContainer>
                <Label>Beehive campaigns</Label>
            </LabelContainer>
            <BHCampaignList
                campaigns={project.BHCampaigns}
                addCampaign={handleAddBHCampaign}
                removeCampaign={handleRemoveBHCampaign}
                projectId={project.Id}
            />
        </div>
    );
}
