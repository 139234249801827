import styled from 'styled-components';

const SpinnerOverlay = styled.div`
    position: fixed; /* Sit on top of the page content */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Black background with opacity */
    z-index: 20; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
`;

const SpinnerContainer = styled.div`
    position: fixed;
    left: 50%;
    top: 40%;
`;

const StyledSpinner = styled.svg`
    animation: rotate 2s linear infinite;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    & .path {
        stroke: #036a36;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`;

const SpinnerIcon = () => (
    <StyledSpinner viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </StyledSpinner>
);

const Spinner = ({showSpinner, children}: {showSpinner: boolean; children?}) => {
    if (!showSpinner) return <div>{children}</div>;

    return (
        <div>
            <SpinnerOverlay>
                <SpinnerContainer>
                    <SpinnerIcon />
                </SpinnerContainer>
            </SpinnerOverlay>
            {children}
        </div>
    );
};

export default Spinner;
