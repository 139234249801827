import {
    FormFieldContainer,
    LabelContainer,
    Label,
    ValidationsList,
    TextControlShowingEdits
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const AgreementNumber = styled(TextControlShowingEdits)`
    max-width: 250px;
`;

export function AgreementNumberControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.TTFAgreementNumberValidated.current}
                        disabled={project.TTFAgreementNumber.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                TTFAgreementNumberValidated:
                                    project.TTFAgreementNumberValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="agreementNumber">Agreement number</Label>
            </LabelContainer>
            <AgreementNumber
                id="agreementNumber"
                disabled={project.ControlledByAutomation}
                maxLength={100}
                value={project.TTFAgreementNumber}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        TTFAgreementNumber: project.TTFAgreementNumber.update(e.target.value)
                    })
                }></AgreementNumber>
            <ValidationsList validations={project.TTFAgreementNumber.validations} />
        </FormFieldContainer>
    );
}
