import {useEffect, useState} from 'react';
import {Outlet, Link, useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {Partner} from '../entities';
import {SearchControl} from '../components/Form/FormStyles';
import {VerticalExpandCollapse} from '../components/ExpandColapse/ExpandCollapse';
import {AddButton} from '../components/Buttons/AddButton';
import Tippy from '@tippyjs/react';
import {UserRoles} from '../providers/UserProvider';
import {
    ValidatedIcon,
    ValidateFilter,
    ValidateFilterValue
} from '../components/Controls/ValidateControl';
import {LiveFilter, LiveFilterValue, LiveSignifier} from '../containers/PartnerLive';
import {ArchivedFilter, ArchivedFilterValue} from '../containers/PartnerArchived';

const PartnersLayout = styled.div<{sidePanelExpanded: boolean}>`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: ${(props) => (props.sidePanelExpanded ? '380px' : '10px')} auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'left-header expand-collapse main'
        'left-content expand-collapse main';
    place-content: stretch;
`;

const LeftHeader = styled.div`
    grid-area: left-header;
    padding: 0 1rem 1rem 1rem;
    background-color: rgb(248, 248, 248);
    position: relative;
`;

const LeftHeaderHeader = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const LeftContent = styled.div`
    grid-area: left-content;
    padding: 0 0.5rem;
    background-color: rgb(248, 248, 248);
    overflow-y: auto;
`;

const PartnersHeader = styled.h3`
    margin-top: 1.1rem;
`;

const MainPanel = styled.div`
    grid-area: main;
`;

const SearchLine = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

const PartnerTable = styled.table`
    margin: 0;
    padding: 0;
`;

const PartnerRow = styled.tr``;

const PartnerNameCell = styled.td`
    vertical-align: top;
    padding: 0.3rem 0;
`;

const EverythingValidatedCell = styled.td`
    vertical-align: top;
    padding: 0.3rem 0;
    width: 20px;
`;

const PositionedExpandCollapse = styled(VerticalExpandCollapse)`
    grid-area: expand-collapse;
`;

function filterPartners(
    partners: Partner[],
    search: string,
    validateFilter: ValidateFilterValue,
    liveFilter: LiveFilterValue,
    ArchivedFilter: ArchivedFilterValue
): Partner[] {
    const searchLower = search.toLowerCase();
    const filteredPartners = partners.filter((p) =>
        filterPartner(p, searchLower, validateFilter, liveFilter, ArchivedFilter)
    );

    return filteredPartners;
}

function filterPartner(
    partner: Partner,
    searchLower: string,
    validateFilter: ValidateFilterValue,
    liveFilter: LiveFilterValue,
    ArchivedFilter: ArchivedFilterValue
): boolean {
    if (
        searchLower &&
        !(
            partner.Id.toString().includes(searchLower) ||
            partner.Name.toLowerCase().includes(searchLower)
        )
    )
        return false;

    switch (validateFilter) {
        case ValidateFilterValue.Validated:
            if (!partner.EverythingValidated) return false;
            break;
        case ValidateFilterValue.Unvalidated:
            if (partner.EverythingValidated) return false;
            break;
    }

    switch (liveFilter) {
        case LiveFilterValue.Live:
            if (!partner.PartnerLive) return false;
            break;
        case LiveFilterValue.NotLive:
            if (partner.PartnerLive) return false;
            break;
    }

    switch (ArchivedFilter) {
        case ArchivedFilterValue.Archived:
            if (!partner.Archived) return false;
            break;
        case ArchivedFilterValue.NotArchived:
            if (partner.Archived) return false;
            break;
    }

    return true;
}

function Partners() {
    const [partners, setPartners] = useState([] as Partner[]);
    const [search, setSearch] = useState('');
    const [validateFilter, setValidateFilter] = useState(ValidateFilterValue.Unset);
    const [liveFilter, setLiveFilter] = useState(LiveFilterValue.Unset);
    const [archivedFilter, setArchivedFilter] = useState(ArchivedFilterValue.NotArchived);
    const [sidePanelExpanded, setSidePanelExpanded] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const refresh = searchParams.get('refresh');

    const getPartners = async () => {
        const result = await (await fetch('/api/partners')).json();
        setPartners(result);
    };

    useEffect(() => {
        getPartners();
    }, [refresh]);

    if (refresh) {
        searchParams.delete('refresh');
        setSearchParams(searchParams);
    }

    const partnerLinkSearchParams = searchParams.entries() ? '?' + searchParams : '';

    const filteredPartners = filterPartners(
        partners,
        search,
        validateFilter,
        liveFilter,
        archivedFilter
    );

    function toggleSidePanel() {
        setSidePanelExpanded(!sidePanelExpanded);
    }

    async function handleRefresh() {
        await getPartners();
    }

    return (
        <PartnersLayout sidePanelExpanded={sidePanelExpanded}>
            {sidePanelExpanded && (
                <>
                    <LeftHeader>
                        <LeftHeaderHeader>
                            <PartnersHeader>Partners</PartnersHeader>
                            {UserRoles().includes('editor') && (
                                <Tippy placement="right" content="Add a partner">
                                    <Link to="new">
                                        <AddButton />
                                    </Link>
                                </Tippy>
                            )}
                        </LeftHeaderHeader>
                        <SearchLine>
                            <SearchControl
                                value={search}
                                onInput={(e) => setSearch((e.target as HTMLInputElement).value)}
                                placeholder="Search"
                            />
                            <ValidateFilter
                                value={validateFilter}
                                onChange={(e) => setValidateFilter(e.value)}
                            />
                            <LiveFilter
                                value={liveFilter}
                                onChange={(value) => {
                                    setLiveFilter(value);
                                }}
                            />
                            <ArchivedFilter
                                value={archivedFilter}
                                onChange={(value) => {
                                    setArchivedFilter(value);
                                }}
                            />
                        </SearchLine>
                    </LeftHeader>
                    <LeftContent>
                        <PartnerTable>
                            <tbody>
                                {filteredPartners.map((partner) => (
                                    <PartnerRow key={partner.Id}>
                                        <EverythingValidatedCell>
                                            {partner.EverythingValidated && <ValidatedIcon />}
                                        </EverythingValidatedCell>
                                        <PartnerNameCell>
                                            <Link
                                                to={
                                                    partner.Id.toString() + partnerLinkSearchParams
                                                }>
                                                {partner.Name}
                                            </Link>
                                            {partner.PartnerLive && (
                                                <LiveSignifier>Live</LiveSignifier>
                                            )}
                                        </PartnerNameCell>
                                    </PartnerRow>
                                ))}
                            </tbody>
                        </PartnerTable>
                    </LeftContent>
                </>
            )}
            <MainPanel>
                <Outlet context={{onRefreshPartners: handleRefresh}} />
            </MainPanel>
            <PositionedExpandCollapse onDoubleClick={toggleSidePanel} />
        </PartnersLayout>
    );
}

export default Partners;
