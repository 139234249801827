import {useState} from 'react';
import styled from 'styled-components';
import {Validation, RecordEditStatus, ValidationLevel} from '../../../record-editing';
import {useConfig} from '../../../providers/ConfigProvider';
import {Button} from '../../../components/Form/FormStyles';
import {RemoveButtonX} from '../../../components/Buttons/RemoveButton';
import {UserRoles} from '../../../providers/UserProvider';
import {EditedCommitment} from '../EditedProject';
import {ValidationsList} from '../../../components/Form/FormStyles';
import {TextControl} from '../../../components/Form/FormStyles';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1rem;
`;

const StyledCommitmentList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const StyledCommitmentListItem = styled.li`
    margin-bottom: 0.5rem;
`;

const CommitmentContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const AddForm = styled.div`
    display: flex;
    gap .5rem;
    align-items: center;
`;

const NewCommitmentIdTextBox = styled(TextControl)`
    width: 200px;
`;

function isValidCommitmentIdFormat(str: string) {
    const regexExp = /^[0-9]{1,9}$/gi;
    return regexExp.test(str);
}

function validateNewCommitment(
    newCommitmentId: string,
    commitments: EditedCommitment[]
): Validation[] {
    const validations = [];

    if (newCommitmentId) {
        const validFormat = isValidCommitmentIdFormat(newCommitmentId);
        if (!validFormat)
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'The format is invalid for a commitment id'
            });

        const newCommitmentIsADuplicate = commitments.find(
            (commitment) =>
                commitment.CommitmentId.toString() === newCommitmentId &&
                commitment.EditStatus !== RecordEditStatus.NewDeleted &&
                commitment.EditStatus !== RecordEditStatus.Deleted
        );
        if (newCommitmentIsADuplicate)
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'This is a duplicate'
            });
    }

    return validations;
}

function CommitmentList({
    commitments,
    addCommitment,
    removeCommitment,
    disabled
}: {
    commitments: EditedCommitment[];
    addCommitment: (commitment: EditedCommitment) => void;
    removeCommitment: (commitment: EditedCommitment) => void;
    disabled: boolean;
}) {
    const config = useConfig();
    const [newCommitmentId, setNewCommitmentId] = useState('');
    const roles = UserRoles();

    function handleNewCommitmentIdChanged(e) {
        setNewCommitmentId(e.target.value);
    }

    function handleAddCommitmentButtonClicked(e) {
        e.preventDefault();

        const commitment = {
            EditStatus: RecordEditStatus.New,
            Id: -1,
            CommitmentId: parseInt(newCommitmentId)
        };

        setNewCommitmentId(null);
        addCommitment(commitment);
    }

    function handleRemoveButtonClicked(commitment: EditedCommitment) {
        removeCommitment(commitment);
    }

    const newCommitmentValidations = validateNewCommitment(newCommitmentId, commitments);

    const nonDeletedCommitments = commitments.filter(
        (account) =>
            account.EditStatus !== RecordEditStatus.NewDeleted &&
            account.EditStatus !== RecordEditStatus.Deleted
    );

    return (
        <Layout>
            <StyledCommitmentList>
                {nonDeletedCommitments.map((commitment) => (
                    <StyledCommitmentListItem key={commitment.CommitmentId}>
                        <CommitmentContainer>
                            {roles.includes('editor') && !disabled && (
                                <RemoveButtonX
                                    onClick={() => handleRemoveButtonClicked(commitment)}
                                />
                            )}
                            <a
                                href={`${config.treeHarmonyBaseUrl}/Commitments/Detail/${commitment.CommitmentId}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                {commitment.CommitmentId}
                            </a>
                        </CommitmentContainer>
                    </StyledCommitmentListItem>
                ))}
            </StyledCommitmentList>
            {nonDeletedCommitments.length === 0 && (
                <>
                    <AddForm>
                        <NewCommitmentIdTextBox
                            value={newCommitmentId}
                            onChange={handleNewCommitmentIdChanged}
                        />
                        {roles.includes('editor') && (
                            <Button
                                type="submit"
                                disabled={!newCommitmentId || newCommitmentValidations.length > 0}
                                onClick={handleAddCommitmentButtonClicked}>
                                Add commitment
                            </Button>
                        )}
                    </AddForm>
                    <ValidationsList validations={newCommitmentValidations} />
                </>
            )}
        </Layout>
    );
}

export default CommitmentList;
