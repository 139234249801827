import {Filter} from '../../ParticipationFunctions/Filtering';
import {EditedParticipation} from '../../../Partner/EditedPartner';
import {ParticipationUpdater} from '../../ParticipationFunctions/ParticipationModifications';
import {
    offsetOneRow,
    offsetTwoRows,
    StateStyledNumberCell
} from '../../Styles/ParticipationTableStyles';
import {CellWithValidator, StickyHeader} from '../../../../components/Table/TableStyles';
import {
    ValidatedIcon,
    ValidateFilter,
    ValidateFilterValue
} from '../../../../components/Controls/ValidateControl';
import {
    ComplexInputWithAsyncUpdate,
    NumericFilter,
    ValidationsList
} from '../../../../components/Form/FormStyles';
import {
    AlignToTextBoxContent,
    DollarsControl,
    ValidationTippy,
    ValidationTippyContent
} from '../../Styles/ParticipationRowStyles';
import {getNumberFromInput} from '../../../../record-editing';

export function VerifiedCarbonCreditsHeader() {
    return (
        <StickyHeader
            style={{
                ...offsetOneRow,
                width: '150px',
                minWidth: '150px',
                maxWidth: '150px'
            }}>
            Verified Carbon Credits
        </StickyHeader>
    );
}

export function VerifiedCarbonCreditsFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <StickyHeader style={{...offsetTwoRows}}>
            <CellWithValidator>
                <ValidateFilter
                    value={filter?.CarbonTransactionsValidated ?? ValidateFilterValue.Unset}
                    onChange={(e) =>
                        updateFilter({
                            ...filter,
                            CarbonTransactionsValidated: e.value
                        })
                    }
                />
                <NumericFilter
                    value={filter?.CarbonCreditTransactions ?? ''}
                    onChange={(e) =>
                        updateFilter({
                            ...filter,
                            CarbonCreditTransactions: e.target.value
                        })
                    }
                />
            </CellWithValidator>
        </StickyHeader>
    );
}

export function VerifiedCarbonCreditsContent({
    ppn,
    updater
}: {
    ppn: EditedParticipation;
    updater: ParticipationUpdater;
}) {
    return (
        <StateStyledNumberCell editStatus={ppn.EditStatus}>
            <CellWithValidator>
                {ppn.ControlledByAutomation ? (
                    <ValidatedIcon />
                ) : (
                    <>
                        <ValidatedIcon />
                        <ValidationTippy
                            content={
                                <ValidationTippyContent>
                                    <ValidationsList
                                        validations={ppn.CarbonCreditsTransacted.validations}
                                    />
                                </ValidationTippyContent>
                            }
                            disabled={ppn.CarbonCreditsTransacted.validations.length === 0}>
                            <div>
                                <ComplexInputWithAsyncUpdate
                                    Control={DollarsControl}
                                    showModification
                                    value={ppn.CarbonCreditsTransacted}
                                    onChange={(updatedValue) =>
                                        updater.update({
                                            ...ppn,
                                            CarbonCreditsTransacted: updatedValue
                                        })
                                    }
                                    valueGetter={(e) =>
                                        ppn.CarbonCreditsTransacted.update(getNumberFromInput(e))
                                    }
                                />
                            </div>
                        </ValidationTippy>
                    </>
                )}
            </CellWithValidator>
        </StateStyledNumberCell>
    );
}
