
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import { CellWithValidator, StickyNumericHeader } from "../../../../components/Table/TableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { NumericFilter } from "../../../../components/Form/FormStyles";

export function TTFDBHHeader() {
	return (
		<StickyNumericHeader
			style={{ ...offsetOneRow, width: '120px', minWidth: '120px', maxWidth: '120px' }}>
			TTF DBH
		</StickyNumericHeader>
	)
}

export function TTFDBHFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.TTFDBHValidated ?? ValidateFilterValue.Unset}
					onChange={(e) => updateFilter({ ...filter, TTFDBHValidated: e.value })}
				/>
				<NumericFilter
					value={filter?.TTFDBH ?? ''}
					onChange={(e) => updateFilter({ ...filter, TTFDBH: e.target.value })}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function TTFDBHContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledNumberCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>{ppn.PlantingProject.TTFDBHValidated && <ValidatedIcon />}</span>
				<span>{ppn.PlantingProject.TTFDBH}</span>
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}