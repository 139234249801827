import {PlantingProject, PlantingProjectType, Partner as PartnerEntity} from '../../entities';
import {EditedField, RecordEditStatus, ValidationLevel} from '../../record-editing';
import {decimalPlaces} from '../../validations';

export type EditedPartner = {
    EditStatus: RecordEditStatus;
    Id: number;
    Name: EditedField<string>;
    LogoDAMAssetId: EditedField<string>;
    PartnerStatementTemplate: EditedField<string>;
    PartnerStatement: EditedField<string>;
    PartnerLive: EditedField<boolean>;
    CreatedByAutomation: boolean;
    Archived: EditedField<boolean>;
    ArcGISUsername: EditedField<string>;
    ArcGISPassword: EditedField<string>;

    Participations: EditedParticipation[];
    D365Accounts: EditedD365Account[];
    NavCustomers: EditedNavCustomer[];
};

export type EditedParticipation = {
    EditStatus: RecordEditStatus;
    Id: number;
    PartnerId: number;
    PlantingProjectId: EditedField<number>;
    FundedYear: EditedField<number>;
    FundedYearValidated: EditedField<boolean>;
    FundingSource: EditedField<string>;
    DollarsDonated: EditedField<number>;
    DollarsDonatedValidated: EditedField<boolean>;
    TTFTrees: EditedField<number>;
    TTFTreesValidated: EditedField<boolean>;
    AcresRestored: EditedField<number>;
    CommunityTreesPlanted: EditedField<number>;
    CommunityTreesPlantedValidated: EditedField<boolean>;
    CommunityTreesDistributed: EditedField<number>;
    CommunityTreesDistributedValidated: EditedField<boolean>;
    CommunityBudgetPassThrough: EditedField<number>;
    CommunityBudgetPassThroughValidated: EditedField<boolean>;
    Excluded: EditedField<boolean>;
    EverythingValidated: boolean;
    WasEverReadyToPublish: boolean;
    ReadyToPublish: boolean;
    ShowOnDate: EditedField<string>;
    PlantingProject: PlantingProject;
    CarbonCreditsTransacted: EditedField<number>;
    VintageYear: EditedField<string>;
    SerialNumber: EditedField<string>;
    ParticipantRegistryLink: EditedField<string>;
    CarbonPurchaseAmount: EditedField<number>;
    DocumentCount: number;
    ControlledByAutomation: boolean;
    D365RevenuePostingInvoiceId: string;
    isOrderProduct: boolean;
    THLiabilityId: number;
    TTFForestName: string;
    ProjectType: PlantingProjectType;
};

export type EditedD365Account = {
    EditStatus: RecordEditStatus;
    AccountId: string;
};

export type EditedNavCustomer = {
    EditStatus: RecordEditStatus;
    CustomerId: string;
};

export function InitializeEditedPartner(partner: PartnerEntity): EditedPartner {
    return {
        EditStatus: RecordEditStatus.Existing,
        Id: partner.Id,
        Name: new EditedField(partner.Name),
        LogoDAMAssetId: new EditedField(partner.LogoDAMAssetId),
        PartnerStatementTemplate: new EditedField(partner.PartnerStatementTemplate),
        PartnerStatement: new EditedField(partner.PartnerStatement),
        PartnerLive: new EditedField(partner.PartnerLive),
        CreatedByAutomation: partner.CreatedByAutomation,
        Archived: new EditedField(partner.Archived),
        ArcGISUsername: new EditedField(partner.ArcGISUsername),
        ArcGISPassword: new EditedField(partner.ArcGISPassword),
        Participations: (partner.Participations ?? []).map((ppn) => ({
            EditStatus: RecordEditStatus.Existing,
            Id: ppn.Id,
            PartnerId: ppn.PartnerId,
            PlantingProjectId: new EditedField(ppn.PlantingProjectId),
            FundedYear: new EditedField(ppn.FundedYear),
            FundedYearValidated: new EditedField(ppn.FundedYearValidated),
            FundingSource: new EditedField(ppn.FundingSource),
            DollarsDonated: new EditedField(ppn.DollarsDonated),
            DollarsDonatedValidated: new EditedField(ppn.DollarsDonatedValidated),
            TTFTrees: new EditedField(ppn.TTFTrees),
            TTFTreesValidated: new EditedField(ppn.TTFTreesValidated),
            AcresRestored: new EditedField(ppn.AcresRestored),
            CommunityTreesPlanted: new EditedField(ppn.CommunityTreesPlanted),
            CommunityTreesPlantedValidated: new EditedField(ppn.CommunityTreesPlantedValidated),
            CommunityTreesDistributed: new EditedField(ppn.CommunityTreesDistributed),
            CommunityTreesDistributedValidated: new EditedField(
                ppn.CommunityTreesDistributedValidated
            ),
            CommunityBudgetPassThrough: new EditedField(ppn.CommunityBudgetPassThrough),
            CommunityBudgetPassThroughValidated: new EditedField(
                ppn.CommunityBudgetPassThroughValidated
            ),
            Excluded: new EditedField(ppn.Excluded),
            EverythingValidated: ppn.EverythingValidated,
            WasEverReadyToPublish: ppn.WasEverReadyToPublish,
            ReadyToPublish: ppn.ReadyToPublish,
            PlantingProject: ppn.PlantingProject,
            ShowOnDate: new EditedField(ppn.ShowOnDate),
            CarbonPurchaseAmount: new EditedField(ppn.CarbonPurchaseAmount),
            CarbonCreditsTransacted: new EditedField(ppn.CarbonCreditsTransacted),
            SerialNumber: new EditedField(ppn.SerialNumber),
            ParticipantRegistryLink: new EditedField(ppn.ParticipantRegistryLink),
            VintageYear: new EditedField(ppn.VintageYear),
            DocumentCount: ppn.DocumentCount,
            ControlledByAutomation: ppn.ControlledByAutomation,
            D365RevenuePostingInvoiceId: ppn.D365RevenuePostingInvoiceId,
            THLiabilityId: ppn.THLiabilityId,
            TTFForestName: ppn.TTFForestName,
            ProjectType: ppn.ProjectType,
            isOrderProduct: ppn.isOrderProduct
        })),
        D365Accounts: (partner.D365Accounts ?? []).map((da) => ({
            ...da,
            EditStatus: RecordEditStatus.Existing
        })),
        NavCustomers: (partner.NavCustomers ?? []).map((nc) => ({
            ...nc,
            EditStatus: RecordEditStatus.Existing
        }))
    };
}

export function InitializeNewPartner(): EditedPartner {
    const partner = {
        EditStatus: RecordEditStatus.New,
        Id: -1,
        Name: new EditedField(''),
        NameValidated: new EditedField(false),
        LogoDAMAssetId: new EditedField(''),
        PartnerStatementTemplate: new EditedField(
            '{{Name}}’s impact with the Arbor Day Foundation is measurable and lasting. Trees and forests are a proven solution to addressing some of the most pressing issues facing people and our planet. These challenges include climate change, water quality and quantity, biodiversity health, human health and environmental inequalities. This tool aims to showcase the impact that {{Name}} and the Arbor Day Foundation have had together to address these issues. We are immensely grateful for the opportunity to collaborate to make a positive impact together.'
        ),
        PartnerStatement: new EditedField(
            '’s impact with the Arbor Day Foundation is measurable and lasting. Trees and forests are a proven solution to addressing some of the most pressing issues facing people and our planet. These challenges include climate change, water quality and quantity, biodiversity health, human health and environmental inequalities. This tool aims to showcase the impact that  and the Arbor Day Foundation have had together to address these issues. We are immensely grateful for the opportunity to collaborate to make a positive impact together.'
        ),
        PartnerStatementValidated: new EditedField(false),
        PartnerLive: new EditedField(false),
        CreatedByAutomation: false,
        Archived: new EditedField(false),
        ArcGISUsername: new EditedField<string>(null),
        ArcGISPassword: new EditedField<string>(null),
        Participations: [],
        D365Accounts: [],
        NavCustomers: []
    } as EditedPartner;

    setValidations(partner);

    return partner;
}

export function getNotValidToSave(partner: EditedPartner) {
    if (!partner) return false;

    if (partner.Name.notValidToSave) return true;
    if (partner.LogoDAMAssetId.notValidToSave) return true;
    if (partner.PartnerStatementTemplate.notValidToSave === null) return true;
    if (partner.ArcGISUsername.notValidToSave) return true;
    if (partner.ArcGISPassword.notValidToSave) return true;

    for (const ppn of partner.Participations) {
        if (
            ppn.EditStatus === RecordEditStatus.Deleted ||
            ppn.EditStatus === RecordEditStatus.NewDeleted
        )
            continue;

        if (ppn.FundedYear.notValidToSave) return true;
        if (ppn.TTFTrees.notValidToSave) return true;
        if (ppn.CommunityTreesPlanted.notValidToSave) return true;
        if (ppn.CommunityTreesDistributed.notValidToSave) return true;
        if (ppn.CommunityBudgetPassThrough.notValidToSave) return true;
        if (ppn.DollarsDonated.notValidToSave) return true;
        if (ppn.ShowOnDate.notValidToSave) return true;
    }

    return false;
}

export function setValidations(partner: EditedPartner) {
    if (!partner) return;

    partner.Name.validations = [];
    if (!partner.Name.current)
        partner.Name.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Name is required to save changes'
        });

    partner.LogoDAMAssetId.validations = [];
    if (!partner.LogoDAMAssetId.current)
        partner.LogoDAMAssetId.validations.push({
            level: ValidationLevel.Warning,
            message: 'A logo is not required, but is desired'
        });

    partner.PartnerStatementTemplate.validations = [];
    if (!partner.PartnerStatementTemplate.current)
        partner.PartnerStatementTemplate.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Partner statement is required for display to partners'
        });

    for (const ppn of partner.Participations) {
        if (
            ppn.EditStatus === RecordEditStatus.Deleted ||
            ppn.EditStatus === RecordEditStatus.NewDeleted
        )
            continue;

        ppn.FundedYear.validations = [];
        if (!ppn.FundedYear.current)
            ppn.FundedYear.validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'Funded year is required to save changes'
            });
        else {
            if (
                ppn.PlantingProjectId.current &&
                ppn.PlantingProject.ProjectType !== PlantingProjectType.Reforestation &&
                ppn.PlantingProject.ProjectType !== PlantingProjectType.Carbon &&
                ppn.FundedYear.current &&
                partner.Participations.find(
                    (ppn2) =>
                        ppn2.Id !== ppn.Id &&
                        ppn2.PlantingProjectId.current === ppn.PlantingProjectId.current &&
                        ppn2.FundedYear.current === ppn.FundedYear.current
                )
            )
                ppn.FundedYear.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: 'There are two participations with the same funded year and project'
                });
        }

        ppn.TTFTrees.validations = [];
        if (
            ppn.PlantingProject.ProjectType === PlantingProjectType.Reforestation &&
            ppn.TTFTrees.current === null
        )
            ppn.TTFTrees.validations.push({
                level: ValidationLevel.NotValidToShowPartner,
                message: 'Reforestation trees is required to show to the partner'
            });
        if (ppn.TTFTrees.current) {
            if (ppn.TTFTrees.current < 0)
                ppn.TTFTrees.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "Reforestation trees can't be negative"
                });
            if (decimalPlaces(ppn.TTFTrees.current) > 0)
                ppn.TTFTrees.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: 'Reforestation trees must be a whole number'
                });
        }

        ppn.CommunityTreesPlanted.validations = [];
        if (
            ppn.PlantingProject.ProjectType === PlantingProjectType.CommunityEvent &&
            ppn.CommunityTreesPlanted.current === null
        )
            ppn.CommunityTreesPlanted.validations.push({
                level: ValidationLevel.NotValidToShowPartner,
                message: 'Community trees planted is required to show to the partner'
            });
        if (ppn.CommunityTreesPlanted.current) {
            if (ppn.CommunityTreesPlanted.current < 0)
                ppn.CommunityTreesPlanted.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "Community trees planted can't be negative"
                });
            if (decimalPlaces(ppn.CommunityTreesPlanted.current) > 0)
                ppn.CommunityTreesPlanted.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: 'Community trees planted must be a whole number'
                });
        }

        ppn.CommunityTreesDistributed.validations = [];
        if (
            ppn.PlantingProject.ProjectType === PlantingProjectType.CommunityEvent &&
            ppn.CommunityTreesDistributed.current === null
        )
            ppn.CommunityTreesDistributed.validations.push({
                level: ValidationLevel.NotValidToShowPartner,
                message: 'Community trees distributed is required to show to the partner'
            });
        if (ppn.CommunityTreesDistributed.current) {
            if (ppn.CommunityTreesDistributed.current < 0)
                ppn.CommunityTreesDistributed.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "Community trees distributed can't be negative"
                });
            if (decimalPlaces(ppn.CommunityTreesDistributed.current) > 0)
                ppn.CommunityTreesDistributed.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: 'Community trees distributed must be a whole number'
                });
        }

        ppn.DollarsDonated.validations = [];
        if (ppn.DollarsDonated.current) {
            if (ppn.DollarsDonated.current < 0)
                ppn.DollarsDonated.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "Dollars donated can't be negative"
                });
            if (decimalPlaces(ppn.DollarsDonated.current) > 2)
                ppn.DollarsDonated.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "Dollars donated can't have fractions of a cent"
                });
        }

        ppn.CommunityBudgetPassThrough.validations = [];
        if (ppn.CommunityBudgetPassThrough.current) {
            if (ppn.CommunityBudgetPassThrough.current < 0)
                ppn.CommunityBudgetPassThrough.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "Budget pass through can't be negative"
                });
            if (decimalPlaces(ppn.CommunityBudgetPassThrough.current) > 2)
                ppn.CommunityBudgetPassThrough.validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "Budget pass through can't have fractions of a cent"
                });
        }

        if (ppn.FundedYear.notValidToShowPartner)
            ppn.FundedYearValidated = ppn.FundedYearValidated.update(false);
        if (ppn.TTFTrees.notValidToShowPartner)
            ppn.TTFTreesValidated = ppn.TTFTreesValidated.update(false);
        if (ppn.CommunityTreesPlanted.notValidToShowPartner)
            ppn.CommunityTreesPlantedValidated = ppn.CommunityTreesPlantedValidated.update(false);
        if (ppn.CommunityTreesDistributed.notValidToShowPartner)
            ppn.CommunityTreesDistributedValidated =
                ppn.CommunityTreesDistributedValidated.update(false);
        if (ppn.DollarsDonated.notValidToShowPartner)
            ppn.DollarsDonatedValidated = ppn.DollarsDonatedValidated.update(false);
    }
}

export function setCalculatedFields(partner: EditedPartner) {
    if (!partner) return;

    for (const ppn of partner.Participations) {
        switch (ppn.PlantingProject.ProjectType) {
            case PlantingProjectType.Reforestation:
                ppn.CommunityTreesPlantedValidated =
                    ppn.CommunityTreesPlantedValidated.update(true);
                ppn.CommunityTreesDistributedValidated =
                    ppn.CommunityTreesDistributedValidated.update(true);
                ppn.CommunityBudgetPassThroughValidated =
                    ppn.CommunityBudgetPassThroughValidated.update(true);
                break;
            case PlantingProjectType.CommunityEvent:
                ppn.TTFTreesValidated = ppn.TTFTreesValidated.update(true);
                break;
            case PlantingProjectType.Carbon:
                ppn.TTFTreesValidated = ppn.TTFTreesValidated.update(true);
                ppn.CommunityTreesPlantedValidated =
                    ppn.CommunityTreesPlantedValidated.update(true);
                ppn.CommunityTreesDistributedValidated =
                    ppn.CommunityTreesDistributedValidated.update(true);
                ppn.CommunityBudgetPassThroughValidated =
                    ppn.CommunityBudgetPassThroughValidated.update(true);
                ppn.DollarsDonatedValidated = ppn.DollarsDonatedValidated.update(true);
                ppn.FundedYearValidated = ppn.FundedYearValidated.update(true);
                break;
            case PlantingProjectType.CommunityCanopy:
                ppn.TTFTreesValidated = ppn.TTFTreesValidated.update(true);
                ppn.CommunityTreesPlantedValidated =
                    ppn.CommunityTreesPlantedValidated.update(true);
                ppn.CommunityTreesDistributedValidated =
                    ppn.CommunityTreesDistributedValidated.update(true);
                ppn.CommunityBudgetPassThroughValidated =
                    ppn.CommunityBudgetPassThroughValidated.update(true);
                ppn.FundedYearValidated = ppn.FundedYearValidated.update(true);
                ppn.DollarsDonatedValidated = ppn.DollarsDonatedValidated.update(true);

                break;
        }
    }
}

export function setOverallValidatedFlag(partner: EditedPartner) {
    for (const ppn of partner.Participations) {
        ppn.EverythingValidated =
            ppn.PlantingProject.EverythingValidated &&
            ppn.FundedYearValidated.current &&
            ppn.TTFTreesValidated.current &&
            ppn.CommunityTreesPlantedValidated.current &&
            ppn.CommunityTreesDistributedValidated.current &&
            ppn.DollarsDonatedValidated.current &&
            ppn.CommunityBudgetPassThroughValidated.current;
    }
}

export function setPublishedFlag(partner: EditedPartner) {
    for (const ppn of partner.Participations) {
        ppn.ReadyToPublish =
            (ppn.WasEverReadyToPublish || ppn.EverythingValidated) && !ppn.Excluded.current;
    }
}

export function updatePartnerWithParticipation(
    partner: EditedPartner,
    participation: EditedParticipation
): EditedPartner {
    return {
        ...partner,
        Participations: partner.Participations.map((ppn) =>
            ppn.Id === participation.Id ? participation : ppn
        )
    };
}

export function projectTypeLabel(projectType: PlantingProjectType) {
    switch (projectType) {
        case PlantingProjectType.Reforestation:
            return 'Reforestation';
        case PlantingProjectType.CommunityEvent:
            return 'Community';
        case PlantingProjectType.Carbon:
            return 'Carbon Credit';
        case PlantingProjectType.CommunityCanopy:
            return 'Community Canopy';
    }
}
