import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ImagesValidatedHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '50px',
				minWidth: '50px',
				maxWidth: '50px'
			}}>
			Val
		</StickyHeader>
	)
}

export function ImagesValidatedFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<ValidateFilter
				value={filter?.ImagesValidated ?? ValidateFilterValue.Unset}
				onChange={(e) => updateFilter({ ...filter, ImagesValidated: e.value })}
			/>
		</StickyHeader>
	)
}

export function ImagesValidatedContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>{ppn.PlantingProject.ImagesValidated && <ValidatedIcon />}</span>
				<span></span>
			</CellWithValidator>
		</StateStyledCell>
	)
}