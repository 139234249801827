import {
    FormFieldContainer,
    LabelContainer,
    Label,
    ValidationsList,
    NumberControlShowingEdits
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const TotalTreeCost = styled(NumberControlShowingEdits)`
    max-width: 170px;
`;

export function TotalTreeCostControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.TTFTotalTreeCostValidated.current}
                        disabled={project.TTFTotalTreeCost.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                TTFTotalTreeCostValidated: project.TTFTotalTreeCostValidated.update(
                                    e.validated
                                )
                            })
                        }
                    />
                )}
                <Label htmlFor="totalTreeCost">Total tree cost</Label>
            </LabelContainer>
            <TotalTreeCost
                id="totalTreeCost"
                disabled={project.ControlledByAutomation}
                value={project.TTFTotalTreeCost}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        TTFTotalTreeCost: project.TTFTotalTreeCost.update(e.target.value)
                    })
                }></TotalTreeCost>
            <ValidationsList validations={project.TTFTotalTreeCost.validations} />
        </FormFieldContainer>
    );
}
