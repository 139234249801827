import {
    FormFieldContainer,
    LabelContainer,
    Label,
    ValidationsList,
    TextControlShowingEdits
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const CommitmentFiscalYear = styled(TextControlShowingEdits)`
    max-width: 200px;
`;

export function CommitmentFiscalYearControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.TTFCommitmentFiscalYearValidated.current}
                        disabled={project.TTFCommitmentFiscalYear.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                TTFCommitmentFiscalYearValidated:
                                    project.TTFCommitmentFiscalYearValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="fiscalYear">Commitment fiscal year</Label>
            </LabelContainer>
            <CommitmentFiscalYear
                id="fiscalYear"
                disabled={project.ControlledByAutomation}
                maxLength={7}
                value={project.TTFCommitmentFiscalYear}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        TTFCommitmentFiscalYear: project.TTFCommitmentFiscalYear.update(
                            e.target.value
                        )
                    })
                }></CommitmentFiscalYear>
            <ValidationsList validations={project.TTFCommitmentFiscalYear.validations} />
        </FormFieldContainer>
    );
}
