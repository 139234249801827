import styled, {css} from 'styled-components';
import {
    LabelContainer,
    PropertyName,
    ReadOnlyProperty,
    SearchControl,
    TextAreaControlShowingEdits,
    ValidationsList
} from '../../../components/Form/FormStyles';
import {EditedField, RecordEditStatus} from '../../../record-editing';
import {RemoveIcon} from '../../../components/Buttons/RemoveButton';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {TreeSpecies} from '../../../entities';
import {EditedProject, EditedPlantingProjectTreeSpecies} from '../EditedProject';
import {useEffect, useState} from 'react';

export const TreeSpeciesLayout = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
        'existing'
        'header'
        'selected'
        'search'
        'validations';
    row-gap: 0.5rem;
    column-gap: 3rem;
`;

export const TreeSpeciesExistingContainer = styled.div`
    grid-area: existing;
`;

export const TreeSpeciesHeaderContainer = styled.div`
    grid-area: header;
`;

export const TreeSpeciesSelectedHeader = styled.h4`
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
`;

export const TreeSpeciesSelectedContainer = styled.ul`
    grid-area: selected;
    display: flex;
    flex-wrap: wrap;
    gap: 0.35rem;
    margin: 0;
    padding: 0;
`;

export const SelectedTreeSpecies = styled.li<{editStatus?: RecordEditStatus}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;

    ${(props) =>
        (props.editStatus === RecordEditStatus.Deleted ||
            props.editStatus === RecordEditStatus.NewDeleted) &&
        css`
            display: none;
        `}
`;

export const RemoveTreeSpeciesButton = styled.button.attrs(() => ({
    children: <RemoveIcon />,
    title: 'Remove',
    type: 'button'
}))`
    margin: 0;
    margin-top: 2px;
    padding: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    color: red;
    cursor: pointer;
`;

export const TreeSpeciesSearchContainer = styled.div`
    grid-area: search;
    position: relative;
    max-width: 350px;
`;

export const TreeSpeciesSearchResultsContainer = styled.div`
    position: absolute;
    max-height: 205px;
    width: 100%;
    padding: 0.4rem 0.8rem;
    border: 1px solid rgb(220, 220, 220);
    overflow-y: auto;
    z-index: 1;
    background-color: white;

    &:empty {
        display: none;
    }
`;

export const TreeSpeciesSearchResult = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.25rem;

    &:focus,
    &:hover {
        background-color: rgb(220, 220, 220);
        box-shadow: none;
        outline: none;
    }
`;

export const OtherTreeSpecies = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.5rem;
`;

export const TreeSpeciesName = styled.div`
    white-space: nowrap;
`;

export const OtherTreeSpeciesName = styled(TextAreaControlShowingEdits)`
    min-width: 30rem;
    padding: 0.1rem 0.5rem;
`;

export function TreeSpeciesControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [treeSpecies, setTreeSpecies] = useState<TreeSpecies[]>(null);
    const [treeSpeciesSearch, setTreeSpeciesSearch] = useState('');
    const [nextTreeSpeciesId, setNextTreeSpeciesId] = useState(-1);
    const [focusOnLastTreeSpecies, setFocusOnLastTreeSpecies] = useState(false);

    const getTreeSpecies = async () => {
        const result = await (await fetch(`/api/tree-species`)).json();
        setTreeSpecies(result);
    };

    useEffect(() => {
        getTreeSpecies();
    }, []);

    function handleTreeSpeciesSearchOnKeyDown(e) {
        const index = parseInt(e.target.dataset.index);
        switch (e.keyCode) {
            case 38: // up
                if (index === 0)
                    e.target.parentElement.parentElement
                        .querySelector(`[data-index='${index - 1}']`)
                        ?.focus();
                else e.target.parentElement.querySelector(`[data-index='${index - 1}']`)?.focus();
                e.preventDefault();
                break;
            case 40: // down
                e.target.parentElement.querySelector(`[data-index='${index + 1}']`)?.focus();
                e.preventDefault();
                break;
            case 27: // escape
                setTreeSpeciesSearch('');
                if (index >= 0)
                    e.target.parentElement.parentElement
                        .querySelector(`[data-index='${-1}']`)
                        ?.focus();
                e.preventDefault();
                break;
            case 13: // enter
                if (index >= 0) {
                    const code = e.target.dataset.code;
                    addTreeSpecies(code);
                    setTreeSpeciesSearch('');
                    e.target.parentElement.parentElement
                        .querySelector(`[data-index='${-1}']`)
                        ?.focus();
                    e.preventDefault();
                }
                break;
        }
    }

    function handleTreeSpeciesResultClick(code) {
        addTreeSpecies(code);
        setTreeSpeciesSearch('');
    }

    function addTreeSpecies(code) {
        const species = treeSpecies.find((ts) => ts.Code === code);
        setProjectAndValidate({
            ...project,
            TreeSpecies: [
                ...project.TreeSpecies,
                {
                    EditStatus: RecordEditStatus.New,
                    Id: nextTreeSpeciesId,
                    Species: species,
                    OtherName: new EditedField<string>(null)
                }
            ]
        });
        setNextTreeSpeciesId(nextTreeSpeciesId - 1);
        setFocusOnLastTreeSpecies(true);
    }

    function removeTreeSpecies(id) {
        setProjectAndValidate({
            ...project,
            TreeSpecies: project.TreeSpecies.map((ts) =>
                ts.Id === id
                    ? {
                          ...ts,
                          EditStatus:
                              ts.EditStatus === RecordEditStatus.Existing
                                  ? RecordEditStatus.Deleted
                                  : RecordEditStatus.NewDeleted
                      }
                    : ts
            )
        });
    }

    function updateTreeSpecies(treeSpecies: EditedPlantingProjectTreeSpecies) {
        setProjectAndValidate({
            ...project,
            TreeSpecies: project.TreeSpecies.map((ts) =>
                ts.Id === treeSpecies.Id ? treeSpecies : ts
            )
        });
    }

    const filteredTreeSpecies =
        treeSpeciesSearch === '' || treeSpecies === null
            ? []
            : treeSpecies
                  .filter((ts) => ts.IsValid)
                  .filter((ts) =>
                      ts.CommonName.toLowerCase().includes(treeSpeciesSearch.toLowerCase())
                  )
                  .slice(0, 300);

    return (
        <TreeSpeciesLayout>
            <TreeSpeciesExistingContainer>
                <ReadOnlyProperty>
                    <PropertyName>Initially loaded tree species: </PropertyName>
                    {project.TreeSpeciesPlanted.current}
                </ReadOnlyProperty>
            </TreeSpeciesExistingContainer>
            <TreeSpeciesHeaderContainer>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.TreeSpeciesValidated.current}
                            disabled={project.TreeSpeciesPlanted.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    TreeSpeciesValidated: project.TreeSpeciesValidated.update(
                                        e.validated
                                    )
                                })
                            }
                        />
                    )}
                    <TreeSpeciesSelectedHeader>Cleaned up tree species</TreeSpeciesSelectedHeader>
                </LabelContainer>
            </TreeSpeciesHeaderContainer>
            <TreeSpeciesSelectedContainer>
                {project.TreeSpecies.map((ts, index) => (
                    <div key={index} style={{display: 'flex'}}>
                        {ts.Species.Code === 'OTHER' ? (
                            <div style={{alignSelf: 'flex-end'}}>
                                <SelectedTreeSpecies key={ts.Id} editStatus={ts.EditStatus}>
                                    <OtherTreeSpecies>
                                        <span>Other: </span>
                                        <OtherTreeSpeciesName
                                            autoFocus={focusOnLastTreeSpecies}
                                            value={ts.OtherName}
                                            onChange={(e) =>
                                                updateTreeSpecies({
                                                    ...ts,
                                                    OtherName: ts.OtherName.update(e.target.value)
                                                })
                                            }
                                        />
                                    </OtherTreeSpecies>
                                    {!project.ControlledByAutomation && (
                                        <RemoveTreeSpeciesButton
                                            onClick={(e) => removeTreeSpecies(ts.Id)}
                                        />
                                    )}
                                </SelectedTreeSpecies>
                            </div>
                        ) : (
                            <div style={{alignSelf: 'flex-start'}}>
                                <SelectedTreeSpecies key={ts.Id} editStatus={ts.EditStatus}>
                                    <TreeSpeciesName>{ts.Species.CommonName}</TreeSpeciesName>
                                    {!project.ControlledByAutomation && (
                                        <RemoveTreeSpeciesButton
                                            onClick={(e) => removeTreeSpecies(ts.Id)}
                                        />
                                    )}
                                </SelectedTreeSpecies>
                            </div>
                        )}
                    </div>
                ))}
            </TreeSpeciesSelectedContainer>
            {!project.ControlledByAutomation && (
                <TreeSpeciesSearchContainer>
                    <SearchControl
                        value={treeSpeciesSearch}
                        onChange={(e) => setTreeSpeciesSearch(e.target.value)}
                        data-index={-1}
                        onKeyDown={handleTreeSpeciesSearchOnKeyDown}
                    />
                    <TreeSpeciesSearchResultsContainer>
                        {filteredTreeSpecies.map((ts, index) => (
                            <TreeSpeciesSearchResult
                                data-index={index}
                                data-code={ts.Code}
                                key={ts.Code}
                                tabIndex={0}
                                onKeyDown={handleTreeSpeciesSearchOnKeyDown}
                                onClick={() => handleTreeSpeciesResultClick(ts.Code)}>
                                <div>{ts.CommonName}</div>
                            </TreeSpeciesSearchResult>
                        ))}
                    </TreeSpeciesSearchResultsContainer>
                </TreeSpeciesSearchContainer>
            )}
            <ValidationsList validations={project.TreeSpeciesPlanted.validations} />
        </TreeSpeciesLayout>
    );
}
