import {PlantingProjectType} from '../../../entities';
import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    RequiredToShowToPartnerSignifier,
    NumberControlShowingEdits,
    ValidationsList,
    FormFieldContainer
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const CalendarYearBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const CalendarYearsContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const CalendarYearContainer = styled(FormFieldContainer)`
    & > input {
        max-width: 100px;
    }
`;

export function ProjectYearsControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <>
            <CalendarYearBlock>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.ProjectYearsValidated.current}
                            disabled={
                                project.ProjectStartYear.notValidToShowPartner ||
                                project.ProjectEndYear.notValidToShowPartner
                            }
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    ProjectYearsValidated: project.ProjectYearsValidated.update(
                                        e.validated
                                    )
                                })
                            }
                        />
                    )}
                    Project years
                </LabelContainer>
                <CalendarYearsContainer>
                    <CalendarYearContainer>
                        <LabelContainer>
                            <Label htmlFor="calendarYearStart">Start</Label>
                            {project.ProjectType.current === PlantingProjectType.Reforestation && (
                                <RequiredToShowToPartnerSignifier />
                            )}
                        </LabelContainer>
                        <NumberControlShowingEdits
                            id="calendarYearStart"
                            disabled={project.ControlledByAutomation}
                            min={2000}
                            step={1}
                            value={project.ProjectStartYear}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    ProjectStartYear: project.ProjectStartYear.update(
                                        getNumberFromInput(e)
                                    )
                                })
                            }></NumberControlShowingEdits>
                    </CalendarYearContainer>
                    <CalendarYearContainer>
                        <LabelContainer>
                            <Label htmlFor="calendarYearEnd">End</Label>
                            {project.ProjectType.current === PlantingProjectType.Reforestation && (
                                <RequiredToShowToPartnerSignifier />
                            )}
                        </LabelContainer>
                        <NumberControlShowingEdits
                            id="calendarYearEnd"
                            disabled={project.ControlledByAutomation}
                            min={2000}
                            step={1}
                            value={project.ProjectEndYear}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    ProjectEndYear: project.ProjectEndYear.update(
                                        getNumberFromInput(e)
                                    )
                                })
                            }></NumberControlShowingEdits>
                    </CalendarYearContainer>
                </CalendarYearsContainer>
                {project.ProjectStartYear.validations.length > 0 && (
                    <ValidationsList validations={project.ProjectStartYear.validations} />
                )}
                {project.ProjectEndYear.validations.length > 0 && (
                    <ValidationsList validations={project.ProjectEndYear.validations} />
                )}
            </CalendarYearBlock>
        </>
    );
}
