export function formatDate(d: string) {
    if (!d) return '';
    const date = new Date(d.length <= 10 ? d + 'T00:00:00' : d);
    return date.toLocaleDateString();
}

export function formatDateTime(dt: string) {
    if (!dt) return '';
    const date = new Date(dt);
    return date.toLocaleString();
}
