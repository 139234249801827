import {PlantingProjectType} from '../../../entities';
import {RecordEditStatus} from '../../../record-editing';
import {EditedParticipation, EditedPartner} from '../../Partner/EditedPartner';

export function participationHasChanges(partner: EditedPartner) {
    if (!partner) return null;

    if (
        partner.Name.modified ||
        partner.LogoDAMAssetId.modified ||
        partner.PartnerStatementTemplate.modified ||
        partner.PartnerStatement.modified ||
        partner.PartnerLive.modified ||
        partner.Archived.modified
    )
        return true;

    for (const ppn of partner.Participations) {
        switch (ppn.ProjectType) {
            case PlantingProjectType.Reforestation:
                if (reforestationEventHasChanges(ppn)) return true;
                break;
            case PlantingProjectType.CommunityEvent:
                if (communityEventHasChanges(ppn)) return true;
                break;
            case PlantingProjectType.CommunityCanopy:
                if (communityCanopyHasChanges(ppn)) return true;
                break;
            case PlantingProjectType.Carbon:
                if (carbonParticipationHasChanges(ppn)) return true;
                break;
        }
    }

    for (const da of partner.D365Accounts) {
        if (da.EditStatus === RecordEditStatus.NewDeleted) continue;
        if (da.EditStatus !== RecordEditStatus.Existing) return true;
    }

    for (const nc of partner.NavCustomers) {
        if (nc.EditStatus === RecordEditStatus.NewDeleted) continue;
        if (nc.EditStatus !== RecordEditStatus.Existing) return true;
    }

    return false;

    function carbonParticipationHasChanges(ppn: EditedParticipation) {
        if (
            ppn.EditStatus !== RecordEditStatus.Existing ||
            ppn.Excluded.modified ||
            ppn.PlantingProjectId.modified ||
            ppn.FundedYear.modified ||
            ppn.FundedYearValidated.modified ||
            ppn.FundingSource.modified ||
            ppn.CarbonPurchaseAmount.modified ||
            ppn.CarbonCreditsTransacted.modified ||
            ppn.SerialNumber.modified ||
            ppn.ParticipantRegistryLink.modified ||
            ppn.VintageYear.modified
        )
            return true;

        return false;
    }
    function reforestationEventHasChanges(ppn: EditedParticipation) {
        if (
            ppn.EditStatus !== RecordEditStatus.Existing ||
            ppn.Excluded.modified ||
            ppn.PlantingProjectId.modified ||
            ppn.FundedYear.modified ||
            ppn.FundedYearValidated.modified ||
            ppn.FundingSource.modified ||
            ppn.DollarsDonated.modified ||
            ppn.DollarsDonatedValidated.modified ||
            ppn.ShowOnDate.modified ||
            ppn.TTFTrees.modified ||
            ppn.TTFTreesValidated.modified
        ) {
            return true;
        }

        return false;
    }
    function communityEventHasChanges(ppn: EditedParticipation) {
        if (
            ppn.EditStatus !== RecordEditStatus.Existing ||
            ppn.Excluded.modified ||
            ppn.PlantingProjectId.modified ||
            ppn.FundedYear.modified ||
            ppn.FundedYearValidated.modified ||
            ppn.FundingSource.modified ||
            ppn.ShowOnDate.modified ||
            ppn.CommunityTreesPlanted.modified ||
            ppn.CommunityTreesPlantedValidated.modified ||
            ppn.CommunityTreesDistributed.modified ||
            ppn.CommunityTreesDistributedValidated.modified ||
            ppn.CommunityBudgetPassThrough.modified ||
            ppn.CommunityBudgetPassThroughValidated.modified
        )
            return true;

        return false;
    }
    function communityCanopyHasChanges(ppn: EditedParticipation) {
        if (
            ppn.EditStatus !== RecordEditStatus.Existing ||
            ppn.Excluded.modified ||
            ppn.PlantingProjectId.modified ||
            ppn.FundedYear.modified ||
            ppn.FundedYearValidated.modified ||
            ppn.FundingSource.modified ||
            ppn.DollarsDonated.modified ||
            ppn.DollarsDonatedValidated.modified ||
            ppn.ShowOnDate.modified
        )
            return true;

        return false;
    }
}
