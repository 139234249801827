import { ValidateFilter, ValidateFilterValue, ValidatedIcon, ValidateControl } from "../../../../components/Controls/ValidateControl"
import { NumericFilter, ValidationsList, ComplexInputWithAsyncUpdate } from "../../../../components/Form/FormStyles"
import { StickyHeader, StickyNumericHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { PlantingProjectType } from "../../../../entities"
import { getNumberFromInput } from "../../../../record-editing"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications"
import { AlignToTextBoxContent, ValidationTippy, ValidationTippyContent, FundedYearControl } from "../../Styles/ParticipationRowStyles"
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles"

export function FundedYearHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '105px', minWidth: '105px', maxWidth: '105px' }}>
			Funded year
		</StickyHeader>)
}

export function FundedYearFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.FundedYearValidated ?? ValidateFilterValue.Unset}
					onChange={(e) => updateFilter({ ...filter, FundedYearValidated: e.value })}
				/>
				<NumericFilter
					value={filter?.FundedYear ?? ''}
					onChange={(e) => updateFilter({ ...filter, FundedYear: e.target.value })}
				/>
			</CellWithValidator>
		</StickyNumericHeader>)
}

export function FundedYearContent({ ppn, updater }: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledNumberCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<>
						{ppn.FundedYearValidated.current && <ValidatedIcon />}
						<AlignToTextBoxContent>{ppn.FundedYear.current}</AlignToTextBoxContent>
					</>
				) : (
					<>
						{ppn.ProjectType === PlantingProjectType.Reforestation ||
								ppn.ProjectType === PlantingProjectType.CommunityEvent ? (
							<ValidateControl
								validated={ppn.FundedYearValidated.current}
								disabled={ppn.FundedYear.notValidToShowPartner}
								onChange={(e) =>
									updater.update({
										...ppn,
										FundedYearValidated: ppn.FundedYearValidated.update(
											e.validated
										)
									})
								}
							/>
						) : (
							<ValidatedIcon />
						)}

						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList validations={ppn.FundedYear.validations} />
								</ValidationTippyContent>
							}
							disabled={ppn.FundedYear.validations.length === 0}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={FundedYearControl}
									showModification
									value={ppn.FundedYear}
									onChange={(updatedValue) =>
										updater.update({ ...ppn, FundedYear: updatedValue })
									}
									valueGetter={(e) =>
										ppn.FundedYear.update(getNumberFromInput(e))
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledNumberCell>)
}