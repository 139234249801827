import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export function NotValidatedCheckboxIcon({disabled = false}: {disabled?: boolean}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            style={{backgroundColor: disabled ? 'rgb(240, 240, 240)' : 'transparent'}}
            viewBox="0 0 16 16"
        >
            <path
                color={false ? 'rgb(200, 200, 200)' : 'rgb(255, 147, 69)'}
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
            />
        </svg>
    );
}

export function ValidatedCheckboxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                color="rgb(130, 240, 130)"
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
            />
            <path
                color="rgb(60, 220, 60)"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"
            />
        </svg>
    );
}

const Label = styled.label`
    height: 18px;
`;

const HiddenCheckbox = styled.input`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`;

function getTooltipMessage(validated: boolean, disabled: boolean) {
    if (disabled) return 'All validation issues must be fixed before marking this as validated';
    return validated ? 'Mark as not validated' : 'Mark as validated';
}

export function ValidateControl(props) {
    const message = getTooltipMessage(props.validated, props.disabled);

    return (
        <Tippy content={message}>
            <Label>
                <HiddenCheckbox
                    type="checkbox"
                    checked={props.validated}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange({validated: e.target.checked})}
                />
                {props.validated ? (
                    <ValidatedCheckboxIcon />
                ) : (
                    <NotValidatedCheckboxIcon disabled={props.disabled} />
                )}
            </Label>
        </Tippy>
    );
}

const StyledValidatedIcon = styled.span`
    line-height: 1;
    vertical-align: bottom;
`;

export function ValidatedIcon() {
    return (
        <StyledValidatedIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                viewBox="0 0 16 16"
            >
                <path
                    color="rgb(60, 220, 60)"
                    d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
            </svg>
        </StyledValidatedIcon>
    );
}

function NotFilteredCheckboxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                color="rgb(200, 200, 200)"
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
            />
            <path
                color="rgb(200, 200, 200)"
                d="M11.354 4.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0z"
            />
        </svg>
    );
}

export enum ValidateFilterValue {
    Unset = 1,
    Validated = 2,
    Unvalidated = 3
}

const FilterContainer = styled.span`
    height: 18px;
    font-size: 18px;
    line-height: 1;
    // vertical-align: bottom;
`;

export function ValidateFilter({
    value,
    onChange
}: {
    value: ValidateFilterValue;
    onChange: ({value}) => void;
}) {
    function message() {
        switch (value) {
            case ValidateFilterValue.Unset:
                return 'Showing validated and unvalidated';
            case ValidateFilterValue.Validated:
                return 'Showing validated';
            case ValidateFilterValue.Unvalidated:
                return 'Showing unvalidated';
            default:
                return null;
        }
    }

    function icon() {
        switch (value) {
            case ValidateFilterValue.Unset:
                return <NotFilteredCheckboxIcon />;
            case ValidateFilterValue.Validated:
                return <ValidatedCheckboxIcon />;
            case ValidateFilterValue.Unvalidated:
                return <NotValidatedCheckboxIcon />;
            default:
                return null;
        }
    }

    function nextValue() {
        switch (value) {
            case ValidateFilterValue.Unset:
                return ValidateFilterValue.Validated;
            case ValidateFilterValue.Validated:
                return ValidateFilterValue.Unvalidated;
            case ValidateFilterValue.Unvalidated:
                return ValidateFilterValue.Unset;
            default:
                return null;
        }
    }

    return (
        <Tippy content={message()}>
            <FilterContainer onClick={() => onChange({value: nextValue()})}>
                {icon()}
            </FilterContainer>
        </Tippy>
    );
}
