import {Filter} from '../../ParticipationFunctions/Filtering';
import {AcresRestoredFilter} from '../../ParticipationColumns/Shared/AcresRestored';
import {DAMTagFilter} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationFilter} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedFilter} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedFilter} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedFilter} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearFilter} from '../../ParticipationColumns/Shared/FundedYear';
import {HasHeroFilter} from '../../ParticipationColumns/Shared/HasHero';
import {HasTreeSpeciesFilter} from '../../ParticipationColumns/Shared/HasTreeSpecies';
import {ImageCountFilter} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedFilter} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedFilter} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ImpactStoryInProgressFilter} from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import {MapFilter} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdFilter} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerFilter} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdFilter} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectLocationFilter} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ProjectNameFilter} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusFilter} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeFilter} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ProjectYearsFilter} from '../../ParticipationColumns/Shared/ProjectYears';
import {ReasonForPlantingFilter} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {D365Filter} from '../../ParticipationColumns/Reforestation/D365';
import {TTFAgreementNumberFilter} from '../../ParticipationColumns/Reforestation/TTFAgreementNumber';
import {TTFCommitmentFiscalYearFilter} from '../../ParticipationColumns/Reforestation/TTFCommitmentFiscalYear';
import {TTFCommitmentsFilter} from '../../ParticipationColumns/Reforestation/TTFCommitments';
import {TTFDBHFilter} from '../../ParticipationColumns/Reforestation/TTFDBH';
import {TTFForestFilter} from '../../ParticipationColumns/Reforestation/TTFForest';
import {TTFPlantingTimeframeFilter} from '../../ParticipationColumns/Reforestation/TTFPlantingTimeframe';
import {TTFTotalTreeCostFilter} from '../../ParticipationColumns/Reforestation/TTFTotalTreeCost';
import {TTFTreesFilter} from '../../ParticipationColumns/Reforestation/TTFTrees';
import {THFilter} from '../../ParticipationColumns/Reforestation/TreeHarmony';
import {ShowOnDateFilter} from '../../ParticipationColumns/Shared/ShowOnDate';
import {ProjectTTFTreesFilter} from '../../ParticipationColumns/Reforestation/ProjectTTFTrees';
import {FundingSourceFilter} from '../../ParticipationColumns/Shared/FundingSource';

export function ReforestationFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <tr>
            <DeleteParticipationFilter />
            <ExcludedFilter filter={filter} updateFilter={updateFilter} />
            <EverythingValidatedFilter filter={filter} updateFilter={updateFilter} />
            <ParticipationIdFilter filter={filter} updateFilter={updateFilter} />
            <FundedYearFilter filter={filter} updateFilter={updateFilter} />
            <FundingSourceFilter filter={filter} updateFilter={updateFilter} />
            <TTFTreesFilter filter={filter} updateFilter={updateFilter} />
            <DollarDonatedFilter filter={filter} updateFilter={updateFilter} />
            <ShowOnDateFilter filter={filter} updateFilter={updateFilter} />
            <D365Filter />
            <THFilter />
            <ProjectIdFilter filter={filter} updateFilter={updateFilter} />
            <ProjectTypeFilter filter={filter} updateFilter={updateFilter} />
            <ProjectNameFilter filter={filter} updateFilter={updateFilter} />
            <ProjectStatusFilter filter={filter} updateFilter={updateFilter} />
            <ProjectLocationFilter filter={filter} updateFilter={updateFilter} />
            <TTFForestFilter filter={filter} updateFilter={updateFilter} />
            <MapFilter filter={filter} updateFilter={updateFilter} />
            <ProjectYearsFilter filter={filter} updateFilter={updateFilter} />
            <TTFPlantingTimeframeFilter filter={filter} updateFilter={updateFilter} />
            <ReasonForPlantingFilter filter={filter} updateFilter={updateFilter} />
            <ImpactStoryInProgressFilter filter={filter} updateFilter={updateFilter} />
            <ImpactStoryCompletedFilter filter={filter} updateFilter={updateFilter} />
            <PlantingPartnerFilter filter={filter} updateFilter={updateFilter} />
            <TTFCommitmentsFilter filter={filter} updateFilter={updateFilter} />
            <TTFCommitmentFiscalYearFilter filter={filter} updateFilter={updateFilter} />
            <TTFAgreementNumberFilter filter={filter} updateFilter={updateFilter} />
            <TTFTotalTreeCostFilter filter={filter} updateFilter={updateFilter} />
            <ProjectTTFTreesFilter filter={filter} updateFilter={updateFilter} />
            <TTFDBHFilter filter={filter} updateFilter={updateFilter} />
            <AcresRestoredFilter filter={filter} updateFilter={updateFilter} />
            <HasTreeSpeciesFilter filter={filter} updateFilter={updateFilter} />
            <DAMTagFilter filter={filter} updateFilter={updateFilter} />
            <ImagesValidatedFilter filter={filter} updateFilter={updateFilter} />
            <ImageCountFilter filter={filter} updateFilter={updateFilter} />
            <HasHeroFilter filter={filter} updateFilter={updateFilter} />
        </tr>
    );
}
