import {AcresRestoredHeader} from '../../ParticipationColumns/Shared/AcresRestored';
import {AdditionalCertificationsHeader} from '../../ParticipationColumns/Carbon/AdditionalCertifications';
import {CCProjectStandardHeader} from '../../ParticipationColumns/Carbon/CCProjectStandard';
import {CarbonCreditDocumentsHeader} from '../../ParticipationColumns/Carbon/CarbonCreditDocuments';
import {CarbonCreditTypeHeader} from '../../ParticipationColumns/Carbon/CarbonCreditType';
import {VerifiedCarbonCreditsHeader} from '../../ParticipationColumns/Carbon/VerifiedCarbonCredits';
import {CarbonPurchaseAmountHeader} from '../../ParticipationColumns/Carbon/CarbonPurchaseAmount';
import {CorsiaEligibleHeader} from '../../ParticipationColumns/Carbon/CorsiaEligible';
import {ICROAEndorsedStandardHeader} from '../../ParticipationColumns/Carbon/ICROAEndorsedStandard';
import {MoreDetailsLinkHeader} from '../../ParticipationColumns/Carbon/MoreDetailsLink';
import {ParticipantRegistryLinkHeader} from '../../ParticipationColumns/Carbon/ParticipantRegistryLink';
import {ProjectTotalCarbonCreditsHeader} from '../../ParticipationColumns/Carbon/ProjectTotalCarbonCredits';
import {RegistryLinkHeader} from '../../ParticipationColumns/Carbon/RegistryLink';
import {SerialNumberHeader} from '../../ParticipationColumns/Carbon/SerialNumber';
import {SustainableDevelopmentGoalsHeader} from '../../ParticipationColumns/Carbon/SustainableDevelopmentGoals';
import {VintageYearHeader} from '../../ParticipationColumns/Carbon/VintageYear';
import {DAMTagHeader} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationHeader} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {EverythingValidatedHeader} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedHeader} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearHeader} from '../../ParticipationColumns/Shared/FundedYear';
import {HasHeroHeader} from '../../ParticipationColumns/Shared/HasHero';
import {ImageCountHeader} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedHeader} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedHeader} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ProjectLocationHeader} from '../../ParticipationColumns/Shared/ProjectLocation';
import {MapHeader} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdHeader} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerHeader} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdHeader} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectNameHeader} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusHeader} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeHeader} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ProjectYearsHeader} from '../../ParticipationColumns/Shared/ProjectYears';
import {FundingSourceHeader} from '../../ParticipationColumns/Shared/FundingSource';

const CarbonParticipationHeader = () => {
    return (
        <tr>
            <DeleteParticipationHeader />
            <ExcludedHeader />
            <EverythingValidatedHeader />
            <ParticipationIdHeader />
            <FundedYearHeader />
            <FundingSourceHeader />
            <CarbonPurchaseAmountHeader />
            <VerifiedCarbonCreditsHeader />
            <VintageYearHeader />
            <SerialNumberHeader />
            <ParticipantRegistryLinkHeader />
            <CarbonCreditDocumentsHeader />
            <ProjectIdHeader />
            <ProjectTypeHeader />
            <ProjectNameHeader />
            <ProjectStatusHeader />
            <ProjectLocationHeader />
            <MapHeader />
            <ProjectYearsHeader />
            <ImpactStoryCompletedHeader />
            <SustainableDevelopmentGoalsHeader />
            <PlantingPartnerHeader />
            <AcresRestoredHeader />
            <ProjectTotalCarbonCreditsHeader />
            <CCProjectStandardHeader />
            <CarbonCreditTypeHeader />
            <ICROAEndorsedStandardHeader />
            <CorsiaEligibleHeader />
            <AdditionalCertificationsHeader />
            <MoreDetailsLinkHeader />
            <RegistryLinkHeader />
            <DAMTagHeader />
            <ImagesValidatedHeader />
            <ImageCountHeader />
            <HasHeroHeader />
        </tr>
    );
};

export default CarbonParticipationHeader;
