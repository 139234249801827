import {
    LabelContainer,
    Label,
    RequiredToShowToPartnerSignifier,
    ValidationsList,
    FormFieldContainer,
    TextAreaControlShowingEdits
} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import {ValidateControl} from '../../../components/Controls/ValidateControl';

export function ImpactStoryCompletedControl({
    project,
    setProjectAndValidate,
    hideValidateControl,
    disabled
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    hideValidateControl?: boolean;
    disabled?: boolean;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!hideValidateControl && !disabled && (
                    <ValidateControl
                        validated={project.ImpactStoryCompletedValidated.current}
                        disabled={project.ImpactStoryCompleted.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                ImpactStoryCompletedValidated:
                                    project.ImpactStoryCompletedValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="impactStoryCompleted">Impact story - completed</Label>
                <RequiredToShowToPartnerSignifier />
            </LabelContainer>
            <TextAreaControlShowingEdits
                id="impactStoryCompleted"
                disabled={disabled}
                rows={6}
                value={project.ImpactStoryCompleted}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        ImpactStoryCompleted: project.ImpactStoryCompleted.update(e.target.value)
                    })
                }></TextAreaControlShowingEdits>
            <ValidationsList validations={project.ImpactStoryCompleted.validations} />
        </FormFieldContainer>
    );
}
