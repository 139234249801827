import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { TextFilter } from "../../../../components/Form/FormStyles";

export function CommunitySiteAddressHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '360px',
				minWidth: '360px',
				maxWidth: '360px'
			}}>
			Community site address
		</StickyHeader>
	)
}

export function CommunitySiteAddressFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CommunitySiteAddressValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunitySiteAddressValidated: e.value
						})
					}
				/>
				<TextFilter
					value={filter?.CommunitySiteAddress ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, CommunitySiteAddress: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function CommunitySiteAddressContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.CommunitySiteAddressValidated && (
						<ValidatedIcon />
					)}
				</span>
				<span>{ppn.PlantingProject.CommunitySiteAddress}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}