import {
    LabelContainer,
    Label,
    ValidationsList,
    FormFieldContainer,
    TextControlShowingEdits
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';

export function PlantingTimeframeControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.TTFPlantingTimeframeValidated.current}
                        disabled={project.TTFPlantingTimeframe.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                TTFPlantingTimeframeValidated:
                                    project.TTFPlantingTimeframeValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="plantingTimeframe">Planting timeframe</Label>
            </LabelContainer>
            <TextControlShowingEdits
                id="plantingTimeframe"
                disabled={project.ControlledByAutomation}
                maxLength={200}
                value={project.TTFPlantingTimeframe}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        TTFPlantingTimeframe: project.TTFPlantingTimeframe.update(e.target.value)
                    })
                }></TextControlShowingEdits>
            <ValidationsList validations={project.TTFPlantingTimeframe.validations} />
        </FormFieldContainer>
    );
}
