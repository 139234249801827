import {useEffect, useState, createContext, useContext} from 'react';

export type Config = {
	arcGISEnterpriseUrl: string;
    mappedByIdProjectLayerId: string;
    arcGISEnterpriseAppId: string;
    treeHarmonyBaseUrl: string;
};

const ConfigContext = createContext(null);

export function ConfigProvider({children}: {children: React.ReactNode}) {
    const [config, setConfig] = useState<Config>(null);

    const getConfig = async () => {
        const result = await (await fetch('/api/ui-config')).json();
        setConfig(result);
    };

    useEffect(() => {
        getConfig();
    }, []);

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

export function useConfig(): Config {
    return useContext(ConfigContext);
}
