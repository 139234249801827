import {AcresRestoredHeader} from '../../ParticipationColumns/Shared/AcresRestored';
import {DAMTagHeader} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationHeader} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedHeader} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedHeader} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedHeader} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearHeader} from '../../ParticipationColumns/Shared/FundedYear';
import {HasHeroHeader} from '../../ParticipationColumns/Shared/HasHero';
import {HasTreeSpeciesHeader} from '../../ParticipationColumns/Shared/HasTreeSpecies';
import {ImageCountHeader} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedHeader} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedHeader} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ImpactStoryInProgressHeader} from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import {MapHeader} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdHeader} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerHeader} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdHeader} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectLocationHeader} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ProjectNameHeader} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusHeader} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeHeader} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ProjectYearsHeader} from '../../ParticipationColumns/Shared/ProjectYears';
import {ReasonForPlantingHeader} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {ShowOnDateHeader} from '../../ParticipationColumns/Shared/ShowOnDate';
import {D365Header} from '../../ParticipationColumns/Reforestation/D365';
import {TTFAgreementNumberHeader} from '../../ParticipationColumns/Reforestation/TTFAgreementNumber';
import {TTFCommitmentFiscalYearHeader} from '../../ParticipationColumns/Reforestation/TTFCommitmentFiscalYear';
import {TTFCommitmentsHeader} from '../../ParticipationColumns/Reforestation/TTFCommitments';
import {TTFDBHHeader} from '../../ParticipationColumns/Reforestation/TTFDBH';
import {TTFForestHeader} from '../../ParticipationColumns/Reforestation/TTFForest';
import {TTFPlantingTimeframeHeader} from '../../ParticipationColumns/Reforestation/TTFPlantingTimeframe';
import {TTFTotalTreeCostHeader} from '../../ParticipationColumns/Reforestation/TTFTotalTreeCost';
import {TTFTreesHeader} from '../../ParticipationColumns/Reforestation/TTFTrees';
import {THHeader} from '../../ParticipationColumns/Reforestation/TreeHarmony';
import {ProjectTTFTreesHeader} from '../../ParticipationColumns/Reforestation/ProjectTTFTrees';
import {FundingSourceHeader} from '../../ParticipationColumns/Shared/FundingSource';

export function ReforestationHeader() {
    return (
        <tr>
            <DeleteParticipationHeader />
            <ExcludedHeader />
            <EverythingValidatedHeader />
            <ParticipationIdHeader />
            <FundedYearHeader />
            <FundingSourceHeader />
            <TTFTreesHeader />
            <DollarDonatedHeader />
            <ShowOnDateHeader />
            <D365Header />
            <THHeader />
            <ProjectIdHeader />
            <ProjectTypeHeader />
            <ProjectNameHeader />
            <ProjectStatusHeader />
            <ProjectLocationHeader />
            <TTFForestHeader />
            <MapHeader />
            <ProjectYearsHeader />
            <TTFPlantingTimeframeHeader />
            <ReasonForPlantingHeader />
            <ImpactStoryInProgressHeader />
            <ImpactStoryCompletedHeader />
            <PlantingPartnerHeader />
            <TTFCommitmentsHeader />
            <TTFCommitmentFiscalYearHeader />
            <TTFAgreementNumberHeader />
            <TTFTotalTreeCostHeader />
            <ProjectTTFTreesHeader />
            <TTFDBHHeader />
            <AcresRestoredHeader />
            <HasTreeSpeciesHeader />
            <DAMTagHeader />
            <ImagesValidatedHeader />
            <ImageCountHeader />
            <HasHeroHeader />
        </tr>
    );
}
