import styled from 'styled-components';
import {css} from 'styled-components';

export const StickyHeader = styled.th`
    background: white;
    position: sticky;
    top: 0;
`;

export const DateHeader = styled.th`
    && {
        text-align: right;
    }
`;

export const NumericHeader = styled.th`
    && {
        text-align: right;
    }
`;

export const DateCell = styled.td`
    text-align: right;
    white-space: nowrap;
`;

export const NumericCell = styled.td`
    text-align: right;
    white-space: nowrap;
`;

export const sticky = css`
    background: white;
    position: sticky;
    top: 0;
`;

export const StickyNumericHeader = styled(NumericHeader)`
    ${sticky}
`;

export const StickyDateHeader = styled(DateHeader)`
    ${sticky}
`;

export const CellWithValidator = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 18px 1fr;
    gap: 0.2rem;
    align-items: center;
`;
