import styled from 'styled-components';

export function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
            />
            <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
            />
        </svg>
    );
}

const StyledCloseButton = styled.button`
    margin-top: 6px;
    border: none;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
`;

export function RemoveButton(props) {
    return (
        <StyledCloseButton {...props}>
            <CloseIcon />
        </StyledCloseButton>
    );
}
