export function decimalPlaces(a) {
    if (!a) return 0;
    if (!isFinite(a)) return 0;
    let e = 1,
        p = 0;
    while (Math.round(a * e) / e !== a) {
        e *= 10;
        p++;
    }
    return p;
}
