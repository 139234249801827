import {
    LabelContainer,
    Label,
    RequiredToShowToPartnerSignifier,
    SelectControlShowingEdits,
    ValidationsList,
    FormFieldContainer
} from '../../../components/Form/FormStyles';
import {approvedReasonsForPlanting, EditedProject} from '../EditedProject';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import styled from 'styled-components';

export const ReasonForPlantingContainer = styled(FormFieldContainer)`
    max-width: 500px;
`;

export function ReasonForPlantingControl({
    project,
    setProjectAndValidate,
    hideValidateControl
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    hideValidateControl?: boolean;
}) {
    return (
        <ReasonForPlantingContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && !hideValidateControl && (
                    <ValidateControl
                        validated={project.ReasonForPlantingValidated.current}
                        disabled={project.ReasonForPlanting.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                ReasonForPlantingValidated:
                                    project.ReasonForPlantingValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="reasonForPlanting">Reason for planting</Label>
                <RequiredToShowToPartnerSignifier />
            </LabelContainer>
            <SelectControlShowingEdits
                id="reasonForPlanting"
                disabled={project.ControlledByAutomation}
                value={project.ReasonForPlanting}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        ReasonForPlanting: project.ReasonForPlanting.update(e.target.value)
                    })
                }>
                {!approvedReasonsForPlanting.includes(project.ReasonForPlanting.current) && (
                    <option value={project.ReasonForPlanting.current ?? ''}>
                        {project.ReasonForPlanting.current}
                    </option>
                )}
                {approvedReasonsForPlanting.map((reason) => (
                    <option key={reason} value={reason}>
                        {reason}
                    </option>
                ))}
            </SelectControlShowingEdits>
            <ValidationsList validations={project.ReasonForPlanting.validations} />
        </ReasonForPlantingContainer>
    );
}
