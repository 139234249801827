import styled from 'styled-components';
import {
    Button,
    FormFieldContainer,
    Label,
    LabelContainer,
    NotValidToSaveValidation,
    TextControlShowingEdits
} from '../../components/Form/FormStyles';
import {EditedField} from '../../record-editing';
import {ArcGISLogin} from '../../entities';
import {UserRoles} from '../../providers/UserProvider';
import {useEffect, useState} from 'react';
import {SuccessAlert, FailureAlert} from '../../components/Alerts/Alerts';

const StyledTitle = styled.h3`
    font-size: 1rem;
    font-weight: 600;
`;

const ArcGISUsername = styled(TextControlShowingEdits)`
    max-width: 250px;
`;

const ArcGISPassword = styled(TextControlShowingEdits)`
    max-width: 250px;
`;

const ActionsContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

function ArcGISLoginControl({
    partnerId,
    partnerName,
    arcGISUsername,
    arcGISPassword,
    onChange,
    onLoginCreated,
    onPasswordChanged
}: {
    partnerId: number;
    partnerName: string;
    arcGISUsername: EditedField<string>;
    arcGISPassword: EditedField<string>;
    onChange: (updatedLogin: ArcGISLogin) => void;
    onLoginCreated: () => void;
    onPasswordChanged: () => void;
}) {
    const [loginExists, setLoginExists] = useState<boolean | 'alreadyused' | 'pending'>('pending');
    const [partnerAlreadyUsingUsername, setPartnerAlreadyUsingUsername] = useState<{
        partnerId: number;
        partnerName: string;
    }>(null);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [failureMessage, setFailureMessage] = useState<string | null>(null);

    useEffect(() => {
        setLoginExists('pending');
        setSuccessMessage(null);
        setFailureMessage(null);
        setPartnerAlreadyUsingUsername(null);
    }, [partnerId]);

    const roles = UserRoles();
    const canEdit = roles.includes('partner_login_manager');

    useEffect(() => {
        const checkIfUserExists = async () => {
            if (arcGISUsername.current === null) {
                setLoginExists(false);
                return;
            }

            const saveResult = await fetch(
                `/api/partners/${partnerId}/login?username=${encodeURIComponent(
                    arcGISUsername.current
                )}`,
                {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (saveResult.ok) {
                setLoginExists(true);
            } else if (saveResult.status === 409) {
                const partner = await saveResult.json();
                setLoginExists('alreadyused');
                setPartnerAlreadyUsingUsername(partner);
            } else if (saveResult.status === 404) {
                setLoginExists(false);
            }
        };

        checkIfUserExists();
    }, [partnerId, arcGISUsername.current]);

    const handleUsernameChanged = (e) => {
        const updatedLogin = {
            ArcGISUsername: e.target.value as string,
            ArcGISPassword: arcGISPassword.current
        } as ArcGISLogin;

        onChange(updatedLogin);
    };

    const handlePasswordChanged = (e) => {
        const updatedLogin = {
            ArcGISUsername: arcGISUsername.current,
            ArcGISPassword: e.target.value as string
        } as ArcGISLogin;

        onChange(updatedLogin);
    };

    const handleCreateLoginClicked = async () => {
        const saveResult = await fetch(`/api/partners/${partnerId}/login`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: arcGISUsername.current,
                password: arcGISPassword.current,
                partnerName: partnerName
            })
        });

        if (saveResult.ok) {
            setSuccessMessage('User was created');
            setFailureMessage(null);
            setLoginExists(true);
            onLoginCreated();
        } else if (saveResult.status === 400) {
            const {message} = await saveResult.json();
            setSuccessMessage(null);
            setFailureMessage(message);
        } else {
            setSuccessMessage(null);
            setFailureMessage('Something went wrong');
        }
    };

    const handleUpdatePasswordClicked = async () => {
        const saveResult = await fetch(`/api/partners/${partnerId}/login`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: arcGISUsername.current,
                password: arcGISPassword.current
            })
        });

        if (saveResult.ok) {
            setSuccessMessage('Password was changed');
            setFailureMessage(null);
            onPasswordChanged();
        } else if (saveResult.status === 400) {
            const {message} = await saveResult.json();
            setSuccessMessage(null);
            setFailureMessage(message);
        } else {
            setSuccessMessage(null);
            setFailureMessage('Something went wrong');
        }
    };

    return (
        <>
            <StyledTitle style={{marginBottom: 0}}>Impact Dashboard login</StyledTitle>

            <FormFieldContainer>
                <LabelContainer>
                    <Label htmlFor="arcGISUsername">Username</Label>
                </LabelContainer>
                <ArcGISUsername
                    id="arcGISUsername"
                    maxLength={50}
                    disabled={!canEdit || loginExists === true || loginExists === 'pending'}
                    value={arcGISUsername}
                    onChange={handleUsernameChanged}
                    data-lpignore="true"
                />
            </FormFieldContainer>

            {loginExists === 'alreadyused' && (
                <NotValidToSaveValidation>
                    This username is already used by partner{' '}
                    {partnerAlreadyUsingUsername.partnerName}
                </NotValidToSaveValidation>
            )}

            <FormFieldContainer>
                <LabelContainer>
                    <Label htmlFor="arcGISPassword">Password</Label>
                </LabelContainer>
                <ArcGISPassword
                    id="arcGISPassword"
                    maxLength={50}
                    disabled={!canEdit}
                    value={arcGISPassword}
                    onChange={handlePasswordChanged}
                    data-lpignore="true"
                />
            </FormFieldContainer>

            {canEdit && (
                <ActionsContainer>
                    <Button
                        disabled={
                            !arcGISUsername.current ||
                            !arcGISPassword.current ||
                            !partnerName ||
                            loginExists === true ||
                            loginExists === 'alreadyused' ||
                            loginExists === 'pending'
                        }
                        onClick={handleCreateLoginClicked}>
                        Create login
                    </Button>

                    <Button
                        disabled={
                            !arcGISUsername.current ||
                            !arcGISPassword.current ||
                            !arcGISPassword.modified ||
                            loginExists !== true
                        }
                        onClick={handleUpdatePasswordClicked}>
                        Change password
                    </Button>
                </ActionsContainer>
            )}

            {successMessage && <SuccessAlert>{successMessage}</SuccessAlert>}
            {failureMessage && <FailureAlert>{failureMessage}</FailureAlert>}
        </>
    );
}

export default ArcGISLoginControl;
