import {
    LabelContainer,
    Label,
    RequiredToShowToPartnerSignifier,
    ValidationsList,
    FormFieldContainer,
    TextAreaControlShowingEdits
} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import {ValidateControl} from '../../../components/Controls/ValidateControl';

export function ImpactStoryInProgressControl({
    project,
    setProjectAndValidate,
    disabled
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    disabled?: boolean;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!disabled && (
                    <ValidateControl
                        validated={project.ImpactStoryInProgressValidated.current}
                        disabled={project.ImpactStoryInProgress.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                ImpactStoryInProgressValidated:
                                    project.ImpactStoryInProgressValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="impactStoryInProgress">Impact story - in progress</Label>
                {!project.Completed.current && <RequiredToShowToPartnerSignifier />}
            </LabelContainer>
            <TextAreaControlShowingEdits
                id="impactStoryInProgress"
                disabled={disabled}
                rows={6}
                value={project.ImpactStoryInProgress}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        ImpactStoryInProgress: project.ImpactStoryInProgress.update(e.target.value)
                    })
                }></TextAreaControlShowingEdits>
            <ValidationsList validations={project.ImpactStoryInProgress.validations} />
        </FormFieldContainer>
    );
}
