
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyNumericHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { NumericFilter } from "../../../../components/Form/FormStyles";

export function CommunityDistributedDBHHeader() {
	return (
		<StickyNumericHeader
			style={{
				...offsetOneRow,
				width: '125px',
				minWidth: '125px',
				maxWidth: '125px'
			}}>
			Community dist. DBH
		</StickyNumericHeader>
	)
}

export function CommunityDistributedDBHFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CommunityDBHTreesDistributedValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityDBHTreesDistributedValidated: e.value
						})
					}
				/>
				<NumericFilter
					value={filter?.CommunityDBHTreesDistributed ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							CommunityDBHTreesDistributed: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function CommunityDistributedDBHContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledNumberCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.CommunityDBHTreesDistributedValidated && (
						<ValidatedIcon />
					)}
				</span>
				<span>{ppn.PlantingProject.CommunityDBHTreesDistributed}</span>
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}