import Tippy from '@tippyjs/react';
import React, {useEffect, useState, useTransition} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {EditedField, RecordEditStatus} from '../../record-editing';
import {AddButton} from '../../components/Buttons/AddButton';
import {RemoveFilterButton} from '../../components/Buttons/FilterButton';
import {SectionHeader} from '../../components/Form/FormStyles';
import {
    EditedParticipation,
    EditedPartner,
    updatePartnerWithParticipation
} from '../Partner/EditedPartner';
import {Filter, filterParticipations} from './ParticipationFunctions/Filtering';
import {
    ParticipationRemover,
    ParticipationUnremover,
    ParticipationUpdater,
    ProjectSelectionSetter
} from './ParticipationFunctions/ParticipationModifications';
import ParticipationsTable from './ParticipationsTable';
import ParticipationViewSelector, {ActiveTab, ParticipationView} from './ParticipationViewSelector';
import {UserRoles} from '../../providers/UserProvider';
import {PlantingProjectType} from '../../entities';
import ProjectSelector from './ProjectSelector';

const ParticipationsLayout = styled.div`
    grid-area: main;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'participations-header'
        'participations-table';
    place-content: stretch;
    overflow-y: auto;
`;

const ParticipationsHeaderArea = styled.div`
    grid-area: participations-header;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
`;

const ParticipationsCommandList = styled.div`
    display: flex;
    gap: 1rem;
    line-height: 1;
`;

const ParticipationViewSelectorWrapper = styled.div`
    margin-left: auto;
`;

const ParticipationsTableArea = styled.div`
    grid-area: participations-table;
    overflow-y: auto;
    padding: 0 1rem;
`;

function ParticipationsTab({
    partner,
    setPartnerAndValidate,
    activeTab,
    view,
    setView,
    filter,
    removeFilters,
    updateFilters,
    setMessage
}: {
    partner: EditedPartner;
    setPartnerAndValidate: (partner: EditedPartner) => void;
    activeTab: ActiveTab;
    view: ParticipationView;
    setView: React.Dispatch<React.SetStateAction<ParticipationView>>;
    filter: Filter;
    removeFilters: () => void;
    updateFilters: (filter: Filter) => void;
    setMessage: React.Dispatch<
        React.SetStateAction<{show: boolean; success: boolean; message: string}>
    >;
}) {
    const [, startTransition] = useTransition();
    const location = useLocation();

    const [projectSelection, setProjectSelection] = useState({
        participationId: null,
        inSelectionMode: false
    });

    const [nextParticipationId, setNextParticipationId] = useState(-1);
    const [lythoImageData, setLythoImageData] = useState(null);
    const [participations, setParticipations] = useState([]);

    const [headerText, setHeaderText] = useState('');
    const [projectType, setProjectType] = useState(PlantingProjectType.Reforestation);

    const [participationRemover] = useState(new ParticipationRemover(removeParticipation));
    participationRemover.remover = removeParticipation;

    const [participationUnremover] = useState(new ParticipationUnremover(unremoveParticipation));
    participationUnremover.unremover = unremoveParticipation;

    const [projectSelectionSetter] = useState(new ProjectSelectionSetter(setProjectSelection));
    projectSelectionSetter.setProject = setProjectSelection;

    const [participationUpdater] = useState(new ParticipationUpdater(updateParticipation));
    participationUpdater.updater = updateParticipation;

    useEffect(() => {
        switch (activeTab) {
            case ActiveTab.Default:
                return;
            case ActiveTab.Carbon:
                setHeaderText('Carbon Participations');
                setProjectType(PlantingProjectType.Carbon);
                break;
            case ActiveTab.Community:
                setHeaderText('Community Participations');
                setProjectType(PlantingProjectType.CommunityEvent);
                break;
            case ActiveTab.Reforestation:
                setHeaderText('Reforestation Participations');
                setProjectType(PlantingProjectType.Reforestation);
                break;
            case ActiveTab.CommunityCanopy:
                setHeaderText('Community Canopy Participations');
                setProjectType(PlantingProjectType.CommunityCanopy);
                break;
        }
    }, [activeTab]);

    useEffect(() => {
        startTransition(() => {
            const participations = filterParticipations(
                partner?.Participations ?? [],
                lythoImageData,
                filter,
                view
            );
            setParticipations(participations);
        });
    }, [filter, view, partner, lythoImageData, activeTab]);

    useEffect(() => {}, [location]);

    useEffect(() => {
        getLythoImageData();
    }, []);

    const getLythoImageData = async () => {
        const result = await (await fetch(`/api/lytho-hero-data`)).json();
        setLythoImageData(result);
    };

    function unremoveParticipation(participationId: number) {
        setPartnerAndValidate({
            ...partner,
            Participations: partner.Participations.map((ppn) => ({
                ...ppn,
                EditStatus:
                    ppn.Id === participationId
                        ? updateStatusToUnDeleted(ppn.EditStatus)
                        : ppn.EditStatus
            }))
        });
    }

    function updateStatusToDeleted(currentStatus: RecordEditStatus) {
        if (currentStatus === RecordEditStatus.New) return RecordEditStatus.NewDeleted;
        else return RecordEditStatus.Deleted;
    }

    function updateStatusToUnDeleted(currentStatus: RecordEditStatus) {
        if (currentStatus === RecordEditStatus.NewDeleted) return RecordEditStatus.New;
        else return RecordEditStatus.Existing;
    }

    function removeParticipation(participationId: number) {
        setPartnerAndValidate({
            ...partner,
            Participations: partner.Participations.map((ppn) => ({
                ...ppn,
                EditStatus:
                    ppn.Id === participationId
                        ? updateStatusToDeleted(ppn.EditStatus)
                        : ppn.EditStatus
            }))
        });
    }

    function updateParticipation(participation: EditedParticipation) {
        setPartnerAndValidate(updatePartnerWithParticipation(partner, participation));
    }

    function projectSelectionClosed() {
        if (projectType === PlantingProjectType.CommunityCanopy) {
            setPartnerAndValidate({
                ...partner,
                Participations: partner.Participations.filter(
                    (ppn) => ppn.Id !== projectSelection.participationId
                )
            });
        }

        setProjectSelection({participationId: null, inSelectionMode: false});
    }

    function updateView(view: ParticipationView) {
        setView(view);
    }

    async function projectSelected(projectId: number) {
        const plantingProject = await (await fetch(`/api/planting-projects/${projectId}`)).json();
        setPartnerAndValidate({
            ...partner,
            Participations: partner.Participations.map((ppn) => ({
                ...ppn,
                PlantingProjectId:
                    ppn.Id === projectSelection.participationId
                        ? ppn.PlantingProjectId.update(projectId)
                        : ppn.PlantingProjectId,
                PlantingProject:
                    ppn.Id === projectSelection.participationId
                        ? plantingProject
                        : ppn.PlantingProject
            }))
        });
        setProjectSelection({participationId: null, inSelectionMode: false});
    }

    function addParticipation() {
        setPartnerAndValidate({
            ...partner,
            Participations: [
                {
                    EditStatus: RecordEditStatus.New,
                    Id: nextParticipationId,
                    PartnerId: partner.Id,
                    PlantingProjectId: new EditedField<number>(null),
                    PlantingProject: {
                        Id: null,
                        ProjectType: null,
                        ProjectTypeValidated: false,
                        Name: null,
                        NameValidated: false,
                        Location: null,
                        LocationValidated: false,
                        TTFForestCode: null,
                        TTFForestName: null,
                        TTFForestCodeValidated: false,
                        ReasonForPlanting: null,
                        ReasonForPlantingValidated: false,
                        TreeSpeciesPlanted: null,
                        TreeSpecies: [],
                        TreeSpeciesValidated: false,
                        PlantingPartnerName: null,
                        PlantingPartnerNameValidated: false,
                        ImpactStoryUnedited: null,
                        ImpactStoryInProgress: null,
                        ImpactStoryInProgressValidated: false,
                        ImpactStoryCompleted: null,
                        ImpactStoryCompletedValidated: false,
                        ProjectStartYear: null,
                        ProjectEndYear: null,
                        ProjectYearsValidated: false,
                        TTFTrees: null,
                        TTFTreesValidated: false,
                        AcresRestored: null,
                        AcresRestoredValidated: false,
                        TTFPlantingTimeframe: null,
                        TTFPlantingTimeframeValidated: false,
                        TTFDBH: null,
                        TTFDBHValidated: false,
                        TTFTotalTreeCost: null,
                        TTFTotalTreeCostValidated: false,
                        TTFAgreementNumber: null,
                        TTFAgreementNumberValidated: false,
                        TTFCommitmentFiscalYear: null,
                        TTFCommitmentFiscalYearValidated: false,
                        CommunityTreesPlanted: null,
                        CommunityTreesPlantedValidated: false,
                        CommunityTreesDistributed: null,
                        CommunityTreesDistributedValidated: false,
                        CommunityNumberOfCorporateVolunteers: null,
                        CommunityNumberOfCorporateVolunteersValidated: false,
                        CommunityNumberOfTotalParticipants: null,
                        CommunityNumberOfTotalParticipantsValidated: false,
                        CommunityFirstDayOfEvent: null,
                        CommunityFinalDayOfEvent: null,
                        CommunityEventDatesValidated: false,
                        CommunitySiteName: null,
                        CommunitySiteNameValidated: false,
                        CommunitySiteAddress: null,
                        CommunitySiteAddressValidated: false,
                        CommunitySiteCity: null,
                        CommunitySiteCityValidated: false,
                        CommunitySiteStateCode: null,
                        CommunitySiteStateCodeValidated: false,
                        CommunitySitePostalCode: null,
                        CommunitySitePostalCodeValidated: false,
                        CommunitySiteCountryCode: null,
                        CommunitySiteCountryCodeValidated: false,
                        CommunityDBHTreesPlanted: null,
                        CommunityDBHTreesPlantedValidated: false,
                        CommunityDBHTreesDistributed: null,
                        CommunityDBHTreesDistributedValidated: false,
                        DAMTag: null,
                        ImagesValidated: false,
                        IsHoldingProject: null,
                        HasTreeSpecies: null,
                        HasImpactStoryInProgress: null,
                        HasImpactStoryCompleted: null,
                        MapValidated: false,
                        EverythingValidated: false,
                        WasEverReadyToPublish: false,
                        Completed: false,
                        CompletedValidated: false,
                        GallonsTotalRunoffAvoidedPerTreeReforestation: null,
                        GallonsTotalRunoffAvoidedPerTreeEventPlanted: null,
                        GallonsTotalRunoffAvoidedPerTreeEventDistributed: null,
                        TonsCarbonPerTreeReforestation: null,
                        TonsCarbonPerTreeEventPlanted: null,
                        TonsCarbonPerTreeEventDistributed: null,
                        TonsAirPollutionPerTreeReforestation: null,
                        TonsAirPollutionPerTreeEventPlanted: null,
                        TonsAirPollutionPerTreeEventDistributed: null,
                        ControlledByAutomation: false,
                        D365_eventcoordinationid: null,
                        D365PlantingProjectId: null,
                        Participations: [],
                        CarbonTotalCreditsIssued: null,
                        CarbonProjectStandard: null,
                        CarbonCreditCreditType: null,
                        CarbonICroaEndorsedStandard: null,
                        CarbonCorsiaEligible: null,
                        CarbonAdditionalDetailsLink: null,
                        CarbonProjectRegistryLink: null,
                        CarbonCreditsAdditionalCertifications: null,
                        SustainableDevelopmentGoals: null,
                        Commitments: [],
                        CarbonTotalCreditsIssuedValidated: false,
                        CarbonProjectStandardValidated: false,
                        CarbonCreditCreditTypeValidated: false,
                        CarbonICroaEndorsedStandardValidated: false,
                        CarbonCorsiaEligibleValidated: false,
                        CarbonAdditionalDetailsLinkValidated: false,
                        CarbonProjectRegistryLinkValidated: false,
                        SustainableDevelopmentGoalsValidated: false,
                        AdditionalCarbonCreditCertificationsValidated: false,
                        HasMap: false,
                        BHCampaigns: [],
                        FundingPartners: []
                    },
                    FundedYear: new EditedField<number>(null),
                    FundedYearValidated: new EditedField(false),
                    FundingSource: new EditedField<string>(null),
                    TTFTrees: new EditedField<number>(null),
                    TTFTreesValidated: new EditedField(false),
                    CommunityTreesPlanted: new EditedField<number>(null),
                    CommunityTreesPlantedValidated: new EditedField(false),
                    CommunityTreesDistributed: new EditedField<number>(null),
                    CommunityTreesDistributedValidated: new EditedField(false),
                    CommunityBudgetPassThrough: new EditedField<number>(null),
                    CommunityBudgetPassThroughValidated: new EditedField(false),
                    DollarsDonated: new EditedField<number>(null),
                    DollarsDonatedValidated: new EditedField(false),
                    AcresRestored: new EditedField<number>(null),
                    Excluded: new EditedField(false),
                    EverythingValidated: false,
                    WasEverReadyToPublish: false,
                    ReadyToPublish: false,
                    ShowOnDate: new EditedField<string>(null),
                    CarbonPurchaseAmount: new EditedField(null),
                    DocumentCount: null,
                    ControlledByAutomation: false,
                    D365RevenuePostingInvoiceId: null,
                    THLiabilityId: null,
                    TTFForestName: null,
                    CarbonCreditsTransacted: new EditedField(null),
                    ParticipantRegistryLink: new EditedField(null),
                    SerialNumber: new EditedField(null),
                    VintageYear: new EditedField(null),
                    ProjectType: projectType,
                    isOrderProduct: true
                },
                ...partner.Participations
            ]
        });

        projectSelectionSetter.setProject({
            participationId: nextParticipationId,
            inSelectionMode: true
        });

        setNextParticipationId(nextParticipationId - 1);
    }

    return (
        <ParticipationsLayout>
            <ParticipationsHeaderArea>
                <SectionHeader style={{minWidth: '265px'}}> {headerText}</SectionHeader>
                <ParticipationsCommandList>
                    {UserRoles().includes('editor') && (
                        <Tippy content="Add a project">
                            <span>
                                <AddButton onClick={addParticipation} />
                            </span>
                        </Tippy>
                    )}
                    {filter && <RemoveFilterButton onClick={removeFilters} />}
                </ParticipationsCommandList>
                <ParticipationViewSelectorWrapper>
                    <ParticipationViewSelector
                        view={view}
                        setView={updateView}
                        activeTab={activeTab}
                    />
                </ParticipationViewSelectorWrapper>
            </ParticipationsHeaderArea>
            <ParticipationsTableArea>
                <ParticipationsTable
                    filter={filter}
                    updateFilter={updateFilters}
                    participations={participations}
                    participationRemover={participationRemover}
                    participationUnremover={participationUnremover}
                    participationUpdater={participationUpdater}
                    projectSelectionSetter={projectSelectionSetter}
                    lythoImageData={lythoImageData}
                    view={view}
                    setMessage={setMessage}
                />
                {projectSelection.inSelectionMode ? (
                    <ProjectSelector
                        projectSelected={projectSelected}
                        closed={projectSelectionClosed}
                        projectType={projectType}
                    />
                ) : null}
            </ParticipationsTableArea>
        </ParticipationsLayout>
    );
}

export default ParticipationsTab;
