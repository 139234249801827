import {EditedParticipation} from '../../../Partner/EditedPartner';
import {CommunityBudgetPassThroughContent} from '../../ParticipationColumns/CommunityEvents/CommunityBudgetPassThrough';
import {CommunityCorpVolunteersContent} from '../../ParticipationColumns/CommunityEvents/CommunityCorpVolunteers';
import {CommunityDistributedDBHContent} from '../../ParticipationColumns/CommunityEvents/CommunityDistributedDBH';
import {CommunityEventDatesContent} from '../../ParticipationColumns/CommunityEvents/CommunityEventDates';
import {CommunityPlantedDBHContent} from '../../ParticipationColumns/CommunityEvents/CommunityPlantedDBH';
import {CommunitySiteAddressContent} from '../../ParticipationColumns/CommunityEvents/CommunitySiteAddress';
import {CommunitySiteCityContent} from '../../ParticipationColumns/CommunityEvents/CommunitySiteCity';
import {CommunitySiteCountryContent} from '../../ParticipationColumns/CommunityEvents/CommunitySiteCountry';
import {CommunitySiteNameContent} from '../../ParticipationColumns/CommunityEvents/CommunitySiteName';
import {CommunitySitePostalCodeContent} from '../../ParticipationColumns/CommunityEvents/CommunitySitePostalCode';
import {CommunitySiteStateContent} from '../../ParticipationColumns/CommunityEvents/CommunitySiteState';
import {CommunityTotalParticipantsContent} from '../../ParticipationColumns/CommunityEvents/CommunityTotalVolunteers';
import {CommunityTreesPlantedContent} from '../../ParticipationColumns/CommunityEvents/CommunityTreesPlanted';
import {ProjectCommunityTreesDistributedContent} from '../../ParticipationColumns/CommunityEvents/ProjectCommunityTreesDistributed';
import {ProjectCommunityTreesPlantedContent} from '../../ParticipationColumns/CommunityEvents/ProjectCommunityTreesPlanted';
import {CommunityTreesDistributedContent} from '../../ParticipationColumns/Shared/CommunityTreesDistributed';
import {DAMTagContent} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationContent} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedContent} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedContent} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedContent} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearContent} from '../../ParticipationColumns/Shared/FundedYear';
import {FundingSourceContent} from '../../ParticipationColumns/Shared/FundingSource';
import {HasHeroContent} from '../../ParticipationColumns/Shared/HasHero';
import {HasTreeSpeciesContent} from '../../ParticipationColumns/Shared/HasTreeSpecies';
import {ImageCountContent} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedContent} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedContent} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ImpactStoryInProgressContent} from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import {MapContent} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdContent} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerContent} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdContent} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectLocationContent} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ProjectNameContent} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusContent} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeContent} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ReasonForPlantingContent} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {ShowOnDateContent} from '../../ParticipationColumns/Shared/ShowOnDate';
import {
    ParticipationRemover,
    ParticipationUnremover,
    ParticipationUpdater,
    ProjectSelectionSetter
} from '../../ParticipationFunctions/ParticipationModifications';

export function CommunityEventContent({
    role,
    participation,
    remover,
    unremover,
    updater,
    projectSelectionSetter,
    imageData
}: {
    role: string[];
    participation: EditedParticipation;
    remover: ParticipationRemover;
    unremover: ParticipationUnremover;
    updater: ParticipationUpdater;
    projectSelectionSetter: ProjectSelectionSetter;
    imageData: {hasHero: boolean; imageCount: number};
}) {
    return (
        <tr>
            <DeleteParticipationContent
                ppn={participation}
                remover={remover}
                unremover={unremover}
                role={role}
            />
            <ExcludedContent ppn={participation} updater={updater} />
            <EverythingValidatedContent ppn={participation} />
            <ParticipationIdContent ppn={participation} />
            <FundedYearContent ppn={participation} updater={updater} />
            <FundingSourceContent ppn={participation} updater={updater} />
            <CommunityTreesPlantedContent ppn={participation} updater={updater} />
            <CommunityTreesDistributedContent ppn={participation} updater={updater} />
            <DollarDonatedContent ppn={participation} updater={updater} />
            <CommunityBudgetPassThroughContent ppn={participation} updater={updater} />
            <ShowOnDateContent ppn={participation} updater={updater} />
            <ProjectIdContent ppn={participation} projectSelectionSetter={projectSelectionSetter} />
            <ProjectTypeContent ppn={participation} />
            <ProjectNameContent ppn={participation} />
            <ProjectStatusContent ppn={participation} />
            <ProjectLocationContent ppn={participation} />
            <CommunitySiteNameContent ppn={participation} />
            <CommunitySiteAddressContent ppn={participation} />
            <CommunitySiteCityContent ppn={participation} />
            <CommunitySiteStateContent ppn={participation} />
            <CommunitySitePostalCodeContent ppn={participation} />
            <CommunitySiteCountryContent ppn={participation} />
            <MapContent ppn={participation} />
            <CommunityEventDatesContent ppn={participation} />
            <ReasonForPlantingContent ppn={participation} />
            <ImpactStoryInProgressContent ppn={participation} />
            <ImpactStoryCompletedContent ppn={participation} />
            <PlantingPartnerContent ppn={participation} />
            <CommunityCorpVolunteersContent ppn={participation} />
            <CommunityTotalParticipantsContent ppn={participation} />
            <ProjectCommunityTreesPlantedContent ppn={participation} />
            <ProjectCommunityTreesDistributedContent ppn={participation} />
            <CommunityPlantedDBHContent ppn={participation} />
            <CommunityDistributedDBHContent ppn={participation} />
            <HasTreeSpeciesContent ppn={participation} />
            <DAMTagContent ppn={participation} />
            <ImagesValidatedContent ppn={participation} />
            <ImageCountContent ppn={participation} imageCount={imageData.imageCount} />
            <HasHeroContent ppn={participation} hasHero={imageData.hasHero} />
        </tr>
    );
}
