import {
    FormFieldContainer,
    LabelContainer,
    Label,
    RequiredToShowToPartnerSignifier,
    TextControlShowingEdits,
    ValidationsList
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';

export function LocationControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <FormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.LocationValidated.current}
                        disabled={project.Location.notValidToShowPartner}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                LocationValidated: project.LocationValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <Label htmlFor="location">Location</Label>
                <RequiredToShowToPartnerSignifier />
            </LabelContainer>
            <TextControlShowingEdits
                id="location"
                disabled={project.ControlledByAutomation}
                maxLength={500}
                value={project.Location}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        Location: project.Location.update(e.target.value)
                    })
                }></TextControlShowingEdits>
            <ValidationsList validations={project.Location.validations} />
        </FormFieldContainer>
    );
}
