import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {SelectControl, TextControl} from '../components/Form/FormStyles';
import {StickyHeader} from '../components/Table/TableStyles';
import {AutomationRun} from '../entities';
import {textMatch} from '../filters';
import {formatDate, formatDateTime} from '../utilities/dateUtilities';

const LogsLayout = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'logs';
    place-content: stretch;
    padding: 0;
`;

const LogsArea = styled.div`
    grid-area: logs;
    overflow: auto;
    padding: 0;
`;

const NoWrapCell = styled.td`
    white-space: nowrap;
`;

const StickyHeaderSecondRow = styled(StickyHeader)`
    top: calc(0.6rem + 2 * 1.5 * 1rem);
`;

const StyledRunFilter = styled(SelectControl)``;

function RunFilter({
    runs,
    value,
    onChange
}: {
    runs: AutomationRun[];
    value: number;
    onChange: (runId: number) => void;
}) {
    return (
        <StyledRunFilter value={value} onChange={(e) => onChange(parseInt(e.target.value))}>
            {runs.map((run) => (
                <option key={run.Id} value={run.Id}>
                    {run.Automation} - {formatDate(run.StartDateTime)}
                </option>
            ))}
        </StyledRunFilter>
    );
}

type Filter = {
    runId: number;
    message: string;
};

const MessageFilter = styled(TextControl)``;

function filterLogs(runs: AutomationRun[], filter: Filter) {
    if (!runs || runs.length === 0) return [];
    const run = filter.runId ? runs.find((run) => run.Id === filter.runId) : runs[0];
    return run.Logs.filter((log) => textMatch(log.Message, filter.message));
}

function AutomationRunLogs() {
    const [runs, setRuns] = useState([] as AutomationRun[]);
    const [filter, setFilter] = useState<Filter>({runId: null, message: ''});

    useEffect(() => {
        const getLogs = async () => {
            const result = await (await fetch('/api/automation-run-logs')).json();
            setRuns(result);
        };

        getLogs();
    }, []);

    const filteredLogs = filterLogs(runs, filter);

    return (
        <LogsLayout>
            <LogsArea>
                <table
                    className="table"
                    style={{width: '100%', minWidth: '100%', maxWidth: '100%'}}>
                    <thead>
                        <tr>
                            <StickyHeader style={{width: '280px', minWidth: '280px'}}>
                                Automation run
                            </StickyHeader>
                            <StickyHeader>Message</StickyHeader>
                            <StickyHeader style={{width: '90px', minWidth: '90px'}}>
                                D365 account
                            </StickyHeader>
                            <StickyHeader style={{width: '90px', minWidth: '90px'}}>
                                Partner
                            </StickyHeader>
                            <StickyHeader style={{width: '90px', minWidth: '90px'}}>
                                D365 event
                            </StickyHeader>
                            <StickyHeader style={{width: '90px', minWidth: '90px'}}>
                                Planting project
                            </StickyHeader>
                            <StickyHeader style={{width: '90px', minWidth: '90px'}}>
                                D365 RPI
                            </StickyHeader>
                            <StickyHeader style={{width: '90px', minWidth: '90px'}}>
                                D365 project
                            </StickyHeader>
                        </tr>
                        <tr>
                            <StickyHeaderSecondRow>
                                <RunFilter
                                    runs={runs}
                                    value={filter.runId}
                                    onChange={(runId) => setFilter({...filter, runId: runId})}
                                />
                            </StickyHeaderSecondRow>
                            <StickyHeaderSecondRow>
                                <MessageFilter
                                    value={filter.message}
                                    onChange={(e) =>
                                        setFilter({...filter, message: e.target.value})
                                    }
                                />
                            </StickyHeaderSecondRow>
                            <StickyHeaderSecondRow></StickyHeaderSecondRow>
                            <StickyHeaderSecondRow></StickyHeaderSecondRow>
                            <StickyHeaderSecondRow></StickyHeaderSecondRow>
                            <StickyHeaderSecondRow></StickyHeaderSecondRow>
                            <StickyHeaderSecondRow></StickyHeaderSecondRow>
                            <StickyHeaderSecondRow></StickyHeaderSecondRow>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLogs.map((log) => (
                            <tr key={log.Id}>
                                <NoWrapCell>{formatDateTime(log.LogDateTime)}</NoWrapCell>
                                <td>{log.Message}</td>
                                <NoWrapCell>
                                    {log.D365AccountId && (
                                        <a
                                            href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=aaf69a85-59da-4da2-9c1b-219a888ed0e8&pagetype=entityrecord&etn=account&id=${log.D365AccountId}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            View
                                        </a>
                                    )}
                                </NoWrapCell>
                                <NoWrapCell>
                                    {log.PartnerId && (
                                        <Link to={`/partners/${log.PartnerId}`}>View</Link>
                                    )}
                                </NoWrapCell>
                                <NoWrapCell>
                                    {log.D365EventCoordinationId && (
                                        <a
                                            href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=aaf69a85-59da-4da2-9c1b-219a888ed0e8&forceUCI=1&pagetype=entityrecord&etn=stn_eventcoordination&id=${log.D365EventCoordinationId}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            View
                                        </a>
                                    )}
                                </NoWrapCell>
                                <NoWrapCell>
                                    {log.PlantingProjectId && (
                                        <Link to={`/planting-projects/${log.PlantingProjectId}`}>
                                            View
                                        </Link>
                                    )}
                                </NoWrapCell>
                                <NoWrapCell>
                                    {log.D365RevenuePostingInvoiceId &&
                                        (log.isOrderProduct ? (
                                            <a
                                                href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=aaf69a85-59da-4da2-9c1b-219a888ed0e8&pagetype=entityrecord&etn=salesorderdetail&id=${log.D365RevenuePostingInvoiceId}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                View
                                            </a>
                                        ) : (
                                            <a
                                                href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=aaf69a85-59da-4da2-9c1b-219a888ed0e8&forceUCI=1&pagetype=entityrecord&etn=stn_revenuepostinginvoice&id=${log.D365RevenuePostingInvoiceId}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                View
                                            </a>
                                        ))}
                                </NoWrapCell>
                                <NoWrapCell>
                                    {log.D365PlantingProjectId && (
                                        <a
                                            href={`https://arbordayfarm.crm.dynamics.com/main.aspx?appid=aaf69a85-59da-4da2-9c1b-219a888ed0e8&pagetype=entityrecord&etn=stn_plantingproject&id=${log.D365PlantingProjectId}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            View
                                        </a>
                                    )}
                                </NoWrapCell>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </LogsArea>
        </LogsLayout>
    );
}

export default AutomationRunLogs;
