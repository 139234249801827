import {EditedProject} from '../EditedProject';
import {TreeSpeciesSelectedHeader} from './TreeSpeciesControl';

export function TreeSpeciesReadOnly({project}: {project: EditedProject}) {
    return (
        <div>
            <TreeSpeciesSelectedHeader>Tree species</TreeSpeciesSelectedHeader>
            {project.TreeSpecies.map((ts, i) => [
                i > 0 && ', ',
                <span key={ts.Id}>{ts.OtherName.current ?? ts.Species.CommonName}</span>
            ])}
        </div>
    );
}
