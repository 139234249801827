import Tippy from "@tippyjs/react"
import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { StickyHeader } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, centeredContainerStyle, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function EverythingValidatedHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '50px', minWidth: '50px', maxWidth: '50px' }}>
			<Tippy content="Shows which participations have been fully validated">
				<span>Val</span>
			</Tippy>
		</StickyHeader>
	)
}

export function EverythingValidatedFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows, ...centeredContainerStyle }}>
			<ValidateFilter
				value={filter?.EverythingValidated ?? ValidateFilterValue.Unset}
				onChange={(e) => updateFilter({ ...filter, EverythingValidated: e.value })}
			/>
		</StickyHeader>
	)
}

export function EverythingValidatedContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			{ppn.EverythingValidated && <ValidatedIcon />}
		</StateStyledCell>
	)
}