import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { TextFilter } from "../../../../components/Form/FormStyles";

export function BeehiveCampaignsHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '370px', minWidth: '370px', maxWidth: '370px' }}>
			Beehive campaigns
		</StickyHeader>
	)
}

export function BeehiveCampaignsFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<TextFilter
				value={filter?.BHCampaigns ?? ''}
				onChange={(e) => updateFilter({ ...filter, BHCampaigns: e.target.value })}
			/>
		</StickyHeader>
	)
}

export function BeehiveCampaignsContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			{ppn.PlantingProject.BHCampaigns?.map((campaign, i) => [
				i > 0 && <br key={i} />,
				campaign.CampaignId
			])}
		</StateStyledCell>
	)
}