import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export const LiveSignifier = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: rgb(60, 220, 60);
    display: inline-block;
    margin-left: 0.5rem;
    line-height: 1;
`;

const LiveFilterIcon = styled.span`
    font-size: 13px;
    color: rgb(60, 220, 60);
    border: 1px solid rgb(60, 220, 60);
    border-radius: 13px;
    padding: 0px 6px 2px 6px;
    position: relative;
    top: -1px;
`;

const NotLiveFilterIcon = styled.span`
    font-size: 13px;
    text-decoration: line-through;
    color: rgb(255, 147, 69);
    border: 1px solid rgb(255, 147, 69);
    border-radius: 13px;
    padding: 0px 6px 2px 6px;
    position: relative;
    top: -1px;
`;

const NotFilteredFilterIcon = styled.span`
    font-size: 13px;
    color: rgb(200, 200, 200);
    border: 1px solid rgb(200, 200, 200);
    border-radius: 13px;
    padding: 0px 6px 2px 6px;
    position: relative;
    top: -1px;
`;

const FilterContainer = styled.span`
    cursor: pointer;
`;

export enum LiveFilterValue {
    Unset = 1,
    Live = 2,
    NotLive = 3
}

export function LiveFilter({
    value,
    onChange
}: {
    value: LiveFilterValue;
    onChange: (value: LiveFilterValue) => void;
}) {
    function message() {
        switch (value) {
            case LiveFilterValue.Unset:
                return 'Showing live and not live';
            case LiveFilterValue.Live:
                return 'Showing live';
            case LiveFilterValue.NotLive:
                return 'Showing not live';
            default:
                return null;
        }
    }

    function icon() {
        switch (value) {
            case LiveFilterValue.Unset:
                return <NotFilteredFilterIcon>Live</NotFilteredFilterIcon>;
            case LiveFilterValue.Live:
                return <LiveFilterIcon>Live</LiveFilterIcon>;
            case LiveFilterValue.NotLive:
                return <NotLiveFilterIcon>Live</NotLiveFilterIcon>;
            default:
                return null;
        }
    }

    function nextValue() {
        switch (value) {
            case LiveFilterValue.Unset:
                return LiveFilterValue.Live;
            case LiveFilterValue.Live:
                return LiveFilterValue.NotLive;
            case LiveFilterValue.NotLive:
                return LiveFilterValue.Unset;
            default:
                return null;
        }
    }

    return (
        <Tippy content={message()}>
            <FilterContainer onClick={() => onChange(nextValue())}>{icon()}</FilterContainer>
        </Tippy>
    );
}
