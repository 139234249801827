import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Validation, RecordEditStatus, ValidationLevel} from '../../../record-editing';
import {Button} from '../../../components/Form/FormStyles';
import {RemoveButtonX} from '../../../components/Buttons/RemoveButton';
import {UserRoles} from '../../../providers/UserProvider';
import {EditedPlantingProjectBHCampaign} from '../EditedProject';
import {ValidationsList} from '../../../components/Form/FormStyles';
import {TextControl} from '../../../components/Form/FormStyles';
import {isGuid} from '../../../utilities/guidUtilities';
import {BHCampaign, BHCampaignType, PlantingProjectBHCampaign} from '../../../entities';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1rem;
`;

const StyledCampaignList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const StyledCampaignListItem = styled.li`
    margin-bottom: 0.5rem;
`;

const CampaignContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const AddForm = styled.div`
    display: flex;
    gap .5rem;
    align-items: center;
`;

const NewCampaignIdTextBox = styled(TextControl)`
    width: 370px;
`;

function isValidCampaignIdFormat(str: string) {
    return isGuid(str);
}

async function campaignExistsOnAnotherProject(projectId: number, campaignId: string) {
    const projects = (await (
        await fetch(`/api/beehive-campaigns/${campaignId}/planting-projects`)
    ).json()) as PlantingProjectBHCampaign[];
    const index = projects.findIndex((project) => project.PlantingProjectId !== projectId);
    return index >= 0;
}

async function validateNewCampaign(
    newCampaignId: string,
    campaigns: EditedPlantingProjectBHCampaign[],
    availableCampaigns: BHCampaign[],
    projectId: number
) {
    const validations = [];

    if (newCampaignId) {
        const validFormat = isValidCampaignIdFormat(newCampaignId);
        if (!validFormat)
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'The format is invalid for a campaign id'
            });
        else {
            const newCampaignIsADuplicate = campaigns.find(
                (campaign) =>
                    campaign.CampaignId.toString() === newCampaignId &&
                    campaign.EditStatus !== RecordEditStatus.NewDeleted &&
                    campaign.EditStatus !== RecordEditStatus.Deleted
            );
            if (newCampaignIsADuplicate)
                validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: 'This is a duplicate'
                });

            if (
                availableCampaigns.findIndex(
                    (c) =>
                        c.BeehiveCampaignId.toLocaleLowerCase() ===
                        newCampaignId.toLocaleLowerCase()
                ) < 0
            )
                validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: "This id doesn't match any campaigns"
                });
        }

        if (validations.length === 0) {
            const exists = await campaignExistsOnAnotherProject(projectId, newCampaignId);
            if (exists) {
                validations.push({
                    level: ValidationLevel.NotValidToSave,
                    message: 'This campaign has been used on another project'
                });
            }
        }
    }

    return validations;
}

function validateList(campaigns: EditedPlantingProjectBHCampaign[]): Validation[] {
    const validations = [];

    if (!campaigns || campaigns.length === 0) {
        validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'At least one campaign is required'
        });
    }

    return validations;
}

function CampaignList({
    campaigns,
    addCampaign,
    removeCampaign,
    projectId
}: {
    campaigns: EditedPlantingProjectBHCampaign[];
    addCampaign: (campaign: EditedPlantingProjectBHCampaign) => void;
    removeCampaign: (campaign: EditedPlantingProjectBHCampaign) => void;
    projectId: number;
}) {
    const [newId, setNewId] = useState(-1);
    const [newCampaignId, setNewCampaignId] = useState('');
    const [availableCampaigns, setAvailableCampaigns] = useState<BHCampaign[]>([]);
    const [newCampaignValidations, setNewCampaignValidations] = useState<Validation[]>([]);
    const roles = UserRoles();

    useEffect(() => {
        async function getAvailableCampaigns() {
            const result = await fetch(`/api/beehive-campaigns`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (result.ok) {
                const campaigns = (await result.json()) as BHCampaign[];
                const communityCanopyCampaigns = campaigns.filter(
                    (c) => c.CampaignType === BHCampaignType.CommunityCanopy
                );
                setAvailableCampaigns(communityCanopyCampaigns);
            } else {
                console.log("Couldn't get the available Beehive campaigns");
            }
        }

        getAvailableCampaigns();
    }, []);

    useEffect(() => {
        async function getValidations() {
            const validations = await validateNewCampaign(
                newCampaignId,
                campaigns,
                availableCampaigns,
                projectId
            );
            setNewCampaignValidations(validations);
        }
        getValidations();
    }, [newCampaignId, campaigns, availableCampaigns, projectId]);

    const nonDeletedCampaigns = campaigns.filter(
        (account) =>
            account.EditStatus !== RecordEditStatus.NewDeleted &&
            account.EditStatus !== RecordEditStatus.Deleted
    );

    const validations = validateList(nonDeletedCampaigns);

    function raiseAddCampaign() {
        const campaign = {
            EditStatus: RecordEditStatus.New,
            Id: newId,
            CampaignId: newCampaignId
        };

        setNewId(newId - 1);
        setNewCampaignId('');
        addCampaign(campaign);
    }

    function handleNewCampaignIdChanged(e) {
        setNewCampaignId(e.target.value);
    }

    function handleAddCampaignButtonClicked(e) {
        e.preventDefault();
        raiseAddCampaign();
    }

    function handleRemoveButtonClicked(campaign: EditedPlantingProjectBHCampaign) {
        removeCampaign(campaign);
    }

    function handleKeyDown(evt) {
        if (evt.keyCode === 13) {
            if (newCampaignId && newCampaignValidations.length === 0) raiseAddCampaign();
        }
    }

    return (
        <Layout>
            <StyledCampaignList>
                {nonDeletedCampaigns.map((campaign) => (
                    <StyledCampaignListItem key={campaign.Id}>
                        <CampaignContainer>
                            {roles.includes('editor') && (
                                <RemoveButtonX
                                    onClick={() => handleRemoveButtonClicked(campaign)}
                                />
                            )}
                            {campaign.CampaignId}
                        </CampaignContainer>
                    </StyledCampaignListItem>
                ))}
            </StyledCampaignList>
            <AddForm>
                <NewCampaignIdTextBox
                    value={newCampaignId}
                    onChange={handleNewCampaignIdChanged}
                    onKeyDown={handleKeyDown}
                />
                {roles.includes('editor') && (
                    <Button
                        type="button"
                        disabled={!newCampaignId || newCampaignValidations.length > 0}
                        onClick={handleAddCampaignButtonClicked}>
                        Add campaign
                    </Button>
                )}
            </AddForm>
            <ValidationsList validations={[...newCampaignValidations, ...validations]} />
        </Layout>
    );
}

export default CampaignList;
