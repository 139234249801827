import styled from 'styled-components';

export function CheckedCheckboxIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill="#0d6efd"
                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"
            />
            <path
                fill="white"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"
            />
        </svg>
    );
}

export function UncheckedCheckboxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                fill="rgb(180, 180, 180)"
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
            />
        </svg>
    );
}

const HiddenCheckbox = styled.input`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`;

export function CheckboxControl({
    checked,
    onChange
}: {
    checked: boolean;
    onChange: ({checked}) => void;
}) {
    return (
        <>
            <HiddenCheckbox
                type="checkbox"
                checked={checked}
                onChange={(e) => onChange({checked: e.target.checked})}
            />
            {checked ? <CheckedCheckboxIcon /> : <UncheckedCheckboxIcon />}
        </>
    );
}
