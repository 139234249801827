import styled, { css } from 'styled-components';
import { RecordEditStatus } from "../../../record-editing";

export const ProjectIdCell = styled.div`
    display: flex;
    gap: .4rem;
    align-items: center;
    justify-content: space-between;
    white-space; nowrap;
`;

export const ModifiedCell = styled.td`
    background-color: rgb(255, 255, 220);
`;

export const addedCellCss = css`
    background-color: rgb(235, 255, 235);
`;

export const AddedCell = styled.td`
    ${addedCellCss}
`;

export const DeletedCell = styled.td`
    background-color: rgb(255, 220, 220);
`;

export const RegularCell = styled.td`
    background-color: white;
`;

export const errorCellCss = css`
    && {
        border: 2px solid rgb(255, 100, 100);
    }
`;

export const AddedErrorCell = styled.td`
    ${addedCellCss}
    ${errorCellCss}
`;

export function StateStyledCell(props) {
	const { editStatus, modified, hasErrors } = props;

	switch (editStatus) {
		case RecordEditStatus.New:
			if (hasErrors) return <AddedErrorCell {...props}>{props.children}</AddedErrorCell>;
			else return <AddedCell {...props}>{props.children}</AddedCell>;
		case RecordEditStatus.Deleted:
		case RecordEditStatus.NewDeleted:
			return <DeletedCell {...props}>{props.children}</DeletedCell>;
		case RecordEditStatus.Existing:
			if (modified) return <ModifiedCell {...props}>{props.children}</ModifiedCell>;
			else return <RegularCell {...props}>{props.children}</RegularCell>;
	}

	return <RegularCell {...props}>{props.children}</RegularCell>;
}

export function StateStyledDateCell(props) {
	return StateStyledCell({
		...props,
		style: { ...props.style, textAlign: 'right', whiteSpace: 'nowrap' }
	});
}

export function StateStyledNumberCell(props) {
	return StateStyledCell({
		...props,
		style: { ...props.style, textAlign: 'right', whiteSpace: 'nowrap' }
	});
}

export const offsetOneRow = {
	top: '33.5px'
};

export const offsetTwoRows = {
	top: '91px'
};

export const fixedProjectIdColumn = {
	left: '-1rem'
};

export const fixedProjectTypeColumn = {
	left: 'calc(100px - 1rem)'
};

export const fixedNameColumn = {
	left: 'calc(290px - 1rem)'
};

export const centeredContainerStyle = {
	height: '100%',
	verticalAlign: 'middle'
};
