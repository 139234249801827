import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function HasTreeSpeciesHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '120px', minWidth: '120px', maxWidth: '120px' }}>
			Has tree species
		</StickyHeader>
	)
}

export function HasTreeSpeciesFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.TreeSpeciesValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, TreeSpeciesValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.TreeSpecies ?? ''}
					onChange={(e) => updateFilter({ ...filter, TreeSpecies: e.target.value })}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function HasTreeSpeciesContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>{ppn.PlantingProject.TreeSpeciesValidated && <ValidatedIcon />}</span>
				<span>{ppn.PlantingProject.HasTreeSpecies ? 'Y' : ''}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}