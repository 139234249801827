import {
    TextFilter,
    ValidationsList,
    ComplexInputWithAsyncUpdate
} from '../../../../components/Form/FormStyles';
import {StickyDateHeader, CellWithValidator} from '../../../../components/Table/TableStyles';
import {PlantingProjectType} from '../../../../entities';
import {formatDate} from '../../../../utilities/dateUtilities';
import {EditedParticipation} from '../../../Partner/EditedPartner';
import {Filter} from '../../ParticipationFunctions/Filtering';
import {ParticipationUpdater} from '../../ParticipationFunctions/ParticipationModifications';
import {
    AlignToTextBoxContentDate,
    ValidationTippy,
    ValidationTippyContent,
    ShowOnDateControl
} from '../../Styles/ParticipationRowStyles';
import {
    offsetOneRow,
    offsetTwoRows,
    StateStyledDateCell
} from '../../Styles/ParticipationTableStyles';

export function ShowOnDateHeader() {
    return (
        <StickyDateHeader
            style={{
                ...offsetOneRow,
                textAlign: 'left',
                width: '175px',
                minWidth: '175px',
                maxWidth: '175px'
            }}>
            Show on date
        </StickyDateHeader>
    );
}

export function ShowOnDateFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <StickyDateHeader style={{...offsetTwoRows}}>
            <CellWithValidator>
                <TextFilter
                    style={{width: '150px'}}
                    value={filter?.ShowOnDate ?? ''}
                    onChange={(e) => updateFilter({...filter, ShowOnDate: e.target.value})}
                />
            </CellWithValidator>
        </StickyDateHeader>
    );
}

export function ShowOnDateContent({
    ppn,
    updater
}: {
    ppn: EditedParticipation;
    updater: ParticipationUpdater;
}) {
    return (
        <StateStyledDateCell editStatus={ppn.EditStatus}>
            <CellWithValidator>
                {ppn.ControlledByAutomation ||
                ppn.ProjectType === PlantingProjectType.CommunityCanopy ? (
                    <AlignToTextBoxContentDate>
                        {formatDate(ppn.ShowOnDate.current)}
                    </AlignToTextBoxContentDate>
                ) : (
                    <ValidationTippy
                        content={
                            <ValidationTippyContent>
                                <ValidationsList validations={ppn.ShowOnDate.validations} />
                            </ValidationTippyContent>
                        }
                        disabled={ppn.ShowOnDate.validations.length === 0}>
                        <div>
                            <ComplexInputWithAsyncUpdate
                                Control={ShowOnDateControl}
                                showModification
                                value={ppn.ShowOnDate}
                                onChange={(e) => updater.update({...ppn, ShowOnDate: e})}
                                valueGetter={(e) =>
                                    ppn.ShowOnDate.update(e.target.value)
                                }></ComplexInputWithAsyncUpdate>
                        </div>
                    </ValidationTippy>
                )}
            </CellWithValidator>
        </StateStyledDateCell>
    );
}
