import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { StickyHeader } from "../../../../components/Table/TableStyles";
import { TextFilter } from "../../../../components/Form/FormStyles";

export function TTFCommitmentsHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '160px',
				minWidth: '160px',
				maxWidth: '160px'
			}}>
			TTF commitments
		</StickyHeader>
	)
}

export function TTFCommitmentsFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<TextFilter
				value={filter?.TTFCommitments ?? ''}
				onChange={(e) =>
					updateFilter({ ...filter, TTFCommitments: e.target.value })
				}
			/>
		</StickyHeader>
	)
}

export function TTFCommitmentsContent({ ppn, treeHarmonyBaseUrl }: { ppn: EditedParticipation, treeHarmonyBaseUrl: string }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			{ppn.PlantingProject.Commitments?.map((commitment, i) => [
				i > 0 && ', ',
				<a
					key={i}
					href={`${treeHarmonyBaseUrl}/Commitments/Detail/${commitment.CommitmentId}`}
					target="_blank"
					rel="noopener noreferrer">
					{commitment.CommitmentId}
				</a>
			])}
		</StateStyledCell>
	)
}