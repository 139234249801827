import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ProjectLocationHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '355px', minWidth: '355px', maxWidth: '355px' }}>
			Location
		</StickyHeader>
	)
}

export function ProjectLocationFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.LocationValidated ?? ValidateFilterValue.Unset}
					onChange={(e) => updateFilter({ ...filter, LocationValidated: e.value })}
				/>
				<TextFilter
					value={filter?.Location ?? ''}
					onChange={(e) => updateFilter({ ...filter, Location: e.target.value })}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function ProjectLocationContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>{ppn.PlantingProject.LocationValidated && <ValidatedIcon />}</span>
				<span>{ppn.PlantingProject.Location}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}