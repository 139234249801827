import { ValidateFilter, ValidateFilterValue, ValidatedIcon } from "../../../../components/Controls/ValidateControl"
import { TextFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader, CellWithValidator } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles"

export function ImpactStoryCompletedHeader() {
	return (
		<StickyHeader
			style={{ ...offsetOneRow, width: '120px', minWidth: '120px', maxWidth: '120px' }}>
			Impact story - completed
		</StickyHeader>
	)
}

export function ImpactStoryCompletedFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.ImpactStoryCompletedValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, ImpactStoryCompletedValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.HasImpactStoryCompleted ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, HasImpactStoryCompleted: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function ImpactStoryCompletedContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus} modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.ImpactStoryCompletedValidated && <ValidatedIcon />}
				</span>
				<span>{ppn.PlantingProject.HasImpactStoryCompleted ? 'Y' : ''}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}