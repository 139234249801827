import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    ValidationsList,
    NumberControlShowingEdits,
    FormFieldContainer
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const NumericFormFieldContainer = styled(FormFieldContainer)`
    & > input {
        max-width: 200px;
    }
`;

export function NumberOfCorporateVolunteersControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <NumericFormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.CommunityNumberOfCorporateVolunteersValidated.current}
                        disabled={
                            project.CommunityNumberOfCorporateVolunteers.notValidToShowPartner
                        }
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CommunityNumberOfCorporateVolunteersValidated:
                                    project.CommunityNumberOfCorporateVolunteersValidated.update(
                                        e.validated
                                    )
                            })
                        }
                    />
                )}
                <Label htmlFor="numberOfCorporateVolunteers">Number of corporate volunteers</Label>
            </LabelContainer>
            <NumberControlShowingEdits
                id="numberOfCorporateVolunteers"
                disabled={project.ControlledByAutomation}
                value={project.CommunityNumberOfCorporateVolunteers}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        CommunityNumberOfCorporateVolunteers:
                            project.CommunityNumberOfCorporateVolunteers.update(
                                getNumberFromInput(e)
                            )
                    })
                }></NumberControlShowingEdits>
            <ValidationsList
                validations={project.CommunityNumberOfCorporateVolunteers.validations}
            />
        </NumericFormFieldContainer>
    );
}
