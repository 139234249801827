import {useEffect, useState, createContext, useContext} from 'react';
import {useInterval} from '../useInterval';

const UserContext = createContext({userInfoRetrieved: false, user: null});

export function UserProvider({children}: {children: React.ReactNode}) {
    const [user, setUser] = useState({userInfoRetrieved: false, user: null});

    const getUser = async () => {
        const {clientPrincipal} = await (await fetch('/.auth/me')).json();
        setUser({userInfoRetrieved: true, user: clientPrincipal});
    };

    useEffect(() => {
        getUser();
    }, []);

    useInterval(() => {
        getUser();
    }, 30000);

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser() {
    return useContext(UserContext);
}

export function UserRoles(): string[] {
    const userInfo = useUser();
    return userInfo.user.userRoles;
}
