import {formatProjectType} from '../../../entities';
import {ReadOnlyProperty, PropertyName, LabelContainer} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';

export function ProjectTypeControl({
    project,
    setProjectAndValidate,
    hideValidateControl
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    hideValidateControl?: boolean;
}) {
    return (
        <LabelContainer>
            {!project.ControlledByAutomation && !hideValidateControl && (
                <ValidateControl
                    validated={project.ProjectTypeValidated.current}
                    disabled={project.ProjectType.notValidToShowPartner}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            ProjectTypeValidated: project.ProjectTypeValidated.update(e.validated)
                        })
                    }
                />
            )}
            <ReadOnlyProperty>
                <PropertyName>Project type: </PropertyName>
                {formatProjectType(project.ProjectType.current)}
            </ReadOnlyProperty>
        </LabelContainer>
    );
}
