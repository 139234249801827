import styled from 'styled-components';
import {deleteStatus, EditedField, RecordEditStatus} from '../../record-editing';
import {CheckboxControl} from '../../components/Controls/CheckboxControl';
import {
    CheckboxLabel,
    FormFieldContainer,
    Label,
    LabelContainer,
    PropertyName,
    ReadOnlyProperty,
    RequiredSignifier,
    RequiredToShowToPartnerSignifier,
    TextAreaControlShowingEdits,
    TextControlShowingEdits,
    ValidationsList
} from '../../components/Form/FormStyles';
import {EditedPartner} from './EditedPartner';
import {ArcGISLogin, D365Account, NavCustomer} from '../../entities';
import D365AccountList from './PartnerLinks/D365AccountList';
import NavCustomerList from './PartnerLinks/NavCustomerList';
import ArcGISLoginControl from './ArcGISLoginControl';

const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`;

const StyledTitle = styled.div`
    font-size: 1rem;
    font-weight: 600;
`;

const NameContainer = styled(FormFieldContainer)`
    max-width: 500px;
`;

const ExpandedPartnerStatement = styled.pre`
    padding: 0.375rem 0.75rem;
`;

const GeneralTabSection = styled.div`
    gap: 0.5rem;
    flex-direction: column;
    display: flex;
`;

function GeneralPartnerInfo({
    partner,
    setPartnerAndValidate,
    focused
}: {
    partner: EditedPartner;
    setPartnerAndValidate: (partner: EditedPartner) => void;
    focused?: boolean;
}) {
    function addAccount(account: D365Account) {
        const updatedPartner = {
            ...partner,
            D365Accounts: [...partner.D365Accounts, {...account, EditStatus: RecordEditStatus.New}]
        };
        setPartnerAndValidate(updatedPartner);
    }

    function removeAccount(account: D365Account) {
        const updatedAccounts = partner.D365Accounts.map((a) =>
            a.AccountId === account.AccountId ? {...a, EditStatus: deleteStatus(a.EditStatus)} : a
        );
        const updatedPartner = {...partner, D365Accounts: updatedAccounts};
        setPartnerAndValidate(updatedPartner);
    }

    function addCustomer(customer: NavCustomer) {
        const updatedPartner = {
            ...partner,
            NavCustomers: [...partner.NavCustomers, {...customer, EditStatus: RecordEditStatus.New}]
        };
        setPartnerAndValidate(updatedPartner);
    }

    function removeCustomer(customer: NavCustomer) {
        const updatedAccounts = partner.NavCustomers.map((c) =>
            c.CustomerId === customer.CustomerId
                ? {...c, EditStatus: deleteStatus(c.EditStatus)}
                : c
        );
        const updatedPartner = {...partner, NavCustomers: updatedAccounts};
        setPartnerAndValidate(updatedPartner);
    }

    function handleNameChanged(e) {
        const newName = e.target.value as string;
        const newPartner = {
            ...partner,
            Name: partner.Name.update(newName),
            PartnerStatement: partner.PartnerStatement.update(
                partner.PartnerStatementTemplate.current?.replaceAll('{{Name}}', newName)
            )
        };
        setPartnerAndValidate(newPartner);
    }

    function handlePartnerStatementTemplateChanged(e) {
        const newTemplate = e.target.value as string;
        setPartnerAndValidate({
            ...partner,
            PartnerStatementTemplate: partner.PartnerStatementTemplate.update(newTemplate),
            PartnerStatement: partner.PartnerStatement.update(
                newTemplate?.replaceAll('{{Name}}', partner.Name.current)
            )
        });
    }

    function handleArcGISLoginChanged(updatedLogin: ArcGISLogin) {
        const newPartner = {
            ...partner,
            ArcGISUsername: partner.ArcGISUsername.update(updatedLogin.ArcGISUsername),
            ArcGISPassword: partner.ArcGISPassword.update(updatedLogin.ArcGISPassword)
        };
        setPartnerAndValidate(newPartner);
    }

    function handleArcGISLoginCreated() {
        const newPartner = {
            ...partner,
            ArcGISUsername: new EditedField(partner.ArcGISUsername.current),
            ArcGISPassword: new EditedField(partner.ArcGISPassword.current)
        };
        setPartnerAndValidate(newPartner);
    }

    function handleArcGISPasswordChanged() {
        const newPartner = {
            ...partner,
            ArcGISUsername: new EditedField(partner.ArcGISUsername.current),
            ArcGISPassword: new EditedField(partner.ArcGISPassword.current)
        };
        setPartnerAndValidate(newPartner);
    }

    return (
        <Form>
            <NameContainer>
                <LabelContainer>
                    <StyledTitle>
                        <Label htmlFor="name">Name</Label>
                    </StyledTitle>
                    <RequiredSignifier />
                </LabelContainer>
                <TextControlShowingEdits
                    id="name"
                    noshow
                    required
                    maxLength={100}
                    value={partner.Name}
                    onChange={handleNameChanged}
                    autoFocus={focused}
                />
                <ValidationsList validations={partner.Name.validations} />
            </NameContainer>
            <FormFieldContainer>
                <ReadOnlyProperty>
                    <PropertyName>Logo DAM asset id: </PropertyName>
                    {partner.LogoDAMAssetId.current}
                </ReadOnlyProperty>
            </FormFieldContainer>
            <GeneralTabSection>
                <StyledTitle>D365 accounts</StyledTitle>
                <D365AccountList
                    partnerId={partner.Id}
                    accounts={partner.D365Accounts}
                    addAccount={addAccount}
                    removeAccount={removeAccount}
                />
            </GeneralTabSection>
            <GeneralTabSection>
                <StyledTitle>Nav customers</StyledTitle>
                <NavCustomerList
                    customers={partner.NavCustomers}
                    addCustomer={addCustomer}
                    removeCustomer={removeCustomer}
                />
            </GeneralTabSection>
            {partner.EditStatus !== RecordEditStatus.New && (
                <ArcGISLoginControl
                    partnerId={partner.Id}
                    partnerName={partner.Name.current}
                    arcGISUsername={partner.ArcGISUsername}
                    arcGISPassword={partner.ArcGISPassword}
                    onChange={handleArcGISLoginChanged}
                    onLoginCreated={handleArcGISLoginCreated}
                    onPasswordChanged={handleArcGISPasswordChanged}
                />
            )}
            <FormFieldContainer style={{marginTop: '5px'}}>
                <LabelContainer>
                    <StyledTitle>
                        <Label htmlFor="partnerStatement">Partner statement</Label>
                    </StyledTitle>
                    <RequiredToShowToPartnerSignifier />
                </LabelContainer>
                <TextAreaControlShowingEdits
                    id="partnerStatement"
                    noshow
                    maxLength={4000}
                    rows={5}
                    value={partner.PartnerStatementTemplate}
                    onChange={handlePartnerStatementTemplateChanged}
                />
                <ValidationsList validations={partner.PartnerStatementTemplate.validations} />
                <ExpandedPartnerStatement>
                    {partner.PartnerStatement.current}
                </ExpandedPartnerStatement>
            </FormFieldContainer>
            {partner.EditStatus !== RecordEditStatus.New && (
                <FormFieldContainer>
                    <LabelContainer>
                        <CheckboxLabel>
                            <CheckboxControl
                                checked={partner.PartnerLive.current}
                                onChange={(e) =>
                                    setPartnerAndValidate({
                                        ...partner,
                                        PartnerLive: partner.PartnerLive.update(e.checked)
                                    })
                                }
                            />
                            Partner live
                        </CheckboxLabel>
                    </LabelContainer>
                </FormFieldContainer>
            )}
            <FormFieldContainer>
                <LabelContainer>
                    <CheckboxLabel>
                        <CheckboxControl
                            checked={partner.Archived.current}
                            onChange={(e) =>
                                setPartnerAndValidate({
                                    ...partner,
                                    Archived: partner.Archived.update(e.checked)
                                })
                            }
                        />
                        Archived
                    </CheckboxLabel>
                </LabelContainer>
            </FormFieldContainer>
            <div></div>
        </Form>
    );
}

export default GeneralPartnerInfo;
