import {
    LabelContainer,
    Label,
    TextControlShowingEdits,
    ValidationsList,
    SubSectionHeader,
    FormFieldContainer
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';

export const SiteCityStatePostalContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const SiteMediumLengthContainer = styled(FormFieldContainer)`
    width: 400px;
`;

export const SiteCodeContainer = styled(FormFieldContainer)`
    width: 150px;
`;

export function SiteSection({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <>
            <SubSectionHeader>Community site</SubSectionHeader>
            <SiteMediumLengthContainer>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.CommunitySiteNameValidated.current}
                            disabled={project.CommunitySiteName.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    CommunitySiteNameValidated:
                                        project.CommunitySiteNameValidated.update(e.validated)
                                })
                            }
                        />
                    )}
                    <Label htmlFor="siteName">Name</Label>
                </LabelContainer>
                <TextControlShowingEdits
                    id="siteName"
                    disabled={project.ControlledByAutomation}
                    maxLength={500}
                    value={project.CommunitySiteName}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            CommunitySiteName: project.CommunitySiteName.update(e.target.value)
                        })
                    }></TextControlShowingEdits>
                <ValidationsList validations={project.CommunitySiteName.validations} />
            </SiteMediumLengthContainer>
            <SiteMediumLengthContainer>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.CommunitySiteAddressValidated.current}
                            disabled={project.CommunitySiteAddress.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    CommunitySiteAddressValidated:
                                        project.CommunitySiteAddressValidated.update(e.validated)
                                })
                            }
                        />
                    )}
                    <Label htmlFor="siteAddress">Address</Label>
                </LabelContainer>
                <TextControlShowingEdits
                    id="siteAddress"
                    disabled={project.ControlledByAutomation}
                    maxLength={500}
                    value={project.CommunitySiteAddress}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            CommunitySiteAddress: project.CommunitySiteAddress.update(
                                e.target.value
                            )
                        })
                    }></TextControlShowingEdits>
                <ValidationsList validations={project.CommunitySiteAddress.validations} />
            </SiteMediumLengthContainer>
            <SiteCityStatePostalContainer>
                <SiteMediumLengthContainer>
                    <LabelContainer>
                        {!project.ControlledByAutomation && (
                            <ValidateControl
                                validated={project.CommunitySiteCityValidated.current}
                                disabled={project.CommunitySiteCity.notValidToShowPartner}
                                onChange={(e) =>
                                    setProjectAndValidate({
                                        ...project,
                                        CommunitySiteCityValidated:
                                            project.CommunitySiteCityValidated.update(e.validated)
                                    })
                                }
                            />
                        )}
                        <Label htmlFor="siteCity">City</Label>
                    </LabelContainer>
                    <TextControlShowingEdits
                        id="siteCity"
                        disabled={project.ControlledByAutomation}
                        maxLength={500}
                        value={project.CommunitySiteCity}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CommunitySiteCity: project.CommunitySiteCity.update(e.target.value)
                            })
                        }></TextControlShowingEdits>
                    <ValidationsList validations={project.CommunitySiteCity.validations} />
                </SiteMediumLengthContainer>
                <SiteCodeContainer>
                    <LabelContainer>
                        {!project.ControlledByAutomation && (
                            <ValidateControl
                                validated={project.CommunitySiteStateCodeValidated.current}
                                disabled={project.CommunitySiteStateCode.notValidToShowPartner}
                                onChange={(e) =>
                                    setProjectAndValidate({
                                        ...project,
                                        CommunitySiteStateCodeValidated:
                                            project.CommunitySiteStateCodeValidated.update(
                                                e.validated
                                            )
                                    })
                                }
                            />
                        )}
                        <Label htmlFor="siteStateCode">State code</Label>
                    </LabelContainer>
                    <TextControlShowingEdits
                        id="siteStateCode"
                        disabled={project.ControlledByAutomation}
                        maxLength={100}
                        value={project.CommunitySiteStateCode}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CommunitySiteStateCode: project.CommunitySiteStateCode.update(
                                    e.target.value
                                )
                            })
                        }></TextControlShowingEdits>
                    <ValidationsList validations={project.CommunitySiteStateCode.validations} />
                </SiteCodeContainer>
                <SiteCodeContainer>
                    <LabelContainer>
                        {!project.ControlledByAutomation && (
                            <ValidateControl
                                validated={project.CommunitySitePostalCodeValidated.current}
                                disabled={project.CommunitySitePostalCode.notValidToShowPartner}
                                onChange={(e) =>
                                    setProjectAndValidate({
                                        ...project,
                                        CommunitySitePostalCodeValidated:
                                            project.CommunitySitePostalCodeValidated.update(
                                                e.validated
                                            )
                                    })
                                }
                            />
                        )}
                        <Label htmlFor="sitePostalCode">Postal code</Label>
                    </LabelContainer>
                    <TextControlShowingEdits
                        id="sitePostalCode"
                        disabled={project.ControlledByAutomation}
                        maxLength={20}
                        value={project.CommunitySitePostalCode}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                CommunitySitePostalCode: project.CommunitySitePostalCode.update(
                                    e.target.value
                                )
                            })
                        }></TextControlShowingEdits>
                    <ValidationsList validations={project.CommunitySitePostalCode.validations} />
                </SiteCodeContainer>
            </SiteCityStatePostalContainer>
            <SiteCodeContainer>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.CommunitySiteCountryCodeValidated.current}
                            disabled={project.CommunitySiteCountryCode.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    CommunitySiteCountryCodeValidated:
                                        project.CommunitySiteCountryCodeValidated.update(
                                            e.validated
                                        )
                                })
                            }
                        />
                    )}
                    <Label htmlFor="siteCountryCode">Country code</Label>
                </LabelContainer>
                <TextControlShowingEdits
                    id="siteCountryCode"
                    disabled={project.ControlledByAutomation}
                    maxLength={2}
                    value={project.CommunitySiteCountryCode}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            CommunitySiteCountryCode: project.CommunitySiteCountryCode.update(
                                e.target.value
                            )
                        })
                    }></TextControlShowingEdits>
                <ValidationsList validations={project.CommunitySiteCountryCode.validations} />
            </SiteCodeContainer>
        </>
    );
}
