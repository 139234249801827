import styled from 'styled-components';

const VerticalExpandCollapseLine = styled.div`
    position: relative;
    width: 2px;
    background-color: rgb(220, 220, 220);
    z-index: 0;
`;

const VerticalExpandCollapseGrabber = styled.div`
    position: absolute;
    width: 10px;
    margin-left: -4px;
    height: 100%;
    cursor: col-resize;
    z-index: 1;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export function VerticalExpandCollapse(props) {
    return (
        <VerticalExpandCollapseLine {...props}>
            <VerticalExpandCollapseGrabber {...props} />
        </VerticalExpandCollapseLine>
    );
}

const HorizontalExpandCollapseLine = styled.div`
    position: relative;
    height: 2px;
    background-color: rgb(220, 220, 220);
    z-index: 0;
`;

const HorizontalExpandCollapseGrabber = styled.div`
    position: absolute;
    height: 10px;
    margin-top: -4px;
    width: 100%;
    cursor: row-resize;
    z-index: 1;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export function HorizontalExpandCollapse(props) {
    return (
        <HorizontalExpandCollapseLine {...props}>
            <HorizontalExpandCollapseGrabber {...props} />
        </HorizontalExpandCollapseLine>
    );
}
