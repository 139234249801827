import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    NumberControlShowingEdits,
    ValidationsList,
    RequiredToShowToPartnerSignifier
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import {NumericFormFieldContainer} from './NumericFormFieldContainer';

export function CommunityDBHTreesDistributedControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <>
            <NumericFormFieldContainer>
                <LabelContainer>
                    {!project.ControlledByAutomation && (
                        <ValidateControl
                            validated={project.CommunityDBHTreesDistributedValidated.current}
                            disabled={project.CommunityDBHTreesDistributed.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    CommunityDBHTreesDistributedValidated:
                                        project.CommunityDBHTreesDistributedValidated.update(
                                            e.validated
                                        )
                                })
                            }
                        />
                    )}
                    <Label htmlFor="dbhEventTreesDistributed">
                        Tree diameter (DBH) - distributed
                    </Label>
                    <RequiredToShowToPartnerSignifier />
                </LabelContainer>
                <NumberControlShowingEdits
                    id="dbhEventTreesDistributed"
                    disabled={project.ControlledByAutomation}
                    value={project.CommunityDBHTreesDistributed}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            CommunityDBHTreesDistributed:
                                project.CommunityDBHTreesDistributed.update(getNumberFromInput(e))
                        })
                    }></NumberControlShowingEdits>
                <ValidationsList validations={project.CommunityDBHTreesDistributed.validations} />
            </NumericFormFieldContainer>
        </>
    );
}
