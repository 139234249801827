import {CommunityTreesPlantedHeader} from '../../ParticipationColumns/CommunityEvents/CommunityTreesPlanted';
import {CommunityBudgetPassThroughHeader} from '../../ParticipationColumns/CommunityEvents/CommunityBudgetPassThrough';
import {CommunitySiteNameHeader} from '../../ParticipationColumns/CommunityEvents/CommunitySiteName';
import {CommunitySiteAddressHeader} from '../../ParticipationColumns/CommunityEvents/CommunitySiteAddress';
import {CommunitySiteCityHeader} from '../../ParticipationColumns/CommunityEvents/CommunitySiteCity';
import {CommunitySiteStateHeader} from '../../ParticipationColumns/CommunityEvents/CommunitySiteState';
import {CommunitySitePostalCodeHeader} from '../../ParticipationColumns/CommunityEvents/CommunitySitePostalCode';
import {CommunitySiteCountryHeader} from '../../ParticipationColumns/CommunityEvents/CommunitySiteCountry';
import {CommunityEventDatesHeader} from '../../ParticipationColumns/CommunityEvents/CommunityEventDates';
import {CommunityCorpVolunteersHeader} from '../../ParticipationColumns/CommunityEvents/CommunityCorpVolunteers';
import {CommunityTotalParticipantsHeader} from '../../ParticipationColumns/CommunityEvents/CommunityTotalVolunteers';
import {ProjectCommunityTreesPlantedHeader} from '../../ParticipationColumns/CommunityEvents/ProjectCommunityTreesPlanted';
import {ProjectCommunityTreesDistributedHeader} from '../../ParticipationColumns/CommunityEvents/ProjectCommunityTreesDistributed';
import {CommunityPlantedDBHHeader} from '../../ParticipationColumns/CommunityEvents/CommunityPlantedDBH';
import {CommunityDistributedDBHHeader} from '../../ParticipationColumns/CommunityEvents/CommunityDistributedDBH';
import {CommunityTreesDistributedHeader} from '../../ParticipationColumns/Shared/CommunityTreesDistributed';
import {DAMTagHeader} from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationHeader} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedHeader} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedHeader} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedHeader} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearHeader} from '../../ParticipationColumns/Shared/FundedYear';
import {HasHeroHeader} from '../../ParticipationColumns/Shared/HasHero';
import {HasTreeSpeciesHeader} from '../../ParticipationColumns/Shared/HasTreeSpecies';
import {ImageCountHeader} from '../../ParticipationColumns/Shared/ImageCount';
import {ImagesValidatedHeader} from '../../ParticipationColumns/Shared/ImagesValidated';
import {ImpactStoryCompletedHeader} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ImpactStoryInProgressHeader} from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import {MapHeader} from '../../ParticipationColumns/Shared/Map';
import {ParticipationIdHeader} from '../../ParticipationColumns/Shared/ParticipationId';
import {PlantingPartnerHeader} from '../../ParticipationColumns/Shared/PlantingPartner';
import {ProjectIdHeader} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectLocationHeader} from '../../ParticipationColumns/Shared/ProjectLocation';
import {ProjectNameHeader} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectStatusHeader} from '../../ParticipationColumns/Shared/ProjectStatus';
import {ProjectTypeHeader} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ReasonForPlantingHeader} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {ShowOnDateHeader} from '../../ParticipationColumns/Shared/ShowOnDate';
import {FundingSourceHeader} from '../../ParticipationColumns/Shared/FundingSource';

export function CommunityEventHeader() {
    return (
        <tr>
            <DeleteParticipationHeader />
            <ExcludedHeader />
            <EverythingValidatedHeader />
            <ParticipationIdHeader />
            <FundedYearHeader />
            <FundingSourceHeader />
            <CommunityTreesPlantedHeader />
            <CommunityTreesDistributedHeader />
            <DollarDonatedHeader />
            <CommunityBudgetPassThroughHeader />
            <ShowOnDateHeader />
            <ProjectIdHeader />
            <ProjectTypeHeader />
            <ProjectNameHeader />
            <ProjectStatusHeader />
            <ProjectLocationHeader />
            <CommunitySiteNameHeader />
            <CommunitySiteAddressHeader />
            <CommunitySiteCityHeader />
            <CommunitySiteStateHeader />
            <CommunitySitePostalCodeHeader />
            <CommunitySiteCountryHeader />
            <MapHeader />
            <CommunityEventDatesHeader />
            <ReasonForPlantingHeader />
            <ImpactStoryInProgressHeader />
            <ImpactStoryCompletedHeader />
            <PlantingPartnerHeader />
            <CommunityCorpVolunteersHeader />
            <CommunityTotalParticipantsHeader />
            <ProjectCommunityTreesPlantedHeader />
            <ProjectCommunityTreesDistributedHeader />
            <CommunityPlantedDBHHeader />
            <CommunityDistributedDBHHeader />
            <HasTreeSpeciesHeader />
            <DAMTagHeader />
            <ImagesValidatedHeader />
            <ImageCountHeader />
            <HasHeroHeader />
        </tr>
    );
}
