import styled from 'styled-components';
import PlantingProjectSearch from '../containers/PlantingProjectSearch/PlantingProjectSearch';
import {ActionContainer, ActionList} from '../components/Buttons/SaveChanges';
import {UserRoles} from '../providers/UserProvider';
import { AddProjectMenu } from '../components/AddProjectMenu/AddProjectMenu';

const PositionedActionContainer = styled(ActionContainer)`
    position: absolute;
    top: 3.6rem;
    right: 2rem;
`;

function PlantingProjects() {
    return (
        <>
            <PlantingProjectSearch />
            {UserRoles().includes('editor') && (
                <PositionedActionContainer>
                    <ActionList>
                        <AddProjectMenu/>
                    </ActionList>
                </PositionedActionContainer>
            )}
        </>
    );
}

export default PlantingProjects;
