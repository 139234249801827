import Tippy from "@tippyjs/react"
import { NumericFilter } from "../../../../components/Form/FormStyles"
import { StickyHeader } from "../../../../components/Table/TableStyles"
import { EditedParticipation } from "../../../Partner/EditedPartner"
import { Filter } from "../../ParticipationFunctions/Filtering"
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles"

export function ImageCountHeader() {
	return (
		<Tippy content="Filtering can be done with <>. Example: >3 or <4">
			<StickyHeader
				style={{
					...offsetOneRow,
					width: '70px',
					minWidth: '70px',
					maxWidth: '70px'
				}}>
				Image count
			</StickyHeader>
		</Tippy>
	)
}

export function ImageCountFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<NumericFilter
				value={filter?.ImageCount ?? ''}
				onChange={(e) => updateFilter({ ...filter, ImageCount: e.target.value })}
			/>
		</StickyHeader>
	)
}

export function ImageCountContent({ ppn, imageCount }: { ppn: EditedParticipation, imageCount: number }) {
	return (
		<StateStyledNumberCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<span>{imageCount}</span>
		</StateStyledNumberCell>
	)
}