// participation row styles
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import {
    DateControlShowingEdits,
    NumberControlShowingEdits
} from '../../../components/Form/FormStyles';

export const TreeCountControl = styled(NumberControlShowingEdits)`
    text-align: right;
`;

export const DollarsControl = styled(NumberControlShowingEdits)`
    text-align: right;
`;

export const FundedYearControl = styled(NumberControlShowingEdits)`
    text-align: right;
`;

export const ShowOnDateControl = styled(DateControlShowingEdits)`
    width: 150px;
`;

export const ValidationTippy = styled(Tippy)``;

export const ValidationTippyContent = styled.div`
    && div {
        color: white;
    }
`;

export const AlignToTextBoxContent = styled.div`
    padding-right: 13px;
`;

export const AlignToTextBoxContentDate = styled.div`
    padding-left: 14px;
`;

export const AlignToTextBoxContentText = styled.div`
    padding-left: 12px;
`;
