import Tippy from '@tippyjs/react';
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { Validation, ValidationLevel } from '../../record-editing';

export const SectionList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const SectionHeader = styled.h3`
    margin: 0;
    font-weight: 600;
    font-size: 1.3rem;
`;

export const SubSectionHeader = styled.h4`
    // margin: 0 0 -.5rem 0;
    margin: 0;
    font-weight: 600;
    font-size: 1.1rem;
`;

export const FormFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const Label = styled.label``;

export const CheckboxLabel = styled.label`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`;

export const LabelContainer = styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`;

export const HeaderContainer = styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`;

export const baseFormControlCss = css`
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;

    &:invalid {
        border-color: rgb(255, 100, 100);
    }

    &:disabled {
        color: rgb(150, 150, 150);
        background-color: rgb(250, 250, 250);
        opacity: 1;
    }
`;

const readOnlyFormControlCss = css`
    &:read-only {
        color: rgb(150, 150, 150);
        opacity: 1;
    }
`;

export const modifiedFormControlCss = css`
    background-color: rgb(255, 255, 220);
`;

export const notValidToSaveFormControlCss = css`
    border-color: rgb(255, 100, 100);
`;

export const notValidToShowPartnerFormControlCss = css`
    border-color: rgb(255, 147, 69);
`;

export const warningFormControlCss = css`
    border-color: rgb(145, 203, 232);
`;

export const stateFormControlCss = css<{
	hasBeenModified?: boolean;
	showModification?: boolean;
	hasWarning?: boolean;
	notValidToSave?: boolean;
	notValidToShowPartner?: boolean;
}>`
    ${baseFormControlCss}
    ${(props) => props.hasBeenModified && props.showModification && modifiedFormControlCss}
    ${(props) => props.hasWarning && warningFormControlCss}
    ${(props) => props.notValidToShowPartner && notValidToShowPartnerFormControlCss}
    ${(props) => props.notValidToSave && notValidToSaveFormControlCss}
`;

export const InputControl = styled.input`
    ${stateFormControlCss}
`;

export const TextControl = styled(InputControl).attrs({
	type: 'text'
})`
    ${readOnlyFormControlCss}
`;

export const NumberControl = styled(InputControl).attrs({
	type: 'number'
})`
    ${readOnlyFormControlCss}
`;

export const DateControl = styled(InputControl).attrs({
	type: 'date'
})`
    ${readOnlyFormControlCss}
`;

export const SearchControl = styled(InputControl).attrs({
	type: 'search',
	placeholder: 'Search'
})`
    ${readOnlyFormControlCss}
`;

export const SelectControl = styled.select`
    ${stateFormControlCss}
`;

export const TextAreaControl = styled.textarea`
    ${stateFormControlCss}
    resize: vertical;
    ${readOnlyFormControlCss}
`;

export function ControlShowingEdits(props) {
	const value = props.value;
	const Control = props.control;

	return (
		<Control
			{...props}
			value={value.current ?? ''}
			hasBeenModified={value.modified}
			hasWarning={value.hasWarning}
			notValidToSave={value.notValidToSave}
			notValidToShowPartner={value.notValidToShowPartner}
		/>
	);
}

export function TextControlShowingEdits(props) {
	return <ControlShowingEdits control={TextControl} {...props} />;
}

export function NumberControlShowingEdits(props) {
	return <ControlShowingEdits control={NumberControl} {...props} />;
}

export function DateControlShowingEdits(props) {
	return <ControlShowingEdits control={DateControl} {...props} />;
}

export function SelectControlShowingEdits(props) {
	return <ControlShowingEdits control={SelectControl} {...props} />;
}

export function TextAreaControlShowingEdits(props) {
	return <ControlShowingEdits control={TextAreaControl} {...props} />;
}

export function ComplexInputWithAsyncUpdate({ Control, value, onChange, valueGetter, ...props }) {
	const [localValue, setLocalValue] = useState(value);

	const localOnChange = useCallback(
		(e) => {
			const updatedValue = valueGetter(e);
			setLocalValue(updatedValue);
			onChange(updatedValue);
		},
		[onChange]
	);

	useEffect(() => {
		if (value !== undefined && value !== localValue) setLocalValue(value);
	}, [value]);

	return <Control {...props} value={localValue} onChange={localOnChange} />;
}

export function InputWithAsyncUpdate({ Control, value, onChange, ...props }) {
	const [localValue, setLocalValue] = useState(value);
	const localOnChange = useCallback(
		(e) => {
			setLocalValue(e.target.value);
			onChange(e.target.value);
		},
		[onChange]
	);

	useEffect(() => {
		if (value !== undefined && value !== localValue) setLocalValue(value);
	}, [value]);

	return <Control {...props} value={localValue} onChange={localOnChange} />;
}

export const StyledRequiredSignifier = styled.span`
    color: rgb(255, 100, 100);
`;

export function RequiredSignifier() {
	return (
		<Tippy placement="right" content="Required to save changes">
			<StyledRequiredSignifier>*</StyledRequiredSignifier>
		</Tippy>
	);
}

export const StyledRequiredToShowToPartnerSignifier = styled.span`
    color: rgb(255, 147, 69);
`;

export function RequiredToShowToPartnerSignifier() {
	return (
		<Tippy placement="right" content="Required to show to partner">
			<StyledRequiredToShowToPartnerSignifier>*</StyledRequiredToShowToPartnerSignifier>
		</Tippy>
	);
}

export const notValidToSaveCss = css`
    color: rgb(220, 53, 69);
`;

export const NotValidToSaveValidation = styled.div`
    ${notValidToSaveCss}
`;

export const notValidToShowToPartnerCss = css`
    color: rgb(220, 114, 49);
`;

export const NotValidToShowToPartnerValidation = styled.div`
    ${notValidToShowToPartnerCss}
`;

export const warningValidationCss = css`
    color: rgb(125, 174, 192);
`;

export const WarningValidation = styled.div`
    ${warningValidationCss}
`;

export function ValidationsList({ validations }: { validations: Validation[] }) {
	return (
		<div>
			{validations?.map((validation, i) => {
				switch (validation.level) {
					case ValidationLevel.NotValidToSave:
						return (
							<NotValidToSaveValidation key={i}>
								{validation.message}
							</NotValidToSaveValidation>
						);
					case ValidationLevel.NotValidToShowPartner:
						return (
							<NotValidToShowToPartnerValidation key={i}>
								{validation.message}
							</NotValidToShowToPartnerValidation>
						);
					case ValidationLevel.Warning:
						return <WarningValidation key={i}>{validation.message}</WarningValidation>;
					default:
						return null;
				}
			})}
		</div>
	);
}

export const ReadOnlyProperty = styled.div``;

export const PropertyName = styled.span`
    color: rgb(140, 140, 140);
`;

export const TextFilter = styled.input`
    ${baseFormControlCss}
    margin-top: .2rem;
    padding: 0.2rem 0.4rem;
    font-size: 85%;
`;

export const NumericFilter = styled(TextFilter)`
    text-align: right;
`;

export const Button = styled.button`
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;
`;
