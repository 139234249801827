import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { usePrompt } from '../../react-router-additions';
import {
	EditedProject,
	initializeEditedProject,
	initializeNewProject,
	setValidations,
	hasChanges,
	getNotValidToSave,
	updateCompletedProject
} from './EditedProject';
import { UserRoles } from '../../providers/UserProvider';
import { PlantingProjectType } from '../../entities';
import { ReforestationProject } from './Reforestation/ReforestationProject';
import { CommunityProject } from './Community/CommunityProject';
import { CarbonProject } from './Carbon/CarbonProject';
import { CommunityCanopyProject } from './CommunityCanopy/CommunityCanopyProject';
import { ActionControls } from './Actions/ActionControls';

const ProjectLayout = styled.div`
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow: auto;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

function PlantingProject({
    project,
    setProjectAndValidate,
    completedProjectSetProjectAndValidate,
    newProject,
    navigationPromptActive,
    setNavigationPromptActive,
    registerSave,
    registerHasChanges,
    hasChangesUpdated
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    completedProjectSetProjectAndValidate: (project: EditedProject) => void;
    newProject?: boolean;
    navigationPromptActive: boolean;
    setNavigationPromptActive: (navigationPromptActive: boolean) => void;
    registerSave: (f: (projectId: number) => void) => void;
    registerHasChanges: (f: () => boolean) => void;
    hasChangesUpdated: () => void;
}) {
    switch (project.ProjectType.current) {
        case PlantingProjectType.Reforestation:
            return (
                <ReforestationProject
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    completedProjectSetProjectAndValidate={completedProjectSetProjectAndValidate}
                    newProject={newProject}
                    navigationPromptActive={navigationPromptActive}
                    setNavigationPromptActive={setNavigationPromptActive}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                />
            );
        case PlantingProjectType.CommunityEvent:
            return (
                <CommunityProject
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    completedProjectSetProjectAndValidate={completedProjectSetProjectAndValidate}
                    newProject={newProject}
                    navigationPromptActive={navigationPromptActive}
                    setNavigationPromptActive={setNavigationPromptActive}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                />
            );
        case PlantingProjectType.Carbon:
            return (
                <CarbonProject
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    newProject={newProject}
                    navigationPromptActive={navigationPromptActive}
                    setNavigationPromptActive={setNavigationPromptActive}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                />
            );
        case PlantingProjectType.CommunityCanopy:
            return (
                <CommunityCanopyProject
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                    completedProjectSetProjectAndValidate={completedProjectSetProjectAndValidate}
                    newProject={newProject}
                    navigationPromptActive={navigationPromptActive}
                    setNavigationPromptActive={setNavigationPromptActive}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                />
            );
    }
}

function PlantingProjectEditor({
    newProject,
    newProjectType,
    projectAdded
}: {
    newProject?: boolean;
    newProjectType?: PlantingProjectType;
    projectAdded?: (id: number) => void;
}) {
    const {projectId} = useParams();
    const navigate = useNavigate();

    const [project, setProject] = useState<EditedProject>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState({
        show: false,
        success: true,
        message: 'The changes were saved'
    });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [potentialDuplicate, setPotentialDuplicate] = useState({
        foundPotentialDuplicate: false,
        projectIds: []
    });
    const [navigationPromptActive, setNavigationPromptActive] = useState<boolean>(true);
    const [, setHasChanges] = useState<number>(1);

    const [saveRegistrations, setSaveRegistrations] = useState<((projectId: number) => void)[]>([]);
    function registerSave(f: (projectId: number) => void) {
        setSaveRegistrations([...saveRegistrations, f]);
    }
    const [hasChangesRegistrations, setHasChangesRegistrations] = useState<(() => boolean)[]>([]);
    function registerHasChanges(f: () => boolean) {
        setHasChangesRegistrations([...hasChangesRegistrations, f]);
    }
    const registrationsHaveChanges = () =>
        hasChangesRegistrations.reduce((p, n) => p || n(), false);
    const hasChangesUpdated = () => setHasChanges(Math.random());

    function completedProjectSetProjectAndValidate(project: EditedProject) {
        setValidations(project);
        updateCompletedProject(project);
        setProject(project);
    }

    function setProjectAndValidate(project: EditedProject) {
        setValidations(project);
        setProject(project);
    }

    const getProject = async () => {
        if (newProject) {
            setProjectAndValidate(initializeNewProject(newProjectType));
        } else {
            const result = await (await fetch(`/api/planting-projects/${projectId}`)).json();
            setProjectAndValidate(initializeEditedProject(result));
        }
        setLoading(false);
    };

    useEffect(() => {
        getProject();
    }, [projectId]);

    const changed = hasChanges(project) || registrationsHaveChanges();
    const notValidToSave = getNotValidToSave(project);

    useEffect(() => {
        window.onbeforeunload = changed && !newProject ? () => true : null;
    }, [changed && navigationPromptActive && !newProject]);
    usePrompt(
        'Leave site? Changes you made may not be saved.',
        changed && navigationPromptActive && !newProject
    );

    async function saveChanges() {
        await Promise.all(
            saveRegistrations.map(async (f) => {
                await f(parseInt(projectId));
            })
        );

        const saveResult = await fetch(`/api/planting-projects/${projectId}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(project)
        });

        if (saveResult.ok) {
            setMessage({show: true, success: true, message: 'The changes were saved'});
            setTimeout(() => setMessage({show: false, success: true, message: ''}), 5000);
            await getProject();
        } else if (saveResult.status === 409) {
            setMessage({
                show: true,
                success: false,
                message: "These changes conflict with another user's changes"
            });
        } else {
            setMessage({show: true, success: false, message: 'Something went wrong'});
        }
    }

    async function addProject() {
        const saveResult = await fetch(`/api/planting-projects`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(project)
        });

        if (saveResult.ok) {
            const {id} = await saveResult.json();

            await Promise.all(
                saveRegistrations.map(async (f) => {
                    await f(id);
                })
            );

            if (projectAdded) projectAdded(id);
        } else {
            setMessage({show: true, success: false, message: 'Something went wrong'});
        }
    }

    async function handleAddClicked() {
        let foundPotentialDuplicate = false;
        let projectIds = [];

        switch (project.ProjectType.current) {
            case PlantingProjectType.CommunityEvent:
                ({foundPotentialDuplicate, projectIds} = await (
                    await fetch(
                        `/api/planting-projects/check-for-duplicate-community-project?D365EventId=${project.D365EventId.current}`
                    )
                ).json());
        }

        if (foundPotentialDuplicate) {
            setPotentialDuplicate({
                foundPotentialDuplicate: foundPotentialDuplicate,
                projectIds: projectIds
            });
        } else {
            await addProject();
        }
    }

    function handleAddCanceled() {
        setPotentialDuplicate({foundPotentialDuplicate: false, projectIds: []});
    }

    async function handleAddConfirmed() {
        setPotentialDuplicate({foundPotentialDuplicate: false, projectIds: []});
        await addProject();
    }

    function handleDeleteClicked() {
        setShowDeleteConfirmation(true);
    }

    function handleDeleteCanceled() {
        setShowDeleteConfirmation(false);
    }

    async function handleDeleteConfirmed() {
        setShowDeleteConfirmation(false);
        await deleteProject();
    }

    async function deleteProject() {
        const saveResult = await fetch(`/api/planting-projects/${projectId}`, {
            method: 'delete'
        });

        if (saveResult.ok) {
            navigate(`/planting-projects`);
        } else {
            setMessage({show: true, success: false, message: 'Something went wrong'});
        }
    }

    const roles = UserRoles();

    if (loading) return <ProjectLayout>Loading...</ProjectLayout>;

    return (
        <Container>
            <ProjectLayout>
                {roles.includes('editor') &&
                    ActionControls(
                        newProject,
                        changed,
                        notValidToSave,
                        saveChanges,
                        project,
                        handleDeleteClicked,
                        handleAddClicked,
                        message,
                        showDeleteConfirmation,
                        handleDeleteConfirmed,
                        handleDeleteCanceled,
                        potentialDuplicate,
                        handleAddConfirmed,
                        handleAddCanceled
                    )}
                <Form>
                    <PlantingProject
                        project={project}
                        setProjectAndValidate={setProjectAndValidate}
                        completedProjectSetProjectAndValidate={
                            completedProjectSetProjectAndValidate
                        }
                        newProject={newProject}
                        navigationPromptActive={navigationPromptActive}
                        setNavigationPromptActive={setNavigationPromptActive}
                        registerSave={registerSave}
                        registerHasChanges={registerHasChanges}
                        hasChangesUpdated={hasChangesUpdated}
                    />
                </Form>
            </ProjectLayout>
        </Container>
    );
}

export default PlantingProjectEditor;
