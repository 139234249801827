import { useEffect, useRef, useState } from 'react';
import {
	LabelContainer,
	Label,
	ValidationsList,
	SubSectionHeader,
	HeaderContainer
} from '../../../../components/Form/FormStyles';
import { Help } from '../../../../components/Buttons/Help';
import { ValidateControl } from '../../../../components/Controls/ValidateControl';
import { EditedProject } from '../../EditedProject';
import { ProjectMap } from './ProjectMap';
import styled from 'styled-components';
import { useSearchParams, useLocation } from 'react-router-dom';

export const ShowMapLink = styled.a`
    cursor: pointer;
    align-self: flex-start;
`;

export function MapControl({
    project,
    setProjectAndValidate,
    registerSave,
    registerHasChanges,
    hasChangesUpdated,
    navigationPromptActive,
    setNavigationPromptActive
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
    registerSave: (f: (projectId: number) => void) => void;
    registerHasChanges: (f: () => boolean) => void;
    hasChangesUpdated: () => void;
    navigationPromptActive: boolean;
    setNavigationPromptActive: (navigationPromptActive: boolean) => void;
}) {
    const mapSubHeaderRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const showMap = searchParams.get('mapVisibility') === 'show';
    const [showMapBeingUpdated, setShowMapBeingUpdated] = useState('');

    useEffect(() => {
        if (showMap) mapSubHeaderRef.current.scrollIntoView({behavior: 'smooth'});
    }, []);

    function handleShowMapClicked() {
        setNavigationPromptActive(false);
        setShowMapBeingUpdated('show');
        mapSubHeaderRef.current.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (!navigationPromptActive)
            if (showMapBeingUpdated !== '')
                setSearchParams(
                    {...searchParams, mapVisibility: showMapBeingUpdated},
                    {replace: true}
                );
    }, [navigationPromptActive]);

    useEffect(() => {
        setNavigationPromptActive(true);
    }, [location]);

    return (
        <>
            <HeaderContainer ref={mapSubHeaderRef}>
                <SubSectionHeader id="map">Map</SubSectionHeader>
                <Help>The boundaries of the project as it will appear on a map.</Help>
            </HeaderContainer>
            <LabelContainer>
                <ValidateControl
                    disabled={project.MapValidated.notValidToShowPartner}
                    validated={project.MapValidated.current}
                    onChange={(e) =>
                        setProjectAndValidate({
                            ...project,
                            MapValidated: project.MapValidated.update(e.validated)
                        })
                    }
                />
                <Label htmlFor="map">Map Validated</Label>
            </LabelContainer>
            <ValidationsList validations={project.MapValidated.validations} />
            {showMap ? (
                <ProjectMap
                    projectId={project.Id}
                    registerSave={registerSave}
                    registerHasChanges={registerHasChanges}
                    hasChangesUpdated={hasChangesUpdated}
                />
            ) : (
                <ShowMapLink onClick={handleShowMapClicked}>Show map</ShowMapLink>
            )}
        </>
    );
}
