import {useEffect, useCallback} from 'react';
import {CloseIcon} from '../Buttons/CloseButton';
import styled from 'styled-components';
import styles from './Modal.module.css';

type IModal = {
    showModal: boolean;
    onClose: () => void;
    title?;
    children?;
    width?: string;
};

const StyledContent = styled.div<{width: string}>`
    width: ${({width}) => (width ? width : '500px')};
`;

const Modal = ({showModal, title, onClose, children, width}: IModal) => {
    const closeOnEscapeKeyDown = useCallback(
        (e) => {
            if ((e.charCode || e.keyCode) === 27) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        };
    }, [closeOnEscapeKeyDown]);

    if (!showModal) return <></>;

    return (
        <div className={styles.modalStyle} onClick={onClose}>
            <StyledContent
                className={styles.modalContent}
                width={width}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.modalCloser} onClick={onClose}>
                    <CloseIcon />
                </div>
                <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>{title}</div>
                </div>
                <div className={styles.modalBody}>
                    <div>{children}</div>
                </div>
            </StyledContent>
        </div>
    );
};

export default Modal;
