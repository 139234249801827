import {ExcludeFilterValue} from './components/Controls/ExcludeControl';
import {ValidateFilterValue} from './components/Controls/ValidateControl';

/*eslint eqeqeq: "off"*/
export function textMatch(value, filter) {
    if (filter == null || filter === '') return true;

    if (filter === "''") return value == null || value === '';
    else return value?.toLowerCase().includes(filter.toLowerCase());
}

function removeOperatorFromString(filter: string) {
    return filter.replace('>', '').replace('<', '').replace('=', '');
}

function numberMatchWithOperators(value: number, filter: string) {
    const operatorRemoved = removeOperatorFromString(filter);
    const filterNumber = parseFloat(operatorRemoved);

    if (isNaN(filterNumber)) return true;

    if (filter.includes('>=') || filter.includes('=>')) {
        return value !== null && value >= filterNumber;
    }
    if (filter.includes('<=') || filter.includes('=<')) {
        return value !== null && value <= filterNumber;
    }
    if (filter.includes('>')) {
        return value !== null && value > filterNumber;
    }
    if (filter.includes('<')) {
        return value !== null && value < filterNumber;
    }
    if (filter.includes('=')) {
        return filterNumber === value;
    }
}

function containsOperator(filter: string) {
    if (!filter) return false;

    if (
        filter.includes('=') ||
        filter.includes('<') ||
        filter.includes('>') ||
        filter.includes('<=') ||
        filter.includes('=<') ||
        filter.includes('>=') ||
        filter.includes('=>')
    )
        return true;

    return false;
}

export function numberMatch(value, filter) {
    if (filter !== null && containsOperator(filter)) return numberMatchWithOperators(value, filter);

    if (filter == null || filter === '') return true;

    if (filter === "''") return value == null;
    else return value == filter;
}

export function numberRangeMatch(low, hi, filter) {
    if (filter == null || filter === '') return true;

    if (filter === "''") return low == null || hi == null;
    else if (low == null) return filter == hi;
    else if (hi == null) return filter == low;
    else return low <= filter && filter <= hi;
}

export function booleanMatch(value, filter) {
    if (filter == null || filter === '') return true;

    if (filter === "''" || filter.toLowerCase() === 'n') return !value;
    else return filter.toLowerCase() === 'y' && value;
}

export function validatedMatch(value: boolean, filter: ValidateFilterValue) {
    switch (filter) {
        case ValidateFilterValue.Unset:
            return true;
        case ValidateFilterValue.Validated:
            return value;
        case ValidateFilterValue.Unvalidated:
            return !value;
        default:
            return true;
    }
}

export function excludedMatch(value: boolean, filter: ExcludeFilterValue) {
    switch (filter) {
        case ExcludeFilterValue.Unset:
            return true;
        case ExcludeFilterValue.Excluded:
            return value;
        case ExcludeFilterValue.NotExcluded:
            return !value;
        default:
            return true;
    }
}
