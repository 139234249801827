import styled from 'styled-components';

export const LythoImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-flow: row dense;
    grid-gap: 2.5rem;
    padding: 2.5rem;
    background-color: rgb(200, 200, 200);
`;
export const LythoImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
`;
export const LythoImageDisplay = styled.img<{hero: boolean}>`
    width: 100%;
    padding: 10px;
    background-color: ${(props) => (props.hero ? 'rgb(55, 55, 55)' : 'rgb(245, 245, 245)')};
`;
export const LythoImageMetadata = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 0.75rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    justify-content: space-between;
    width: 100%;
`;
