import {BeehiveCampaignsHeader} from '../../ParticipationColumns/CommunityCanopy/BeehiveCampaigns';
import {CommunityEventDatesHeader} from '../../ParticipationColumns/CommunityEvents/CommunityEventDates';
import {CommunityTreesDistributedHeader} from '../../ParticipationColumns/Shared/CommunityTreesDistributed';
import {DeleteParticipationHeader} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedHeader} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedHeader} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedHeader} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearHeader} from '../../ParticipationColumns/Shared/FundedYear';
import {FundingSourceHeader} from '../../ParticipationColumns/Shared/FundingSource';
import {ImpactStoryCompletedHeader} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ParticipationIdHeader} from '../../ParticipationColumns/Shared/ParticipationId';
import {ProjectIdHeader} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectNameHeader} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectTypeHeader} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ReasonForPlantingHeader} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {ShowOnDateHeader} from '../../ParticipationColumns/Shared/ShowOnDate';

export function CommunityCanopyParticipationHeader() {
    return (
        <tr>
            <DeleteParticipationHeader />
            <ExcludedHeader />
            <EverythingValidatedHeader />
            <ParticipationIdHeader />
            <FundedYearHeader />
            <FundingSourceHeader />
            <CommunityTreesDistributedHeader />
            <DollarDonatedHeader />
            <ShowOnDateHeader />
            <ProjectIdHeader />
            <ProjectTypeHeader />
            <ProjectNameHeader />
            <BeehiveCampaignsHeader />
            <CommunityEventDatesHeader />
            <ReasonForPlantingHeader />
            <ImpactStoryCompletedHeader />
        </tr>
    );
}
