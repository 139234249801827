import { CellWithValidator, StickyNumericHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import {
	ValidatedIcon,
	ValidateFilter,
	ValidateFilterValue
} from "../../../../components/Controls/ValidateControl";
import { ComplexInputWithAsyncUpdate, NumericFilter, ValidationsList } from "../../../../components/Form/FormStyles";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { getNumberFromInput } from "../../../../record-editing";
import { EditedParticipation } from "../../../Partner/EditedPartner";
import {
	AlignToTextBoxContent,
	DollarsControl,
	ValidationTippy,
	ValidationTippyContent
} from "../../Styles/ParticipationRowStyles";
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications";

export function CarbonPurchaseAmountHeader() {
	return (
		<StickyNumericHeader
			style={{
				...offsetOneRow,
				width: '152px',
				minWidth: '152px',
				maxWidth: '152px'
			}}>
			Purchase Amount
		</StickyNumericHeader>
	)
}

export function CarbonPurchaseAmountFilter({
	filter,
	updateFilter
}: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CarbonPurchaseAmountValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CarbonPurchaseAmountValidated: e.value
						})
					}
				/>
				<NumericFilter
					value={filter?.CarbonPurchaseAmount ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, CarbonPurchaseAmount: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function CarbonPurchaseAmountContent({
	ppn,
	updater
}: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledNumberCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<>
						<ValidatedIcon />
						<AlignToTextBoxContent>
							{ppn.CarbonPurchaseAmount.current}
						</AlignToTextBoxContent>
					</>
				) : (
					<>
						<ValidatedIcon />
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={
											ppn.CarbonPurchaseAmount.validations
										}
									/>
								</ValidationTippyContent>
							}
							disabled={
								ppn.CarbonPurchaseAmount.validations.length === 0
							}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={DollarsControl}
									showModification
									value={ppn.CarbonPurchaseAmount}
									onChange={(updatedValue) =>
										updater.update({
											...ppn,
											CarbonPurchaseAmount: updatedValue
										})
									}
									valueGetter={(e) =>
										ppn.CarbonPurchaseAmount.update(
											getNumberFromInput(e)
										)
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}