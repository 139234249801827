import { EditedParticipation } from "../../../Partner/EditedPartner";
import { ParticipationUpdater } from "../../ParticipationFunctions/ParticipationModifications";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import {
	ValidatedIcon,
	ValidateFilter,
	ValidateFilterValue
} from "../../../../components/Controls/ValidateControl";
import {
	ComplexInputWithAsyncUpdate,
	TextControlShowingEdits,
	TextFilter,
	ValidationsList
} from "../../../../components/Form/FormStyles";
import { AlignToTextBoxContent, ValidationTippy, ValidationTippyContent } from "../../Styles/ParticipationRowStyles";

export function SerialNumberHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '400px',
				minWidth: '400px',
				maxWidth: '400px'
			}}>
			Serial Number
		</StickyHeader>
	)
}

export function SerialNumberFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={filter?.SerialNumberValidated ?? ValidateFilterValue.Unset}
					onChange={(e) =>
						updateFilter({ ...filter, SerialNumberValidated: e.value })
					}
				/>
				<TextFilter
					value={filter?.SerialNumber ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							SerialNumber: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function SerialNumberContent({ ppn, updater }: { ppn: EditedParticipation, updater: ParticipationUpdater }) {
	return (
		<StateStyledCell editStatus={ppn.EditStatus}>
			<CellWithValidator>
				{ppn.ControlledByAutomation ? (
					<ValidatedIcon />
				) : (
					<>
						<ValidatedIcon />
						<ValidationTippy
							content={
								<ValidationTippyContent>
									<ValidationsList
										validations={ppn.SerialNumber.validations}
									/>
								</ValidationTippyContent>
							}
							disabled={ppn.SerialNumber.validations.length === 0}>
							<div>
								<ComplexInputWithAsyncUpdate
									Control={TextControlShowingEdits}
									showModification
									value={ppn.SerialNumber}
									onChange={(updatedValue) => {
										updater.update({
											...ppn,
											SerialNumber: updatedValue
										});
									}}
									valueGetter={(e) =>
										ppn.SerialNumber.update(e.target.value)
									}
								/>
							</div>
						</ValidationTippy>
					</>
				)}
			</CellWithValidator>
		</StateStyledCell>
	)
}