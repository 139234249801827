import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {SectionList} from '../components/Form/FormStyles';
import {
    getNotValidToSave,
    InitializeNewPartner,
    setValidations
} from '../containers/Partner/EditedPartner';
import GeneralPartnerInfo from '../containers/Partner/GeneralPartnerInfo';
import {SaveChangesButton, SaveChangesMessage} from '../components/Buttons/SaveChanges';

const Layout = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'header'
        'main';
    place-content: stretch;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;
`;

const HeaderArea = styled.div`
    grid-area: header;
`;

const Header = styled.h2`
    font-size: 1.5rem;
    height: calc(1.5rem * 1.5);
    font-weight: 600;
    margin: 0.3rem 0 0 1rem;
`;

const MainArea = styled.div`
    grid-area: main;
    padding: 1rem;
`;

function PartnerNew() {
    const navigate = useNavigate();
    const [message, setMessage] = useState({
        show: false,
        success: true,
        message: 'The changes were saved'
    });
    const [partner, setPartner] = useState(InitializeNewPartner());

    function setPartnerAndValidate(partner) {
        setValidations(partner);
        setPartner(partner);
    }

    async function addPartner() {
        const saveResult = await fetch(`/api/partners`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(partner)
        });

        if (saveResult.ok) {
            const {id} = await saveResult.json();
            navigate(`/partners/${id}?refresh=true`);
        } else {
            setMessage({show: true, success: false, message: 'Something went wrong'});
        }
    }

    const notValidToSave = getNotValidToSave(partner);

    return (
        <Layout>
            <HeaderArea>
                <Header>Add a new partner</Header>
            </HeaderArea>
            <MainArea>
                <SectionList>
                    <GeneralPartnerInfo
                        partner={partner}
                        setPartnerAndValidate={setPartnerAndValidate}
                        focused={true}
                    />
                    <div>
                        <SaveChangesButton disabled={notValidToSave} onClick={addPartner}>
                            Add Partner
                        </SaveChangesButton>
                        <SaveChangesMessage {...message} />
                    </div>
                </SectionList>
            </MainArea>
        </Layout>
    );
}

export default PartnerNew;
