import {useRef, useState} from 'react';
import styled from 'styled-components';
import {SuccessAlert, FailureAlert} from '../components/Alerts/Alerts';
import {ImportButton} from '../components/Buttons/ImportButton';

const ImportLayout = styled.div`
    margin: 0;
    padding: 1rem;
    max-width: 1200px;
`;

const CarbonCreditImporter = () => {
    return (
        <div>
            <ImportCarbonProjects />
            <ImportCarbonParticipations />
        </div>
    );
};

const ImportCarbonProjects = () => {
    const fileRef = useRef(null);
    const [importSuccessful, setImportSuccessful] = useState<boolean>(undefined);

    async function handleSubmit(e) {
        e.preventDefault();

        const payload = new FormData();
        payload.append('file', fileRef.current.files[0]);

        const importResult = await fetch(`/api/import-carbon-projects`, {
            method: 'post',
            body: payload
        });

        if (importResult.ok) {
            setImportSuccessful(true);
        } else {
            setImportSuccessful(false);
        }
    }

    return (
        <ImportLayout>
            <h2>Import Carbon Projects</h2>
            <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
                {importSuccessful !== true && (
                    <>
                        <p></p>
                        <input
                            type="file"
                            ref={fileRef}
                            accept=".xlsx"
                            onChange={() => setImportSuccessful(undefined)}
                            required
                        />
                        <br />
                        <br />
                    </>
                )}
                {importSuccessful === true && (
                    <SuccessAlert>Carbon projects were successfully imported</SuccessAlert>
                )}
                {importSuccessful === false && (
                    <>
                        <FailureAlert>Something went wrong</FailureAlert>
                        <br />
                    </>
                )}
                {importSuccessful !== true && <ImportButton type="submit">Import</ImportButton>}
            </form>
        </ImportLayout>
    );
};

const ImportCarbonParticipations = () => {
    const fileRef = useRef(null);
    const [importSuccessful, setImportSuccessful] = useState<boolean>(undefined);

    async function handleSubmit(e) {
        e.preventDefault();

        const payload = new FormData();
        payload.append('file', fileRef.current.files[0]);

        const importResult = await fetch(`/api/import-carbon-participations`, {
            method: 'post',
            body: payload
        });

        if (importResult.ok) {
            setImportSuccessful(true);
        } else {
            setImportSuccessful(false);
        }
    }

    return (
        <ImportLayout>
            <h2>Import Carbon Participations</h2>
            <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
                {importSuccessful !== true && (
                    <>
                        <p></p>
                        <input
                            type="file"
                            ref={fileRef}
                            accept=".xlsx"
                            onChange={() => setImportSuccessful(undefined)}
                            required
                        />
                        <br />
                        <br />
                    </>
                )}
                {importSuccessful === true && (
                    <SuccessAlert>Carbon participations were successfully imported</SuccessAlert>
                )}
                {importSuccessful === false && (
                    <>
                        <FailureAlert>Something went wrong</FailureAlert>
                        <br />
                    </>
                )}
                {importSuccessful !== true && <ImportButton type="submit">Import</ImportButton>}
            </form>
        </ImportLayout>
    );
};

export default CarbonCreditImporter;
