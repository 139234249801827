import {YesNoConditional} from '../entities';

export function getYesNoConditionalOptions(): YesNoConditionalOption[] {
    return Object.keys(YesNoConditional)
        .filter((x) => isNaN(Number(x)))
        .map((name) => {
            const id = YesNoConditional[name as keyof YesNoConditional];
            return {
                value: id,
                label: name
            };
        });
}

export type YesNoConditionalOption = {
    value: number;
    label: string;
};

export default YesNoConditionalOption;
