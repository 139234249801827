import {EditedParticipation} from '../../Partner/EditedPartner';

export class ParticipationUpdater {
    updater;

    constructor(updater) {
        this.updater = updater;
    }

    update(participation: EditedParticipation) {
        this.updater(participation);
    }
}

export class ParticipationRemover {
    remover;

    constructor(remover) {
        this.remover = remover;
    }

    remove(id: number) {
        this.remover(id);
    }
}

export class ParticipationUnremover {
    unremover;

    constructor(unremover) {
        this.unremover = unremover;
    }

    unremove(id: number) {
        this.unremover(id);
    }
}

export class ProjectSelectionSetter {
    setter;

    constructor(setter) {
        this.setter = setter;
    }

    setProject({participationId, inSelectionMode}) {
        this.setter({participationId: participationId, inSelectionMode: inSelectionMode});
    }
}
