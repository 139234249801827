
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyNumericHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledNumberCell } from "../../Styles/ParticipationTableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { NumericFilter } from "../../../../components/Form/FormStyles";

export function AcresRestoredHeader() {
	return (
		<StickyNumericHeader
			style={{ ...offsetOneRow, width: '120px', minWidth: '120px', maxWidth: '120px' }}>
			Acres restored
		</StickyNumericHeader>
	)
}

export function AcresRestoredFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyNumericHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.ProjectAcresRestoredValidated ?? ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({ ...filter, ProjectAcresRestoredValidated: e.value })
					}
				/>
				<NumericFilter
					value={filter?.ProjectAcresRestored ?? ''}
					onChange={(e) =>
						updateFilter({ ...filter, ProjectAcresRestored: e.target.value })
					}
				/>
			</CellWithValidator>
		</StickyNumericHeader>
	)
}

export function AcresRestoredContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledNumberCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.AcresRestoredValidated && <ValidatedIcon />}
				</span>
				<span>
					{ppn.PlantingProject.AcresRestored?.toLocaleString(undefined, {
						maximumFractionDigits: 2
					})}
				</span>
			</CellWithValidator>
		</StateStyledNumberCell>
	)
}