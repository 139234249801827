import {useState, useEffect} from 'react';
import Select from 'react-select';
import {
    CarbonCreditsAdditionalCertification,
    CarbonCreditsAdditionalCertifications
} from '../../../entities';
import {EditedCarbonCreditsAdditionalCertification, EditedProject} from '../EditedProject';
import {RecordEditStatus} from '../../../record-editing';

export function CarbonCreditAdditionalCertificationsComponent({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [availableOptions, setAvailableOptions] = useState<
        CarbonCreditAdditionalCertificationsType[]
    >([]);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [originalCerts, setOriginalCerts] = useState<
        EditedCarbonCreditsAdditionalCertification[]
    >([]);

    useEffect(() => {
        setSelectedOptions(
            project.CarbonCreditsAdditionalCertifications?.map((x) => setterSelectedOptions(x))
        );
    }, []);

    useEffect(() => {
        setOriginalCerts(project.CarbonCreditsAdditionalCertifications);
    }, []);

    useEffect(() => {
        setAvailableOptions(getAdditionalCertObject());
    }, []);

    const onChange = (e) => {
        setSelectedOptions(e);
        setProjectAndValidate({
            ...project,
            CarbonCreditsAdditionalCertifications: updateCarbonAdditionalCertifications(e)
        });
    };

    function updateCarbonAdditionalCertifications(
        certsInState: CarbonCreditAdditionalCertificationsType[]
    ): EditedCarbonCreditsAdditionalCertification[] {
        const updatedCertList: EditedCarbonCreditsAdditionalCertification[] = [];

        certsInState.forEach((certToEval) => {
            if (
                originalCerts.some(
                    (types) => types.CarbonCreditsAdditionalCertificationType === certToEval.value
                )
            ) {
                updatedCertList.push({
                    EditStatus: RecordEditStatus.Existing,
                    CarbonCreditsAdditionalCertificationType: certToEval.value
                });
            } else if (
                !originalCerts.some(
                    (types) => types.CarbonCreditsAdditionalCertificationType === certToEval.value
                )
            ) {
                updatedCertList.push({
                    EditStatus: RecordEditStatus.New,
                    CarbonCreditsAdditionalCertificationType: certToEval.value
                });
            }
        });

        originalCerts.forEach((originalCert) => {
            if (
                !certsInState.some(
                    (cert) => cert.value === originalCert.CarbonCreditsAdditionalCertificationType
                )
            ) {
                updatedCertList.push({
                    EditStatus: RecordEditStatus.Deleted,
                    CarbonCreditsAdditionalCertificationType:
                        originalCert.CarbonCreditsAdditionalCertificationType
                });
            }
        });

        return updatedCertList;
    }

    function setterSelectedOptions(
        editedCert: EditedCarbonCreditsAdditionalCertification
    ): CarbonCreditAdditionalCertificationsType {
        const cert: CarbonCreditAdditionalCertificationsType = {
            value: editedCert?.CarbonCreditsAdditionalCertificationType,
            label: formatAdditionalCertifications(
                editedCert?.CarbonCreditsAdditionalCertificationType
            ),
            name: CarbonCreditsAdditionalCertification[
                editedCert?.CarbonCreditsAdditionalCertificationType
            ]?.toString()
        };
        return cert;
    }

    return (
        <Select
            value={selectedOptions}
            onChange={(e) => onChange(e)}
            options={availableOptions}
            isMulti
        />
    );
}

export function formatAdditionalCertificationsArray(
    certs: CarbonCreditsAdditionalCertifications[]
) {
    const certStrings = certs?.map((cert) =>
        formatAdditionalCertifications(cert.CarbonCreditsAdditionalCertification)
    );
    return certStrings?.join(', ');
}

export function formatAdditionalCertifications(cert: CarbonCreditsAdditionalCertification) {
    switch (cert) {
        case CarbonCreditsAdditionalCertification.CCBStandards:
            return 'CCB Standards';
        case CarbonCreditsAdditionalCertification.FairTrade:
            return 'Fair Trade';
        case CarbonCreditsAdditionalCertification.Organic:
            return 'Organic';
        case CarbonCreditsAdditionalCertification.FSC:
            return 'FSC';
        case CarbonCreditsAdditionalCertification.SFI:
            return 'SFI';
        case CarbonCreditsAdditionalCertification.PEFC:
            return 'PEFC';
        case CarbonCreditsAdditionalCertification.RainforestAlliance:
            return 'Rainforest Alliance';
    }
}

function getAdditionalCertObject(): CarbonCreditAdditionalCertificationsType[] {
    return Object.keys(CarbonCreditsAdditionalCertification)
        .filter((x) => isNaN(Number(x)))
        .map((name) => {
            const id =
                CarbonCreditsAdditionalCertification[
                    name as keyof CarbonCreditsAdditionalCertification
                ];
            return {
                name,
                value: id,
                label: formatAdditionalCertifications(id)
            };
        });
}

type CarbonCreditAdditionalCertificationsType = {
    name: string;
    value: number;
    label: string;
};

export default CarbonCreditAdditionalCertificationsComponent;
