
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { CellWithValidator, StickyHeader } from "../../../../components/Table/TableStyles";
import { offsetOneRow, offsetTwoRows, StateStyledCell } from "../../Styles/ParticipationTableStyles";
import { ValidatedIcon, ValidateFilter, ValidateFilterValue } from "../../../../components/Controls/ValidateControl";
import { TextFilter } from "../../../../components/Form/FormStyles";

export function MoreDetailsLinkHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				width: '250px',
				minWidth: '250px',
				maxWidth: '250px'
			}}>
			CC More Details Link
		</StickyHeader>
	)
}

export function MoreDetailsLinkFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows }}>
			<CellWithValidator>
				<ValidateFilter
					value={
						filter?.CarbonAdditionalDetailsLinkValidated ??
						ValidateFilterValue.Unset
					}
					onChange={(e) =>
						updateFilter({
							...filter,
							CarbonAdditionalDetailsLinkValidated: e.value
						})
					}
				/>
				<TextFilter
					value={filter?.CarbonAdditionalDetailsLink ?? ''}
					onChange={(e) =>
						updateFilter({
							...filter,
							CarbonAdditionalDetailsLink: e.target.value
						})
					}
				/>
			</CellWithValidator>
		</StickyHeader>
	)
}

export function MoreDetailsLinkContent({ ppn }: { ppn: EditedParticipation }) {
	return (
		<StateStyledCell
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}>
			<CellWithValidator>
				<span>
					{ppn.PlantingProject.CarbonAdditionalDetailsLinkValidated && (
						<ValidatedIcon />
					)}
				</span>
				<span>{ppn.PlantingProject.CarbonAdditionalDetailsLink}</span>
			</CellWithValidator>
		</StateStyledCell>
	)
}