import {useState} from 'react';
import {Outlet, NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {useUser} from './providers/UserProvider';
import HelpModal from './components/Modal/HelpModal';
const AppLayout = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        'navigation'
        'main';
    height: 100vh;
    overflow: hidden;
`;

const AppBar = styled.div`
    grid-area: navigation;
    background-color: rgb(160, 160, 160);
    color: white;
    display: flex;
    justify-content: space-between;

    a {
        display: block;
        padding: 0.6rem 1rem;
        color: white;
        text-decoration: none;
    }

    a:hover,
    a:focus {
        background-color: rgb(170, 170, 170);
    }

    a:active {
        color: rgb(70, 70, 70);
        text-decoration: underline;
    }

    a[aria-current='page'] {
        background-color: rgb(210, 210, 210);
        color: rgb(50, 50, 50);
    }
`;

const MainNavigation = styled.nav``;

const MainNavigationList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

const NavigationItem = styled.li`
    margin: 0;
    padding: 0;
`;

const NavigationSubListRootItem = styled.li`
    margin: 0;
    padding: 0;
    position: relative;
`;

const NavigationSublistRootLink = styled.a``;

const NavigationSubList = styled.ul`
    display: none;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    background-color: white;
    color: rgb(50, 50, 50);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;

    ${NavigationSubListRootItem}:hover & {
        display: flex;
    }
`;

const NavigationSubListItem = styled.li`
    margin: 0;
    padding: 0;

    a {
        display: block;
        padding: 0.6rem 1rem;
        color: rgb(50, 50, 50);
        text-decoration: none;
        white-space: nowrap;
    }

    a:hover,
    a:focus {
        background-color: rgb(220, 220, 220);
    }

    a:active {
        color: rgb(70, 70, 70);
        text-decoration: underline;
    }
`;

const MainContent = styled.div`
    grid-area: main;
    overflow: hidden;
`;

const ClickableLink = styled.div`
    padding: 0.6rem;
    cursor: pointer;
    :hover {
        background-color: rgb(170, 170, 170);
    }
`;

function Login() {
    const userInfo = useUser();

    if (!userInfo.userInfoRetrieved) return null;
    else if (userInfo.user) return <a href="/.auth/logout">Log out</a>;
    else return <a href="/.auth/login/aad">Log in</a>;
}

export default function App() {
    const [showHelpModal, setShowHelpModal] = useState(false);

    return (
        <AppLayout>
            <AppBar>
                <MainNavigation aria-label="Main Menu">
                    <MainNavigationList>
                        <NavigationItem>
                            <NavLink to="/">Home</NavLink>
                        </NavigationItem>
                        <NavigationItem>
                            <NavLink to="/partners">Partners &amp; Participations</NavLink>
                        </NavigationItem>
                        <NavigationItem>
                            <NavLink to="/planting-projects">Planting Projects</NavLink>
                        </NavigationItem>
                        <NavigationItem>
                            <NavLink to="/automation-run-logs">Automation</NavLink>
                        </NavigationItem>
                        <NavigationItem>
                            <ClickableLink
                                onClick={() => {
                                    setShowHelpModal(true);
                                }}>
                                {' '}
                                Help
                                <HelpModal
                                    showHelpModal={showHelpModal}
                                    setShowHelpModal={setShowHelpModal}
                                />
                            </ClickableLink>
                        </NavigationItem>
                    </MainNavigationList>
                </MainNavigation>
                <Login />
            </AppBar>
            <MainContent>
                <Outlet />
            </MainContent>
        </AppLayout>
    );
}
