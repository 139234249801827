import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {CancelButton, AddButton} from '../../../components/Buttons/SaveChanges';

const ConfirmationOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 2;
    padding: 3rem;
`;

const ConfirmationContainer = styled.div`
    border: 1px solid gray;
    border-radius: 0.25rem;
    padding: 1rem;
    background-color: white;
    z-index: 3;
`;

const ConfirmationHeader = styled.h3`
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0;
`;

const ConfirmationActionList = styled.div`
    display: flex;
    gap: 1rem;
`;

const WarningPotentialDuplicate = styled.p`
    background-color: #fff3cd;
    border-color: #ffecb5;
    border-radius: 0.25rem;
    color: #664d03;
    padding: 1rem;
`;

export function AddConfirmation({
    projectIds,
    addConfirmed,
    canceled
}: {
    projectIds: number[];
    addConfirmed: () => void;
    canceled: () => void;
}) {
    return (
        <>
            <ConfirmationOverlay></ConfirmationOverlay>
            <ConfirmationContainer>
                <ConfirmationHeader>Are you sure you want to add this project?</ConfirmationHeader>
                <WarningPotentialDuplicate>
                    This project is a potential duplicate of another project:&nbsp;
                    {projectIds.map((projectId, i) => [
                        i > 0 && ', ',
                        <Link to={`/planting-projects/${projectId}`}>{projectId}</Link>
                    ])}
                </WarningPotentialDuplicate>
                <ConfirmationActionList>
                    <AddButton onClick={() => addConfirmed()}>Add</AddButton>
                    <CancelButton onClick={() => canceled()}>Cancel</CancelButton>
                </ConfirmationActionList>
            </ConfirmationContainer>
        </>
    );
}
