import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {NumericHeader, NumericCell} from '../../../components/Table/TableStyles';
import {EditedParticipation} from '../EditedProject';

export const PartnersTable = styled.table.attrs({className: 'table'})`
    margin-bottom: 1rem;
`;

export function FundingPartnersControl({participations}: {participations: EditedParticipation[]}) {
    return (
        <PartnersTable>
            <thead>
                <tr>
                    <th>Partner</th>
                    <NumericHeader>Funded year</NumericHeader>
                    <NumericHeader>TTF trees</NumericHeader>
                    <NumericHeader>Community trees planted</NumericHeader>
                    <NumericHeader>Community trees distributed</NumericHeader>
                    <NumericHeader>Dollars donated</NumericHeader>
                </tr>
            </thead>
            <tbody>
                {participations?.map((ppn) => (
                    <tr key={ppn.Id}>
                        <td>
                            <Link to={`/partners/${ppn.PartnerId}`}>{ppn.Partner.Name}</Link>
                        </td>
                        <NumericCell>{ppn.FundedYear}</NumericCell>
                        <NumericCell>{ppn.TTFTrees?.toLocaleString() ?? ''}</NumericCell>
                        <NumericCell>
                            {ppn.CommunityTreesPlanted?.toLocaleString() ?? ''}
                        </NumericCell>
                        <NumericCell>
                            {ppn.CommunityTreesDistributed?.toLocaleString() ?? ''}
                        </NumericCell>
                        <NumericCell>
                            {ppn.DollarsDonated?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) ?? ''}
                        </NumericCell>
                    </tr>
                ))}
            </tbody>
        </PartnersTable>
    );
}
