import { Link } from "react-router-dom";
import { StyledSearchButton, SearchIcon } from "../../../../components/Buttons/SearchButton";
import { TextFilter } from "../../../../components/Form/FormStyles";
import { StickyHeader } from "../../../../components/Table/TableStyles";
import { PlantingProjectType } from "../../../../entities";
import { EditedParticipation } from "../../../Partner/EditedPartner";
import { Filter } from "../../ParticipationFunctions/Filtering";
import { ProjectSelectionSetter } from "../../ParticipationFunctions/ParticipationModifications";
import { offsetOneRow, fixedProjectIdColumn, offsetTwoRows, StateStyledCell, ProjectIdCell } from "../../Styles/ParticipationTableStyles";

export function ProjectIdHeader() {
	return (
		<StickyHeader
			style={{
				...offsetOneRow,
				zIndex: 1,
				...fixedProjectIdColumn,
				width: '100px',
				minWidth: '100px',
				maxWidth: '100px'
			}}>
			Project id
		</StickyHeader>
	)
}

export function ProjectIdFilter({ filter, updateFilter }: { filter: Filter, updateFilter: (filter: Filter) => void }) {
	return (
		<StickyHeader style={{ ...offsetTwoRows, zIndex: 1, ...fixedProjectIdColumn }}>
			<TextFilter
				value={filter?.PlantingProjectId ?? ''}
				onChange={(e) => updateFilter({ ...filter, PlantingProjectId: e.target.value })}
			/>
		</StickyHeader>
	)
}

export function ProjectIdContent({
	ppn,
	projectSelectionSetter
}: { ppn: EditedParticipation, projectSelectionSetter: ProjectSelectionSetter }) {
	return (
		<StateStyledCell
			style={{ position: 'sticky', ...fixedProjectIdColumn }}
			editStatus={ppn.EditStatus}
			modified={ppn.PlantingProjectId.modified}
			hasErrors={!ppn.PlantingProjectId.current}>
			<ProjectIdCell>
				{ppn.ProjectType === PlantingProjectType.Reforestation ||
					ppn.ProjectType === PlantingProjectType.CommunityEvent ||
					ppn.ProjectType === PlantingProjectType.Carbon ? (
					<ChangeProjectButton
						onClick={() =>
							projectSelectionSetter.setProject({
								participationId: ppn.Id,
								inSelectionMode: true
							})
						}></ChangeProjectButton>
				) : (
					<span></span>
				)}
				<Link to={`/planting-projects/${ppn.PlantingProjectId.current}`}>
					{ppn.PlantingProjectId.current}
				</Link>
			</ProjectIdCell>
		</StateStyledCell>
	)
}

function ChangeProjectButton(props) {
	return (
		<StyledSearchButton title="Switch projects" {...props}>
			<SearchIcon />
		</StyledSearchButton>
	);
}