import styled from 'styled-components';

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem 0 0;
`;

const StyledTab = styled.div<{active?: boolean}>`
    padding: 0.25rem 0.75rem;
    margin: 0 0.25rem;
    cursor: ${(props) => (props.active ? 'unset' : 'pointer')};
    border-radius: 0.25rem;

    ${(props) =>
        props.active
            ? null
            : `
            &:hover {
                background-color: rgb(240, 240, 240);
            }
        `}
`;

const ActiveTabFooter = styled.div`
    margin: 0 0.75rem;
    border-bottom: 2px solid rgb(120, 120, 255);
`;

const InactiveTabFooter = styled.div`
    margin: 0 0.75rem;
    border-bottom: 2px solid transparent;
`;

export function Tab(props) {
    return (
        <div>
            <StyledTab {...props} />
            {props.active ? <ActiveTabFooter /> : <InactiveTabFooter />}
        </div>
    );
}

export const TabSpacer = styled.div`
    height: 1rem;
    border-right: 1px solid rgb(220, 220, 220);
`;
