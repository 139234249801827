import {LabelContainer, Label, FormFieldContainer} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import SustainableDevelopmentGoalContainer from './SustainableDevelopmentGoals';
import styled from 'styled-components';

export const SustainableDevelopmentGoalsWrapper = styled(FormFieldContainer)`
    max-width: 500px;
    margin-bottom: 10px;
`;

export function SustainabilityDevelopmentGoalsControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <>
            <SustainableDevelopmentGoalsWrapper>
                <LabelContainer>
                    <ValidateControl
                        validated={project.SustainableDevelopmentGoalsValidated.current}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                SustainableDevelopmentGoalsValidated:
                                    project.SustainableDevelopmentGoalsValidated.update(e.validated)
                            })
                        }
                    />
                    <Label htmlFor="sustainabilityDevelopmentGoals">
                        Sustainability Development Goals
                    </Label>
                </LabelContainer>
                <SustainableDevelopmentGoalContainer
                    project={project}
                    setProjectAndValidate={setProjectAndValidate}
                />
            </SustainableDevelopmentGoalsWrapper>
        </>
    );
}
