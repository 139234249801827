import {PlantingProjectType} from '../../../entities';
import {getNumberFromInput} from '../../../record-editing';
import {
    LabelContainer,
    Label,
    NumberControlShowingEdits,
    ValidationsList
} from '../../../components/Form/FormStyles';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import {NumericFormFieldContainer} from './NumericFormFieldContainer';

export function CommunityTreesDistributedControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    return (
        <NumericFormFieldContainer>
            <LabelContainer>
                {!project.ControlledByAutomation &&
                    project.ProjectType.current !== PlantingProjectType.CommunityCanopy && (
                        <ValidateControl
                            validated={project.CommunityTreesDistributedValidated.current}
                            disabled={project.CommunityTreesDistributed.notValidToShowPartner}
                            onChange={(e) =>
                                setProjectAndValidate({
                                    ...project,
                                    CommunityTreesDistributedValidated:
                                        project.CommunityTreesDistributedValidated.update(
                                            e.validated
                                        )
                                })
                            }
                        />
                    )}
                <Label htmlFor="eventTreesDistributed">Trees distributed</Label>
            </LabelContainer>
            <NumberControlShowingEdits
                id="eventTreesDistributed"
                disabled={
                    project.ControlledByAutomation ||
                    project.ProjectType.current == PlantingProjectType.CommunityCanopy
                }
                value={project.CommunityTreesDistributed}
                onChange={(e) =>
                    setProjectAndValidate({
                        ...project,
                        CommunityTreesDistributed: project.CommunityTreesDistributed.update(
                            getNumberFromInput(e)
                        )
                    })
                }></NumberControlShowingEdits>
            <ValidationsList validations={project.CommunityTreesDistributed.validations} />
        </NumericFormFieldContainer>
    );
}
