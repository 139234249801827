import {
    LabelContainer,
    Label,
    ValidationsList,
    FormFieldContainer,
    TextControlShowingEdits
} from '../../../components/Form/FormStyles';
import {EditedProject} from '../EditedProject';
import styled from 'styled-components';
import {isGuid} from '../../../utilities/guidUtilities';
import {ValidationLevel} from '../../../record-editing';

export const D365EventCoordinationIdField = styled(TextControlShowingEdits)`
    max-width: 370px;
`;

function isValidEventCoordinationIdFormat(str: string) {
    return isGuid(str);
}

async function idExistsOnAnotherProject(projectId: number, eventCoordinationId: string) {
    const projects = (await (
        await fetch(
            `/api/planting-projects/check-for-duplicate-eventcoordinationid?D365_eventcoordinationid=${eventCoordinationId}`
        )
    ).json()) as number[];
    const index = projects.findIndex((id) => id !== projectId);
    return index >= 0;
}

async function validateChangedId(projectId: number, eventCoordinationId: string) {
    const validations = [];

    const validFormat = isValidEventCoordinationIdFormat(eventCoordinationId);
    if (!validFormat) {
        validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'The format is invalid for an event coordination id'
        });
    }

    if (validations.length === 0) {
        const exists = await idExistsOnAnotherProject(projectId, eventCoordinationId);
        if (exists) {
            validations.push({
                level: ValidationLevel.NotValidToSave,
                message: 'This event coordination id has been used on another project'
            });
        }
    }

    return validations;
}

export function D365EventCoordinationIdControl({
    project,
    setProjectAndValidate
}: {
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const handleIdChanged = async (e) => {
        const updatedId = project.D365_eventcoordinationid.update(e.target.value);
        updatedId.validations = await validateChangedId(project.Id, e.target.value);

        setProjectAndValidate({
            ...project,
            D365_eventcoordinationid: updatedId
        });
    };

    return (
        <FormFieldContainer>
            <LabelContainer>
                <Label htmlFor="d365EventCoordinationId">D365 event coordination id</Label>
            </LabelContainer>
            <D365EventCoordinationIdField
                id="d365EventCoordinationId"
                disabled={project.ControlledByAutomation}
                value={project.D365_eventcoordinationid}
                onChange={handleIdChanged}
            />
            <ValidationsList validations={project.D365_eventcoordinationid.validations} />
        </FormFieldContainer>
    );
}
