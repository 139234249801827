import { useState } from "react";
import { PlantingProjectType } from "../../entities";
import styled from "styled-components";
import { CloseIcon } from "../../components/Buttons/CloseButton";
import {
	ActionContainer,
	ActionList,
	AddButton as AddButton2
} from '../../components/Buttons/SaveChanges';
import PlantingProjectEditor from '../PlantingProjectEditor/PlantingProjectEditor';
import PlantingProjectSearch from '../PlantingProjectSearch/PlantingProjectSearch';

const ProjectSelectionOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 2;
    padding: 3rem;
`;

const ProjectSelectionArea = styled.div`
    width: 100%;
    height: 100%;
    background: white;
    border: 1px solid gray;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'header'
        'project-search';
    place-content: stretch;
    position: relative;
`;

const ProjectSelectionHeaderArea = styled.div`
    grid-area: header;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
`;

const ProjectSearchContainer = styled.div`
    grid-area: project-search;
    overflow: hidden;
    position: relative;
`;

const ProjectSelectionHeader = styled.h3`
    margin: 1rem;
`;

const PositionedActionContainer = styled(ActionContainer)`
    position: absolute;
    top: 0rem;
    right: 1rem;
`;

const StyledProjectSelectionCloseButton = styled.button`
    margin: 0.5rem;
    padding: 0.2rem 0.2rem 0 0.2rem;
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

function ProjectSelectionCloseButton(props) {
	return (
		<StyledProjectSelectionCloseButton {...props}>
			<CloseIcon />
		</StyledProjectSelectionCloseButton>
	);
}

function ProjectSelector({
	projectSelected,
	projectType,
	closed
}: {
	projectSelected: (projectId: number) => void;
	closed: () => void;
	projectType: PlantingProjectType;
}) {
	const startWithNewProject = projectType === PlantingProjectType.CommunityCanopy;
	const [newProject, setNewProject] = useState(startWithNewProject);

	function handleAddProjectClicked() {
		setNewProject(true);
	}

	function handleProjectAdded(id: number) {
		projectSelected(id);
	}

	return (
		<ProjectSelectionOverlay>
			<ProjectSelectionArea>
				<ProjectSelectionHeaderArea>
					<ProjectSelectionHeader>Select a project</ProjectSelectionHeader>
					<ProjectSelectionCloseButton
						onClick={() => closed()}></ProjectSelectionCloseButton>
				</ProjectSelectionHeaderArea>
				<ProjectSearchContainer>
					{newProject ? (
						<PlantingProjectEditor
							newProject={true}
							newProjectType={projectType}
							projectAdded={handleProjectAdded}
						/>
					) : (
						<>
							<PlantingProjectSearch
								forSelection={true}
								projectSelected={projectSelected}
								projectType={projectType}
							/>
							<PositionedActionContainer>
								<ActionList>
									<AddButton2 onClick={handleAddProjectClicked}>
										Add project
									</AddButton2>
								</ActionList>
							</PositionedActionContainer>
						</>
					)}
				</ProjectSearchContainer>
			</ProjectSelectionArea>
		</ProjectSelectionOverlay>
	);
}

export default ProjectSelector;